import classNames from 'classnames';
import {Box, Header, Line, Scroll, Text, as} from 'folds';
import {ComponentProps, MutableRefObject, ReactNode} from 'react';
import {ScreenSize, useScreenSizeContext} from '../../hooks/useScreenSize';
import {ContainerColor} from '../../styles/ContainerColor.css';
import * as css from './style.css';

type PageRootProps = {
  nav: ReactNode;
  children: ReactNode;
};

export function PageRoot({nav, children}: PageRootProps) {
  const screenSize = useScreenSizeContext();

  return (
    <Box grow="Yes" className={ContainerColor({variant: 'Background'})}>
      {nav}
      {screenSize !== ScreenSize.Mobile && (
        <Line variant="Background" size="300" direction="Vertical" />
      )}
      {children}
    </Box>
  );
}

type ClientDrawerLayoutProps = {
  children: ReactNode;
};
export function PageNav({children}: ClientDrawerLayoutProps) {
  const screenSize = useScreenSizeContext();
  const isMobile = screenSize === ScreenSize.Mobile;

  return (
    <Box
      grow={isMobile ? 'Yes' : undefined}
      className={css.PageNav}
      shrink={isMobile ? 'Yes' : 'No'}
    >
      <Box grow="Yes" direction="Column">
        {children}
      </Box>
    </Box>
  );
}

export const PageNavHeader = as<'header'>(({className, ...props}, ref) => (
  <Header
    className={classNames(css.PageNavHeader, className)}
    variant="Background"
    size="600"
    {...props}
    ref={ref}
  />
));

export function PageNavContent({
  scrollRef,
  dataListName,
  children,
}: {
  children: ReactNode;
  dataListName?: string;
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
}) {
  return (
    <Box grow="Yes" direction="Column">
      <Scroll
        ref={scrollRef}
        variant="Background"
        direction="Vertical"
        size="300"
        hideTrack
        visibility="Hover"
        data-list-container={dataListName}
      >
        <div className={css.PageNavContent}>{children}</div>
      </Scroll>
    </Box>
  );
}

export const Page = as<'div'>(({className, ...props}, ref) => (
  <Box
    grow="Yes"
    direction="Column"
    className={classNames(ContainerColor({variant: 'Surface'}), className)}
    {...props}
    ref={ref}
  />
));

export const PageHeader = as<'div'>(({className, ...props}, ref) => (
  <Header
    as="header"
    size="600"
    className={classNames(css.PageHeader, className)}
    {...props}
    ref={ref}
  />
));

export const PageContent = as<'div'>(({className, ...props}, ref) => (
  <div className={classNames(css.PageContent, className)} {...props} ref={ref} />
));

export const PageHeroSection = as<'div', ComponentProps<typeof Box>>(
  ({className, ...props}, ref) => (
    <Box
      direction="Column"
      className={classNames(css.PageHeroSection, className)}
      {...props}
      ref={ref}
    />
  ),
);

export function PageHero({
  icon,
  title,
  subTitle,
  children,
}: {
  icon: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  children?: ReactNode;
}) {
  return (
    <Box direction="Column" gap="400">
      <Box direction="Column" alignItems="Center" gap="200">
        {icon}
      </Box>
      <Box as="h2" direction="Column" gap="200" alignItems="Center">
        <Text align="Center" size="H2">
          {title}
        </Text>
        <Text align="Center" priority="400">
          {subTitle}
        </Text>
      </Box>
      {children}
    </Box>
  );
}

export const PageContentCenter = as<'div'>(({className, ...props}, ref) => (
  <div className={classNames(css.PageContentCenter, className)} {...props} ref={ref} />
));
