import './CrossSigning.scss';

import {openReusableDialog} from '../../../client/action/navigation';

import Button from '../../atoms/button/Button';
import Text from '../../atoms/text/Text';
import SettingTile from '../../molecules/setting-tile/SettingTile';

import {useCrossSigningStatus} from '../../hooks/useCrossSigningStatus';

const setupDialog = () => {
  openReusableDialog(
    <Text variant="s1" weight="medium">
      Setup cross signing
    </Text>,
    () => <div />, // <CrossSigningSetup />
  );
};

function CrossSigningReset() {
  return (
    <div className="cross-signing__reset">
      <Text variant="h1">✋🧑‍🚒🤚</Text>
      <Text weight="medium">Resetting cross-signing keys is permanent.</Text>
      <Text>
        Anyone you have verified with will see security alerts and your message backup will be lost.
        You almost certainly do not want to do this, unless you have lost <b>Security Key</b> or{' '}
        <b>Phrase</b> and every session you can cross-sign from.
      </Text>
      <Button variant="danger" onClick={setupDialog}>
        Reset
      </Button>
    </div>
  );
}

const resetDialog = () => {
  openReusableDialog(
    <Text variant="s1" weight="medium">
      Reset cross signing
    </Text>,
    () => <CrossSigningReset />,
  );
};

function CrossSignin() {
  const isCSEnabled = useCrossSigningStatus();
  return (
    <SettingTile
      title="Cross signing"
      content={
        <Text variant="b3">
          Setup to verify and keep track of all your sessions. Also required to backup encrypted
          message.
        </Text>
      }
      options={
        isCSEnabled ? (
          <Button variant="danger" onClick={resetDialog}>
            Reset
          </Button>
        ) : (
          <Button variant="primary" onClick={setupDialog}>
            Setup
          </Button>
        )
      }
    />
  );
}

export default CrossSignin;
