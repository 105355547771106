import {Box, Spinner, Text} from 'folds';
import {ReactNode, useEffect, useState} from 'react';
import ReusableContextMenu from '~/app/atoms/context-menu/ReusableContextMenu';
import {CapabilitiesAndMediaConfigLoader} from '~/app/components/CapabilitiesAndMediaConfigLoader';
import {SplashScreen} from '~/app/components/splash-screen';
import {CapabilitiesProvider} from '~/app/hooks/useCapabilities';
import {MatrixClientProvider} from '~/app/hooks/useMatrixClient';
import {MediaConfigProvider} from '~/app/hooks/useMediaConfig';
import Dialogs from '~/app/organisms/pw/Dialogs';
import Windows from '~/app/organisms/pw/Windows';
import {useSetting} from '~/app/state/hooks/settings';
import {settingsAtom} from '~/app/state/settings';
import {initHotkeys} from '~/client/event/hotkeys';
import initMatrix from '~/client/initMatrix';
import {getSecret} from '~/client/state/auth';
import {useAuth} from '../auth/AuthProvider';
import {SpecVersions} from './SpecVersions';

function SystemEmojiFeature() {
  const [twitterEmoji] = useSetting(settingsAtom, 'twitterEmoji');

  if (twitterEmoji) {
    document.documentElement.style.setProperty('--font-emoji', 'Twemoji');
  } else {
    document.documentElement.style.setProperty('--font-emoji', 'Twemoji_DISABLED');
  }

  return null;
}

function ClientRootLoading() {
  return (
    <SplashScreen>
      <Box direction="Column" grow="Yes" alignItems="Center" justifyContent="Center" gap="400">
        <Spinner variant="Secondary" size="600" />
        <Text>Heating up</Text>
      </Box>
    </SplashScreen>
  );
}

type ClientRootProps = {
  children: ReactNode;
};

export function ClientRoot({children}: ClientRootProps) {
  const [loading, setLoading] = useState(true);
  const [, setMatrixClientReady] = useState(false);
  const {isAuthenticated} = useAuth();
  const {baseUrl} = getSecret();

  useEffect(() => {
    if (!baseUrl || !isAuthenticated) return;

    const handleStart = () => {
      initHotkeys();
      setLoading(false);
      setMatrixClientReady(true);
    };

    initMatrix.once('init_loading_finished', handleStart);

    if (!initMatrix.matrixClient) {
      initMatrix.init(baseUrl);
    } else {
      handleStart();
    }

    return () => {
      initMatrix.removeListener('init_loading_finished', handleStart);
    };
  }, [baseUrl, isAuthenticated]);

  if (!baseUrl || !isAuthenticated || loading) {
    return <ClientRootLoading />;
  }

  return (
    <SpecVersions baseUrl={baseUrl}>
      <MatrixClientProvider value={initMatrix.matrixClient!}>
        <CapabilitiesAndMediaConfigLoader>
          {(capabilities, mediaConfig) => (
            <CapabilitiesProvider value={capabilities ?? {}}>
              <MediaConfigProvider value={mediaConfig ?? {}}>
                {children}

                {/* TODO: remove these components after navigation refactor */}
                <Windows />
                <Dialogs />
                <ReusableContextMenu />
                <SystemEmojiFeature />
              </MediaConfigProvider>
            </CapabilitiesProvider>
          )}
        </CapabilitiesAndMediaConfigLoader>
      </MatrixClientProvider>
    </SpecVersions>
  );
}
