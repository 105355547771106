import {TaskType} from '~/types/task';

export const getWbsChain = (
  osk: string,
  oskMap: Map<string, TaskType>,
): Array<{task_id: string; name: string; outline_sort_key: string}> => {
  if (!osk) return [];

  const oskElems = osk.split('.');
  const wbsTasks = [];

  for (let ctr = 0; ctr < oskElems.length - 1; ctr++) {
    const key = oskElems.slice(0, ctr + 1).join('.');
    const wbsParent = oskMap.get(key);

    if (wbsParent) {
      wbsTasks.push({
        name: wbsParent.name,
        task_id: wbsParent.id,
        outline_sort_key: wbsParent.outline_sort_key,
      });
    }
  }

  return wbsTasks;
};

export function getWBSText(osk: string, oskMap: Map<string, TaskType>): string {
  if (!osk || !oskMap) {
    return '';
  }

  const oskElems = osk.split('.');
  const names = [];

  for (let ctr = 0; ctr < oskElems.length; ctr++) {
    const key = oskElems.slice(0, ctr + 1).join('.');
    const wbsParent = oskMap.get(key);

    if (wbsParent && wbsParent.name) {
      names.push(wbsParent.name);
    }
  }

  const fullPath = names.join('/');

  return fullPath;
}

export const formatWbsPath = (wbsPath: string | undefined, isMobile: boolean) => {
  if (!wbsPath) return '';

  const pathParts = wbsPath.split('/');

  if (isMobile) {
    // For mobile, return only the last item
    return `.../${pathParts[pathParts.length - 1]}`;
  } else {
    // For desktop, return the last three items if length is greater than 3
    if (pathParts.length > 3) {
      return `.../${pathParts.slice(-3).join('/')}`;
    }
    return wbsPath;
  }
};
