import FocusTrap from 'focus-trap-react';
import {
  Avatar,
  Badge,
  Box,
  Icon,
  IconButton,
  Icons,
  Menu,
  MenuItem,
  PopOut,
  RectCords,
  Text,
  config,
  toRem,
} from 'folds';
import {useAtom} from 'jotai';
import {Room} from 'matrix-js-sdk';
import {MouseEventHandler, forwardRef, useCallback, useState} from 'react';
import {useFocusWithin, useHover} from 'react-aria';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {Pill} from '~/app/atoms/pill/Pill';
import {CustomIcons} from '~/app/atoms/system-icons/CustomIconsStore';
import {UseStateProvider} from '~/app/components/UseStateProvider';
import {LeaveRoomPrompt} from '~/app/components/leave-room-prompt';
import {NavItem, NavItemContent, NavItemOptions, NavLink} from '~/app/components/nav';
import {TypingIndicator} from '~/app/components/typing-indicator';
import {UnreadBadge, UnreadBadgeCenter} from '~/app/components/unread-badge';
import {useGetProjectByRoomId} from '~/app/hooks/useGetProjectByRoomId';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useRoomLatestRenderedEvent} from '~/app/hooks/useRoomLatestRenderedEvent';
import {useRoomTypingMember} from '~/app/hooks/useRoomTypingMembers';
import {formatMessageContent} from '~/app/organisms/create-issue/helper';
import {useRoomUnread} from '~/app/state/hooks/unread';
import {roomToUnreadAtom} from '~/app/state/room/roomToUnread';
import {openEditRoom} from '~/client/action/navigation';
import {markAsRead} from '~/client/action/notifications';
import {useCompany} from '~/queries/useCompany';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {useGroupInfoForRoom} from '../../../queries/useGroupInfoForRoom';
import {Unread} from '../../../types/matrix/room';
import {Project} from '../../../types/project';
import {RoomAvatar} from '../../components/room-avatar';
import {workerAtom} from '../../state/worker';
import {nameInitials} from '../../utils/common';
import * as css from './styles.css';

type RoomNavItemMenuProps = {
  room: Room;
  requestClose: () => void;
};

const RoomNavItemMenu = forwardRef<HTMLDivElement, RoomNavItemMenuProps>(
  ({room, requestClose}, ref) => {
    const unread = useRoomUnread(room.roomId, roomToUnreadAtom);
    const {t} = useTranslation('common');

    const handleMarkAsRead = useCallback(() => {
      markAsRead(room.roomId);
      requestClose();
      mixpanelService.track(MixpanelEvents.ChatListChatRoom3DotMarkReadClick);
    }, [room.roomId, requestClose]);

    const handleEditRoom = () => {
      openEditRoom(room.roomId);
      requestClose();
      mixpanelService.track(MixpanelEvents.ChatListChatRoom3DotEditRoomClick);
    };

    const handleLeaveRoom = useCallback(() => {
      mixpanelService.trackWithAction(
        requestClose,
        MixpanelEvents.ChatListChatRoom3DotLeaveRoomClick,
      );
    }, [requestClose]);

    return (
      <Menu ref={ref} style={{maxWidth: toRem(160), width: '100vw'}}>
        <Box direction="Column" gap="100" style={{padding: config.space.S100}}>
          <MenuItem
            onClick={handleMarkAsRead}
            size="300"
            after={<Icon size="100" src={Icons.CheckTwice} />}
            radii="300"
            disabled={!unread}
          >
            <Text style={{flexGrow: 1}} as="span" size="T300" truncate>
              {t('navbar.navitem.mark_as_read')}
            </Text>
          </MenuItem>
          <UseStateProvider initial={false}>
            {(promptLeave, setPromptLeave) => (
              <>
                <MenuItem
                  onClick={() => setPromptLeave(true)}
                  variant="Critical"
                  fill="None"
                  size="300"
                  after={<Icon size="100" src={Icons.ArrowGoLeft} />}
                  radii="300"
                  aria-pressed={promptLeave}
                >
                  <Text style={{flexGrow: 1}} as="span" size="T300" truncate>
                    {t('navbar.navitem.leave_room')}
                  </Text>
                </MenuItem>
                {promptLeave && (
                  <LeaveRoomPrompt
                    roomId={room.roomId}
                    onDone={handleLeaveRoom}
                    onCancel={() => setPromptLeave(false)}
                  />
                )}
              </>
            )}
          </UseStateProvider>
          <MenuItem
            after={<Icon size="100" src={Icons.UserPlus} />}
            onClick={handleEditRoom}
            radii="300"
            size="300"
            variant="Primary"
          >
            <Text style={{flexGrow: 1}} as="span" size="T300" truncate>
              {t('navbar.navitem.edit')}
            </Text>
          </MenuItem>
        </Box>
      </Menu>
    );
  },
);

const LoadingPill = () => <Pill loading />;
const RoomNavItemLoadingState = () => (
  <>
    <LoadingPill />
    <Skeleton width={200} height={22} />
  </>
);

const ProjectNamePill = ({project}: {project: Project | null | undefined}) =>
  project?.name ? <Pill before={CustomIcons.Folder} title={project.name} truncate /> : null;

const LastMessage = ({sender, content}: {sender: string; content: string | JSX.Element}) =>
  sender && content ? (
    <Box className={css.RoomNavLastLiveContentContainer}>
      <Text size="B400" className={css.RoomNavLastLiveContentParagraph}>
        {sender}:{' '}
        <Text
          className={css.RoomNavLastLiveContent}
          title={content as string}
          as="span"
          size="L400"
          truncate
        >
          {content}
        </Text>
      </Text>
    </Box>
  ) : null;

const RoomName = ({name, unread}: {name: string; unread: Unread | undefined}) => (
  <Text
    priority={unread ? '500' : '300'}
    as="span"
    size="Inherit"
    truncate
    className={css.RoomNavRoomName}
  >
    {name}
  </Text>
);

type RoomNavItemProps = {
  room: Room;
  selected: boolean;
  linkPath: string;
  muted?: boolean;
  showAvatar?: boolean;
  direct?: boolean;
};

export function RoomNavItem({room, selected, muted, linkPath}: RoomNavItemProps) {
  const mx = useMatrixClient();
  const [hover, setHover] = useState(false);
  const {hoverProps} = useHover({onHoverChange: setHover});
  const {focusWithinProps} = useFocusWithin({onFocusWithinChange: setHover});
  const [menuAnchor, setMenuAnchor] = useState<RectCords>();
  const unread = useRoomUnread(room.roomId, roomToUnreadAtom);
  const typingMember = useRoomTypingMember(room.roomId);
  const [currentUser] = useAtom(workerAtom);

  const handleContextMenu: MouseEventHandler<HTMLElement> = (evt) => {
    evt.preventDefault();
    setMenuAnchor({
      x: evt.clientX,
      y: evt.clientY,
      width: 0,
      height: 0,
    });
  };

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setMenuAnchor(evt.currentTarget.getBoundingClientRect());
  };

  const optionsVisible = hover || !!menuAnchor;

  const {data: project, isLoading: isProjectNameLoading} = useGetProjectByRoomId(room.roomId);
  const {data: company} = useCompany(project?.company_id ?? '');

  const handleAnalytics = useCallback(() => {
    if (project && company) {
      mixpanelService.setProject(project);
      mixpanelService.setCompany(company);
    }
  }, [project, company]);

  const lastLiveEvent = useRoomLatestRenderedEvent(room);
  const senderId = lastLiveEvent?.getSender() || '';
  const sender = mx.getUser(senderId)?.displayName || '';
  const lastLiveContent = lastLiveEvent?.event.content;
  let content = formatMessageContent(lastLiveContent, mx);
  content = (content as string)?.replace(/<[^>]*>|<|>/g, '');

  const {data: groupInfo} = useGroupInfoForRoom(room.roomId);
  const memberAvatarUrl =
    groupInfo?.members.find((mem) => mem.member_id !== currentUser.id)?.member_profile_pic_url ||
    '';
  const isDirect = groupInfo?.direct ?? false;

  return (
    <NavLink to={linkPath} onClick={handleAnalytics}>
      <NavItem
        variant="Background"
        className={css.RoomNavContainer}
        radii="400"
        highlight={unread !== undefined}
        aria-selected={selected}
        data-hover={!!menuAnchor}
        data-room-id={room.roomId}
        onContextMenu={handleContextMenu}
        {...hoverProps}
        {...focusWithinProps}
      >
        <NavItemContent as="div">
          <Box as="span" grow="Yes" alignItems="Center" gap="200">
            {isDirect && !isProjectNameLoading ? (
              <Avatar size="200" radii="500">
                <RoomAvatar
                  roomId={room.roomId}
                  src={memberAvatarUrl}
                  renderFallback={() => (
                    <Text as="span" size="H6">
                      {nameInitials(room.name)}
                    </Text>
                  )}
                  alt={room.name}
                />
              </Avatar>
            ) : null}
            <Box direction="Column" alignItems="Start" grow="Yes">
              {isProjectNameLoading ? (
                <RoomNavItemLoadingState />
              ) : (
                <>
                  <ProjectNamePill project={project} />
                  <RoomName name={room.name} unread={unread} />
                  <LastMessage sender={sender} content={content} />
                </>
              )}
            </Box>
            {!optionsVisible && !unread && !selected && typingMember.length > 0 ? (
              <Badge size="300" variant="Secondary" fill="Soft" radii="Pill" outlined>
                <TypingIndicator size="300" disableAnimation />
              </Badge>
            ) : null}
            {!optionsVisible && unread && !isProjectNameLoading ? (
              <UnreadBadgeCenter>
                <UnreadBadge highlight={unread.highlight > 0} count={unread.total} />
              </UnreadBadgeCenter>
            ) : null}
            {muted && !optionsVisible && <Icon size="50" src={Icons.BellMute} />}
          </Box>
        </NavItemContent>
        <NavItemOptions
          style={{
            visibility: optionsVisible ? 'visible' : 'hidden',
            pointerEvents: optionsVisible ? 'auto' : 'none',
          }}
        >
          <PopOut
            anchor={menuAnchor}
            offset={menuAnchor?.width === 0 ? 0 : undefined}
            alignOffset={menuAnchor?.width === 0 ? 0 : -5}
            position="Bottom"
            align={menuAnchor?.width === 0 ? 'Start' : 'End'}
            content={
              <FocusTrap
                focusTrapOptions={{
                  initialFocus: false,
                  returnFocusOnDeactivate: false,
                  onDeactivate: () => setMenuAnchor(undefined),
                  clickOutsideDeactivates: true,
                  isKeyForward: (evt: KeyboardEvent) => evt.key === 'ArrowDown',
                  isKeyBackward: (evt: KeyboardEvent) => evt.key === 'ArrowUp',
                }}
              >
                <RoomNavItemMenu room={room} requestClose={() => setMenuAnchor(undefined)} />
              </FocusTrap>
            }
          >
            <IconButton
              onClick={handleOpenMenu}
              aria-pressed={!!menuAnchor}
              variant="Background"
              fill="None"
              size="300"
              radii="300"
            >
              <Icon size="50" src={Icons.VerticalDots} />
            </IconButton>
          </PopOut>
        </NavItemOptions>
      </NavItem>
    </NavLink>
  );
}
