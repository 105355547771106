import dayjs, {ConfigType, extend} from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/weekday';

export function initDayJs() {
  extend(utc);
  extend(advancedFormat);
  extend(timezone);
  extend(isoWeek);
  extend(quarterOfYear);
  extend(localeData);
  extend(localizedFormat);
  extend(isBetween);
}

export const ISO_SHORT = 'YYYY-MM-DD';
export const ISO_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:SSS';
export const MONTHDAY = 'M/D';

export function setDefaultTimezone(tz?: string) {
  dayjs.tz.setDefault(tz);
}

export function isValidDate(date: any) {
  return dayjs(date).isValid();
}

export function toShortIso(date: ConfigType) {
  return dayjs(date).format(ISO_SHORT);
}

export function toIsoString(date: string | number | Date): string {
  return dayjs(date).toISOString();
}
