import {MenuItem, Text, color} from 'folds';
import {useAtom} from 'jotai';
import type {Room} from 'matrix-js-sdk';
import {
  type KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import type {Editor} from 'slate';
import {v4 as uuid} from 'uuid';
import {useKeyDown} from '~/app/hooks/useKeyDown';
import {hasMentionAtom} from '~/app/state/mention';
import {onTabPress} from '~/app/utils/keyboard';
import {useIssuesQuery} from '~/queries/useIssuesQuery';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import type {IssueModelDTO} from '~/types/issue';
import {createIssueElement, moveCursor, replaceWithElement} from '../utils';
import {AutocompleteMenu} from './AutocompleteMenu';
import type {AutocompleteQuery} from './autocompleteQuery';

type IssueMentionAutocompleteProps = {
  room: Room;
  editor: Editor;
  query: AutocompleteQuery<string>;
  requestClose: () => void;
};

export function IssueMentionAutocomplete({
  room,
  editor,
  query,
  requestClose,
}: IssueMentionAutocompleteProps) {
  const {t} = useTranslation('issue_mentions');
  const {data: chatData} = useProjectAndGroupIdFromWorkerChats(room.roomId ?? '');
  const {
    data: issues,
    isLoading: issuesLoading,
    isError: issuesError,
  } = useIssuesQuery({projectId: chatData?.projectId}, query.text);
  const [searchTerm, setSearchTerm] = useState('');
  const [, setHasMention] = useAtom(hasMentionAtom);

  const filteredIssues = useMemo(() => {
    if (!issues) return [];
    if (!searchTerm) return issues;
    return issues.filter((issue) => issue.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [issues, searchTerm]);

  useEffect(() => {
    setSearchTerm(query.text);
  }, [query.text]);

  const handleAutocomplete = useCallback(
    (issue: IssueModelDTO) => {
      const issueElement = createIssueElement({
        id: issue.id,
        status: issue.status,
        name: issue.name,
        highlight: false,
      });
      setHasMention(true);
      replaceWithElement(editor, query.range, issueElement);
      moveCursor(editor, true);
      mixpanelService.track(MixpanelEvents.MessageBoxIssueMentionClick);
      requestClose();
    },
    [editor, query.range, setHasMention, requestClose],
  );

  useKeyDown(window, (evt: KeyboardEvent) => {
    onTabPress(evt, () => {
      if (filteredIssues.length > 0) {
        handleAutocomplete(filteredIssues[0]);
      }
    });
  });

  const renderContent = () => {
    if (issuesError) {
      return (
        <MenuItem as="button" radii="300" onClick={requestClose}>
          <Text style={{flexGrow: 2}} size="B400">
            {t('auto_complete.failed_to_load_issues')}
          </Text>
        </MenuItem>
      );
    }

    if (issuesLoading) {
      return Array(9)
        .fill(undefined)
        .map(() => (
          <MenuItem
            key={uuid()}
            as="button"
            radii="300"
            style={{position: 'relative', marginBottom: 4}}
          >
            <Skeleton
              borderRadius={4}
              count={1}
              height={40}
              style={{
                backgroundColor: color.Background.Container,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
            />
          </MenuItem>
        ));
    }

    if (filteredIssues?.length > 0) {
      return filteredIssues.map((issue) => (
        <MenuItem
          key={issue.id}
          as="button"
          radii="300"
          onKeyDown={(evt: ReactKeyboardEvent<HTMLButtonElement>) =>
            onTabPress(evt, () => handleAutocomplete(issue))
          }
          onClick={() => handleAutocomplete(issue)}
        >
          <Text style={{flexGrow: 2}} size="B400" truncate>
            {issue.name}
          </Text>
        </MenuItem>
      ));
    }

    return (
      <MenuItem as="button" radii="300" onClick={requestClose}>
        <Text style={{flexGrow: 2}} size="B400">
          {t('auto_complete.no_results')}
        </Text>
      </MenuItem>
    );
  };

  return (
    <AutocompleteMenu
      headerContent={<Text size="L400">{t('auto_complete.header.title')}</Text>}
      requestClose={requestClose}
    >
      {renderContent()}
    </AutocompleteMenu>
  );
}
