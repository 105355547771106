import {useCallback} from 'react';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';

type CreateRoomOptions = {
  openCreateRoom: () => void;
};

export const useCreateRoom = ({openCreateRoom}: CreateRoomOptions) => {
  const handleCreateRoom = useCallback(() => {
    mixpanelService.trackWithAction(openCreateRoom, MixpanelEvents.CreateRoomClick);
  }, [openCreateRoom]);

  return {handleCreateRoom};
};
