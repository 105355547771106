import {HttpStatusCode} from '../types/http-status';

type HttpStatusCodeMessage = keyof typeof HttpStatusCode;

export type NonSuccessResponse = {
  success: boolean;
  reason: string;
  statusCode: number;
};
export class Handlers {
  handleNonSuccessResponse(statusCode: number): NonSuccessResponse {
    const statusMessage = this.getStatusMessage(statusCode);

    switch (statusMessage) {
      case 'NotFound':
        return {success: false, reason: 'Not found', statusCode};
      case 'Forbidden':
        return {success: false, reason: 'Forbidden', statusCode};
      case 'InternalServerError':
        return {success: false, reason: 'Server error', statusCode};
      default:
        return {success: false, reason: 'Unknown error', statusCode};
    }
  }

  protected getStatusMessage(statusCode: number): HttpStatusCodeMessage | undefined {
    return Object.entries(HttpStatusCode).find(([_, value]) => value === statusCode)?.[0] as
      | HttpStatusCodeMessage
      | undefined;
  }
}
