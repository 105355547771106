import ky, {HTTPError} from 'ky';
import {sleep} from 'matrix-js-sdk/lib/utils';
import {HttpStatusCode} from '~/types/http-status';
import {JourneyApi} from '~/util/api-helper';
import {Handlers, NonSuccessResponse} from '../handlers';

export type MatrixConnectionToken = {
  id: string;
  password: string;
};

export type MatrixSessionBody = {
  type: 'm.login.password';
  identifier: {
    type: 'm.id.user';
    user: string;
  };
  password: string;
};

export type MatrixSessionResponse = {
  user_id: string;
  access_token: string;
  home_server: string;
  device_id: string;
  well_known: {
    'm.homeserver': {
      base_url: string;
    };
  };
};

type MemberRole = 'owner' | 'member' | 'assignee' | 'responsible';

export const MemberRoleMap: Record<MemberRole, MemberRole> = {
  owner: 'owner',
  member: 'member',
  assignee: 'assignee',
  responsible: 'responsible',
} as const;

export type Member = {
  member_id: string;
  member_profile_pic_url?: string;
  member_role: MemberRole;
};

export type MemberResponse = {
  created_by: string;
  group_id: string;
  id: string;
  member_name: string;
  member_profile_pic_url: string;
  member_role: 'owner';
  member_status: string;
  member_trade: string;
  time_chat_last_sync: string;
} & Member;

export type CreateGroupProps = {
  adhoc: boolean;
  company_id: string;
  direct: boolean;
  id?: string;
  members: Member[];
  name: string;
  notifybot: boolean;
  project_id: string;
};

export type GroupResponse = {
  company_id: string;
  ext_chat_id: string;
  id: string;
  members: Member[];
  name: string;
  project_id: string;
  time_created: string;
  time_removed: string;
  time_updated: string;
} & CreateGroupProps;

export type GroupRequestParams = {
  filterParams?: Partial<GroupResponse>;
  projectId?: string;
  workerId: string;
};

export type AddMembersToGroupParams = {
  group_id: string;
  members: Member[];
};

export type LeaveRoomResult =
  | {success: true; response: Response}
  | {
      success: false;
      reason: 'not_found' | 'forbidden' | 'server_error' | 'unknown_error';
      statusCode: number;
    };

class ChatService extends Handlers {
  private static instance: ChatService | null = null;

  private constructor() {
    super();
  }

  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }

    return ChatService.instance;
  }

  async getMatrixConnectionToken(workerId: string): Promise<MatrixConnectionToken> {
    const response = await JourneyApi.get(`workers/${workerId}/chatauth`);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as MatrixConnectionToken;
  }

  async initializeMatrixSession(
    workerId: string,
    homeServer: string,
  ): Promise<MatrixSessionResponse> {
    const credentials = await this.getMatrixConnectionToken(workerId);
    const body: MatrixSessionBody = {
      type: 'm.login.password',
      identifier: {
        type: 'm.id.user',
        user: credentials.id,
      },
      password: credentials.password,
    };

    const response = await ky.post('_matrix/client/v3/login', {
      json: body,
      prefixUrl: homeServer,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as MatrixSessionResponse;
  }

  async pollCreatedRoomForUser(
    id: string,
    maxAttempts = 10,
    delayMs = 2000,
  ): Promise<GroupResponse> {
    let attempts = 0;

    while (attempts < maxAttempts) {
      try {
        const result = await this.getCreatedRoomForUser(id);
        if (result.ext_chat_id !== undefined) {
          return result;
        }
        throw new Error('ext_chat_id is undefined');
      } catch (error) {
        attempts++;
        if (attempts >= maxAttempts) {
          throw new Error('Max polling attempts reached');
        }
        await sleep(delayMs);
      }
    }

    throw new Error('Polling failed after max attempts');
  }

  private async getCreatedRoomForUser(id: string): Promise<GroupResponse> {
    const endpoint = `groups/${id}`;
    const response = await JourneyApi.get(endpoint);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  }

  async createRoom(params: CreateGroupProps): Promise<GroupResponse> {
    const endpoint = `projects/${params.project_id}/chats`;
    const response = await JourneyApi.post(endpoint, {json: params});
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json<GroupResponse>();
  }

  async leaveRoom(
    roomId: string,
    workerId: string,
    direct: boolean,
  ): Promise<LeaveRoomResult | NonSuccessResponse> {
    const memberChats = await this.getAllChatsForUser({
      workerId,
      filterParams: {ext_chat_id: roomId, direct},
    });
    const chatToLeave = memberChats.find((chat) => chat.ext_chat_id === roomId);

    if (!chatToLeave) {
      return {success: false, reason: 'Not Found', statusCode: HttpStatusCode.NotFound};
    }

    const endpoint = `groups/${chatToLeave.id}/members/${workerId}`;

    try {
      const response = await JourneyApi.delete(endpoint);
      if (response.status === HttpStatusCode.Ok) {
        return {success: true, response};
      } else {
        return this.handleNonSuccessResponse(response.status);
      }
    } catch (error) {
      if (error instanceof HTTPError) {
        return this.handleNonSuccessResponse(error.response.status);
      } else {
        return {
          success: false,
          reason: 'Unknown error',
          statusCode: HttpStatusCode.InternalServerError,
        };
      }
    }
  }

  async getAllChatsForUser(params: GroupRequestParams): Promise<GroupResponse[]> {
    const {workerId, filterParams, projectId} = params;
    const endpoint = `workers/${workerId}/chats`;
    const searchParams = new URLSearchParams({
      ...(filterParams && {filter_params: JSON.stringify(filterParams)}),
      ...(projectId && {project_id: projectId}),
    });
    const response = await JourneyApi.get(endpoint, {searchParams});
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return await response.json();
  }

  async updateGroupById(params: CreateGroupProps & {group_id: string}): Promise<GroupResponse> {
    const {group_id, ...json} = params;
    const endpoint = `groups/${group_id}`;
    const response = await JourneyApi.post(endpoint, {json: {...json, id: group_id}});
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return await response.json();
  }

  async addMembersToGroup(params: {
    group_id: string;
    membersToAdd: Member[];
  }): Promise<GroupResponse> {
    const {group_id, membersToAdd} = params;
    const endpoint = `groups/${group_id}/add_members`;
    const response = await JourneyApi.post(endpoint, {json: membersToAdd});
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return await response.json();
  }

  async removeMembersFromGroup(params: {
    group_id: string;
    membersToRemove: Member[];
  }): Promise<GroupResponse> {
    const {group_id, membersToRemove} = params;
    const endpoint = `groups/${group_id}/remove_members`;
    const response = await JourneyApi.post(endpoint, {json: membersToRemove});
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return await response.json();
  }
}

export const chatService = ChatService.getInstance();
