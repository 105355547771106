import {z} from 'zod';
import {TaskStatus} from '~/types/taskStatus';
import {CreateIssueTFunction} from './helper';

export type CreateIssueFormSchema = z.infer<ReturnType<typeof createIssueSchema>>;

export const activitySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const createIssueSchema = (t: CreateIssueTFunction) =>
  z.object({
    activity: activitySchema.nullable(),
    assignee: z.string().optional(),
    attachmentEventId: z.string().optional(),
    costImpact: z.string().optional(),
    description: z.string().min(1, {message: t('create_issue:form.errors.description_required')}),
    endDate: z.string().optional(),
    issueType: z.string().min(1, {message: t('create_issue:form.errors.issue_type_required')}),
    liableCompany: z.string().optional(),
    newIssue: z.string().min(2, {message: t('create_issue:form.errors.issue_type_min_length')}),
    photos: z.array(z.string()).optional(),
    resolvingCompany: z.string().optional(),
    startDate: z.string().optional(),
    status: z.nativeEnum(TaskStatus).optional(),
    timeImpact: z.string().min(1, {message: t('create_issue:form.errors.time_impact_required')}),
  });
