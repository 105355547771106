import {ReactNode} from 'react';
import {useParams} from 'react-router-dom';
import {JoinBeforeNavigate} from '../../../features/join-before-navigate';
import {useSelectedRoom} from '../../../hooks/router/useSelectedRoom';
import {useMatrixClient} from '../../../hooks/useMatrixClient';
import {RoomProvider} from '../../../hooks/useRoom';
import {useHomeRooms} from './useHomeRooms';

export function HomeRouteRoomProvider({children}: {children: ReactNode}) {
  const mx = useMatrixClient();
  const rooms = useHomeRooms();

  const {roomIdOrAlias} = useParams();
  const roomId = useSelectedRoom();
  const room = mx.getRoom(roomId);

  if (!room || !rooms.includes(room.roomId)) {
    return <JoinBeforeNavigate roomIdOrAlias={roomIdOrAlias!} />;
  }

  return (
    <RoomProvider key={room.roomId} value={room}>
      {children}
    </RoomProvider>
  );
}
