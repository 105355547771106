import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import {Box, Icon, IconButton, Icons, Text} from 'folds';
import {ReactNode, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import RawModal from '~/app/atoms/modal/RawModal';
import Tooltip from '~/app/atoms/tooltip/Tooltip';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useCreateRoomMutation} from '~/mutations/useCreateRoomMutation';
import {CreateGroupProps, Member, MemberRoleMap} from '~/services/ChatService/ChatService';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import s from './CreateRoom.module.scss';
import {ChatTypeSelection} from './Steps/ChatTypeSelection';
import {GroupParticipantSelection} from './Steps/GroupParticipantSelection';
import {ProjectSelection} from './Steps/ProjectSelection';
import {useWindowToggle} from './hooks/useWindowToggle';
import {createRoomFormSchema} from './schemas/createRoomFormSchema';
import {STEPS} from './types/constants';
import {isCreateGroupChat, isCreateIndividualChat, narrowCreateRoomFormProps} from './types/guards';
import {CreateRoomFormProps, StepValue} from './types/types';

export const HorizontalRule = () => <Box className={s.modal__horizontalRule} />;

export const EmptyResults = ({title}: {title: string}) => {
  const {t} = useTranslation();
  return (
    <Box justifyContent="Center" alignItems="Center" grow="Yes">
      <Text size="H2">{t('common:empty.results', {title})}</Text>
    </Box>
  );
};

export default function CreateRoom() {
  const {t} = useTranslation(['create_room']);
  const {isCreateOpen: isOpen, onRequestClose} = useWindowToggle();
  const [currentStep, setCurrentStep] = useState<StepValue>(STEPS.stepOne);
  const mx = useMatrixClient();
  const methods = useForm<CreateRoomFormProps>({
    mode: 'onChange',
    defaultValues: {
      roomName: '',
      selectedProject: undefined,
      selectedWorker: undefined,
      selectedWorkers: [],
    },
    resolver: zodResolver(createRoomFormSchema),
  });

  const selectedProject = methods.watch('selectedProject');

  const handleCloseModal = () => {
    setCurrentStep(STEPS.stepOne);
    methods.reset();
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const onError = (e: unknown) => {
    Sentry.captureException(e);
    handleCloseModal();
  };

  const {mutateAsync: createChatRoomMutation} = useCreateRoomMutation({
    projectId: selectedProject?.id,
    onSuccess: handleCloseModal,
    onError,
  });

  // Handle form submission
  const onSubmit = async (data: CreateRoomFormProps) => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }
    const narrowedData = narrowCreateRoomFormProps(data);
    let createChatParms: CreateGroupProps | null = null;
    const sharedCreateParams = {
      adhoc: true,
      company_id: narrowedData?.selectedProject.company_id,
      direct: false,
      project_id: narrowedData?.selectedProject.id,
      notifybot: true,
    };

    const userId = mx.getUserId();
    if (isCreateIndividualChat(data)) {
      if (userId) {
        const memberName = data.selectedWorker.worker_full.full_name;
        createChatParms = {
          ...sharedCreateParams,
          direct: true,
          name: memberName,
          members: [
            {
              member_id: data.selectedWorker.worker_id,
              member_role: MemberRoleMap.member,
            },
          ],
        } as CreateGroupProps;
        mixpanelService.track(MixpanelEvents.Create1on1RoomClick);
      } else {
        throw new Error('User must be logged in.');
      }
    } else if (isCreateGroupChat(data)) {
      const members: Member[] = data.selectedWorkers.map((worker) => ({
        member_id: worker.worker_id,
        member_role: 'member',
      }));
      createChatParms = {
        ...sharedCreateParams,
        name: data.roomName,
        members,
      } as CreateGroupProps;
      mixpanelService.track(MixpanelEvents.CreateGroupClick);
    }
    if (createChatParms) {
      try {
        await createChatRoomMutation(createChatParms);
      } catch (error) {
        Sentry.captureException(error, {
          level: 'error',
          tags: {
            feature: 'chat_room_creation',
            projectId: createChatParms.project_id,
          },
          extra: {
            params: createChatParms,
            errorType: error instanceof Error ? error.constructor.name : 'Unknown',
            errorMessage: error instanceof Error ? error.message : String(error),
          },
        });
      }
    }
  };

  const nextStep = () => {
    setCurrentStep((prev) => {
      if (prev === STEPS.stepOne) {
        return STEPS.stepTwo;
      }
      if (prev === STEPS.stepTwo) {
        mixpanelService.track(MixpanelEvents.CreateGroupClick);
        return STEPS.stepThree;
      }
      return prev;
    });
  };

  const prevStep = () => {
    setCurrentStep((prev) => {
      if (prev === STEPS.stepThree) {
        return STEPS.stepTwo;
      }
      if (prev === STEPS.stepTwo) {
        return STEPS.stepOne;
      }
      return prev;
    });
  };

  const steps: Record<StepValue, ReactNode> = {
    [STEPS.stepOne]: <ProjectSelection renderNextStep={nextStep} />,
    [STEPS.stepTwo]: <ChatTypeSelection renderNextStep={nextStep} />,
    [STEPS.stepThree]: <GroupParticipantSelection />,
  };

  return (
    <RawModal
      closeFromOutside={!methods.formState.isSubmitting}
      isOpen={isOpen}
      className={s.modal}
      onRequestClose={handleCloseModal}
    >
      <Box direction="Column" className={s.modal__layout}>
        <Box direction="Column">
          <Box direction="Row" justifyContent="SpaceBetween" alignItems="Center">
            <Box direction="Row" alignItems="Center">
              {currentStep !== STEPS.stepOne ? (
                <Tooltip content={<Text size="B300">{t('steps.previous_step')}</Text>}>
                  <IconButton
                    onClick={prevStep}
                    className={s.modal__selectedProject__header__iconBtn}
                  >
                    <Icon src={Icons.ArrowLeft} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
            <Text as="h3" size="H3" priority="300">
              {t('header.title')}
            </Text>
            <IconButton
              size="300"
              onClick={handleCloseModal}
              radii="300"
              className={s.modal__selectedProject__header__iconBtn}
            >
              <Icon src={Icons.Cross} />
            </IconButton>
          </Box>
          {selectedProject?.id && currentStep !== STEPS.stepOne ? (
            <Text>
              <Text as="span" className={s.modal__selectedProject__header__pretext}>
                {t('header.company.in')}:
              </Text>
              <Text as="span" className={s.modal__selectedProject__header__text}>
                {selectedProject.name}
              </Text>
            </Text>
          ) : null}
        </Box>
        <HorizontalRule />
        <FormProvider<CreateRoomFormProps> {...methods}>
          <form autoComplete="false" onSubmit={methods.handleSubmit(onSubmit)}>
            {steps[currentStep]}
          </form>
        </FormProvider>
      </Box>
    </RawModal>
  );
}
