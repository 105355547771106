import "src/app/features/room/message/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/features/room/message/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Vy5LaMBC88xVzSdXuQZTN8tiIS/4kJVtjo0WWHEnGZlP8e0oSGEEIkFQuGM/09Gi6p6xpua25mmfwcwLQaiuc0IqCQcmc2OF6AsCFbSXbU6gkDj7gn4QLg2UEl1p2jfKZXnC3obDIvvg33TkpFFLI2wGslhwKycrtenKYTGPbPLRlUtSKWJRV7EFQ8QQ0C6CHpzC6T4reni4iBndoLCbF8+eKT3OPdYu/qwNomKmFIkbUG0dhx8wLId/z5Xyoaszta0K9/EdqidUD5lVgvqkmwEdnnaj2pNTKoXIUbMtKJAW6HjH0ieYJh42lUDCL3vKE/v1P9Gfd/0ebr1cLzAqrZecCu9MtBZJP31cLg42PHAXP/P9PIhTHgUKe0LFAd4SxzmmPjFpmCayIXRnnQtW/yWxSmcsALTtjtTlRjkn+PA+e9ayN7i+PXd3Zkt6wloL/9aHav9zoMy5OEO3eQSqKTev2lx2VvnClDtleG04Kg2xLITyIjySwTYCFjtl0djLpKPc5cOvzNHKIqItWjvQYfSu0TLt8RAe09AbEyfSPJc9WbZg7lFrxiddjV+nU2+cmkpeyCOXXlZz8uFjmEpVDs77r/22nCm04GmIYF529rpq9RQwrt7XRneLkxuRZwJyWstUiHuU8Bt3oHZowzGOmsawJBY1Q5HghzIKDY17F/J0vVNy0bw1yweClYcOJKZ/P83Z4DQTT9O4aL588i7fPYXL4BbU6dafdBgAA\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var SpinnerOverrides = 'ckgdn4h';
export var HeaderRight = 'ckgdn48';
export var MessageInner = 'ckgdn44';
export var AvatarRight = 'ckgdn46';
export var ReactionButton = 'ckgdn4l';
export var ReactionsTooltipText = 'ckgdn4g';
export var MessageBaseRight = 'ckgdn41';
export var Timestamp = 'ckgdn4j';
export var MessageAvatar = 'ckgdn4c';
export var MessageMenuItemText = 'ckgdn4e';
export var MessageContentRight = 'ckgdn43';
export var MessageOptionsBar = 'ckgdn4b _1mqalmd1 _1mqalmd0';
export var MessageOptionsBase = 'ckgdn49 _1mqalmd1 _1mqalmd0';
export var MessageOptionsBaseRight = 'ckgdn4a';
export var MessageQuickReaction = 'ckgdn4m';
export var ReactionCount = 'ckgdn4n';
export var MessageContentLeft = 'ckgdn42';
export var ReactionsContainer = 'ckgdn4f';
export var Username = 'ckgdn4i';
export var MessageMenuGroup = 'ckgdn4d';
export var MessageContent = 'ckgdn4k';
export var MessageBase = 'ckgdn40';
export var HeaderLeft = 'ckgdn47';
export var AvatarLeft = 'ckgdn45';