import {z} from 'zod';
import {sentryLog} from '~/app/utils/logger';

export const envSchema = z.object({
  PUBLIC_DISABLE_LOGGING: z.preprocess((str) => str === 'true', z.boolean()).optional(),
  PUBLIC_FIREBASE_API_KEY: z.string().min(1),
  PUBLIC_FIREBASE_AUTH_DOMAIN: z.string().min(1),
  PUBLIC_FIREBASE_DATABASE_URL: z.string().min(1),
  PUBLIC_FIREBASE_MEASUREMENT_ID: z.string().min(1),
  PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string().min(1),
  PUBLIC_FIREBASE_PROJECT_ID: z.string().min(1),
  PUBLIC_FIREBASE_STORAGE_BUCKET: z.string().min(1),
  PUBLIC_JOURNEY_API_URL: z.string().min(1),
  PUBLIC_MATRIX_API_URL: z.string().min(1),
  PUBLIC_SENTRY_DSN: z.string().min(1).optional(),
  PUBLIC_SENTRY_RELEASE: z.string().min(1).optional(),
  PUBLIC_SENTRY_PROJECT: z.string().min(1).optional(),
  PUBLIC_SENTRY_URL: z.string().min(1).optional(),
  PUBLIC_RELEASE_TAG: z.string().min(1).optional(),
  PUBLIC_C4_URL: z.string().min(1),
  PUBLIC_MIXPANEL_TOKEN: z.string().min(1),
});

export function getEnv() {
  try {
    return envSchema.parse(JSON.parse(JSON.stringify(import.meta.env)));
  } catch (err) {
    const error = err as z.ZodError;
    const {fieldErrors} = error.flatten();
    const errorMessage = Object.entries(fieldErrors)
      .map(([field, errors]) => (errors ? `${field}: ${errors.join(', ')}` : field))
      .join('\n  ');

    // Log the error to the console instead of throwing
    sentryLog.error('Error parsing environment variables', errorMessage, {
      tags: {
        name: 'ENVIRONMENT_VARIABLES',
      },
      error: error.toString(),
    });

    // Return a default or partial environment object
    return {} as z.infer<typeof envSchema>;
  }
}

const ENV = getEnv();

export {ENV};
