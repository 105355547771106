import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {companyService} from '~/services/CompanyService/CompanyService';
import {CompanyModel} from '~/types/company';

export const useCompany = (companyId: string): UseQueryResult<CompanyModel, Error> => {
  return useQuery<CompanyModel, Error>({
    queryKey: QUERY_CACHE_KEYS.getCompany(companyId),
    queryFn: () => companyService.getCompany(companyId),
    enabled: Boolean(companyId),
    staleTime: 5 * 60 * 1000,
  });
};
