import {toRem} from 'folds';
import {SVGAttributes, forwardRef} from 'react';
import {CustomIconSrc} from './CustomIconsStore';

type Size = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700';

const sizeToPixels: Record<Size, string> = {
  '50': toRem(16),
  '100': toRem(18),
  '200': toRem(20),
  '300': toRem(22),
  '400': toRem(24),
  '500': toRem(28),
  '600': toRem(36),
  '700': toRem(50),
} as const;

export type CustomIconProps = SVGAttributes<SVGElement> & {
  filled?: boolean;
  src: CustomIconSrc;
  size?: Size;
};

const IconComponent = ({src}: {filled: boolean; src: CustomIconSrc}) => src();

export const CustomIcon = forwardRef<SVGSVGElement, CustomIconProps>(
  ({className, size = '400', filled = false, src, ...props}, ref) => {
    const dimensions = sizeToPixels[size];
    return (
      <svg
        className={className}
        focusable="false"
        height={dimensions}
        width={dimensions}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <IconComponent filled={filled} src={src} />
      </svg>
    );
  },
);
