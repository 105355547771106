import * as Sentry from '@sentry/browser';
import adze, {LogData, setup} from 'adze';

type LogLevel =
  | 'alert'
  | 'error'
  | 'warn'
  | 'info'
  | 'fail'
  | 'success'
  | 'log'
  | 'debug'
  | 'verbose';

const logLevelToSentrySeverity: Record<LogLevel, Sentry.SeverityLevel> = {
  alert: 'fatal',
  error: 'error',
  warn: 'warning',
  info: 'info',
  fail: 'error',
  success: 'info',
  log: 'info',
  debug: 'debug',
  verbose: 'debug',
};

// Configure Adze globally and get the store reference
const store = setup();

store.addListener('*', (log) => {
  const data: LogData | undefined = log.data;
  const level = logLevelToSentrySeverity[data?.levelName as LogLevel] || 'info';
  const message = data?.args.join(' ');

  // Prepare the extra data
  const extra = {...data?.meta};
  delete extra.error; // Remove error from extra to avoid duplication

  try {
    if (data?.levelName === 'error') {
      const error = data.meta.error instanceof Error ? data.meta.error : new Error(message);
      Sentry.captureException(error, {
        level,
        extra,
      });
    } else {
      Sentry.captureMessage(message ?? '', {
        level,
        extra,
      });
    }
  } catch (sentryError) {
    // Log to console if Sentry capture fails
    console.error('Failed to log to Sentry:', sentryError);
  }
});

// Create a logger where all logs generated include the "app" namespace,
export const logger = adze.timestamp.namespace('app').seal();

// biome-ignore lint/suspicious/noExplicitAny:
type LogMeta = Record<string, any>;

export const sentryLog = {
  debug: (message: string, meta: LogMeta) => logger.debug(message, meta),
  info: (message: string, meta: LogMeta) => logger.info(message, meta),
  log: (message: string, meta: LogMeta) => logger.log(message, meta),
  warn: (message: string, meta: LogMeta) => logger.warn(message, meta),
  error: (message: string, error: unknown, meta: LogMeta) =>
    logger.error(message, {error, ...meta}),
};

if (typeof window !== 'undefined') {
  window.addEventListener('error', (event) => {
    sentryLog.error('Unhandled error', event.error, {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
    });
  });

  window.addEventListener('unhandledrejection', (event) => {
    let error = event.reason;
    if (!(error instanceof Error)) {
      error = new Error(String(error));
    }
    sentryLog.error('Unhandled promise rejection', error, {
      promise: event.promise,
      reason: event.reason,
      cause: error?.cause ?? error,
    });
  });
}
