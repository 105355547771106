export type CustomIconName = 'Group' | 'Invite' | 'Folder' | 'Clipboard' | 'Warning';

export type CustomIconSrc = () => JSX.Element;

export const CustomIcons: Record<CustomIconName, CustomIconSrc> = {
  Group: () => (
    <>
      <rect width="24" height="24" rx="12" fill="#053fe3" id="rect1" x="0" y="0" />
      <path
        d="m 11.680981,13.40154 c 0.503159,-0.33426 0.885298,-0.82158 1.089959,-1.38984 0.2046,-0.56832 0.220859,-1.1874 0.04633,-1.76568 C 12.64267,9.6678 12.28657,9.1611 11.80171,8.80092 11.316789,8.4408 10.728789,8.24634 10.12477,8.24634 9.5207506,8.24634 8.9327506,8.4408 8.4478294,8.80092 7.96291,9.1611 7.60681,9.6678 7.43227,10.24602 7.25773,10.8243 7.27393,11.44338 7.478602,12.0117 c 0.2046,0.56826 0.5868,1.05558 1.0898994,1.38984 -0.8582994,0.3156 -1.5992994,0.88656 -2.1233994,1.63596 -0.04098,0.05556 -0.065339,0.12162 -0.07032,0.19044 -0.00504,0.06888 0.00966,0.1377 0.042174,0.1986 0.032112,0.06048 0.08004,0.11118 0.1386,0.14664 0.058632,0.03552 0.1257,0.05448 0.19422,0.0549 h 6.75 c 0.06846,-4.2e-4 0.1356,-0.01938 0.19416,-0.0549 0.05863,-0.03546 0.1065,-0.08616 0.13866,-0.14664 0.03252,-0.0609 0.04709,-0.12972 0.04212,-0.1986 -0.005,-0.06882 -0.02935,-0.13488 -0.07026,-0.19044 -0.5241,-0.7494 -1.26516,-1.32036 -2.12346,-1.63596 z"
        fill="currentColor"
        id="path1"
      />
      <path
        d="m 17.62968,15.0375 c -0.52704,-0.74844 -1.269241,-1.31904 -2.12808,-1.6359 0.50472,-0.333 0.88848,-0.81996 1.09416,-1.38858 0.20574,-0.56856 0.222359,-1.18836 0.04751,-1.76718 C 16.468429,9.66702 16.11137,9.16008 15.62525,8.8005 15.13919,8.44092 14.54999,8.24784 13.945309,8.25 13.68725,8.25138 13.43045,8.28606 13.18127,8.35314 13.1227,8.37072 13.06925,8.40198 13.02521,8.4444 12.98116,8.48676 12.94793,8.53908 12.92813,8.59692 12.9093,8.65422 12.90456,8.7153 12.91434,8.77488 c 0.0098,0.05958 0.03384,0.11592 0.07008,0.16422 0.4239,0.56958 0.6675,1.25316 0.69924,1.96254 0.03175,0.70932 -0.14976,1.41192 -0.52116,2.01714 -0.04757,0.0804 -0.0639,0.17556 -0.04572,0.26724 0.01817,0.09168 0.06948,0.17346 0.14418,0.22962 0.14064,0.10938 0.27654,0.22188 0.40782,0.3375 l 0.02339,0.02346 c 0.5004,0.46296 0.9063,1.01856 1.19532,1.6359 0.02971,0.06456 0.07728,0.11916 0.137099,0.15738 0.05982,0.03822 0.129421,0.05844 0.2004,0.05826 h 2.095321 c 0.06852,-4.2e-4 0.1356,-0.01944 0.19422,-0.0549 0.05857,-0.03552 0.1065,-0.08616 0.1386,-0.14664 0.03217,-0.06078 0.04692,-0.12924 0.04277,-0.19782 -0.0041,-0.06864 -0.02701,-0.13476 -0.06624,-0.19128 z"
        fill="currentColor"
        id="path2"
      />
    </>
  ),
  Invite: () => (
    <>
      <rect width="24" height="24" rx="12" fill="#053fe3" id="rect1" x="0" y="0" />
      <path
        d="m 18.00024,12.37494 c 0,0.09942 -0.03948,0.19482 -0.1098,0.26514 -0.07032,0.07032 -0.16572,0.10986 -0.2652,0.10986 h -0.75 v 0.75 c 0,0.09942 -0.03948,0.19482 -0.1098,0.26514 -0.07032,0.07032 -0.16572,0.10986 -0.2652,0.10986 -0.09942,0 -0.19482,-0.03954 -0.26514,-0.10986 -0.07032,-0.07032 -0.10986,-0.16572 -0.10986,-0.26514 v -0.75 h -0.75 c -0.09942,0 -0.19482,-0.03954 -0.26514,-0.10986 -0.07032,-0.07032 -0.10986,-0.16572 -0.10986,-0.26514 0,-0.09948 0.03954,-0.19488 0.10986,-0.2652 0.07032,-0.07032 0.16572,-0.1098 0.26514,-0.1098 h 0.75 v -0.75 c 0,-0.09948 0.03954,-0.19488 0.10986,-0.2652 0.07032,-0.07032 0.16572,-0.1098 0.26514,-0.1098 0.09948,0 0.19488,0.03948 0.2652,0.1098 0.07032,0.07032 0.1098,0.16572 0.1098,0.2652 v 0.75 h 0.75 c 0.09948,0 0.19488,0.03948 0.2652,0.1098 0.07032,0.07032 0.1098,0.16572 0.1098,0.2652 z m -5.24532,1.0125 c 0.58632,-0.36732 1.03722,-0.91542 1.2846,-1.5615 0.24738,-0.64608 0.27786,-1.35516 0.08676,-2.02008 C 13.93518,9.14094 13.53294,8.55618 12.98034,8.13996 12.42768,7.7238 11.7546,7.49868 11.06274,7.49868 c -0.69186,0 -1.36488,0.22512 -1.91754,0.64128 -0.55266,0.41622 -0.9549,1.00098 -1.146,1.6659 -0.19104,0.66492 -0.16062,1.374 0.08682,2.02008 0.24738,0.64608 0.69822,1.19418 1.28454,1.5615 -1.02228,0.3183 -1.93062,0.9255 -2.61564,1.7484 -0.04656,0.05412 -0.0762,0.12072 -0.08538,0.19152 -0.00918,0.0708 0.00258,0.14274 0.03384,0.20694 0.02964,0.0645 0.07722,0.11916 0.1371,0.15738 0.05982,0.03822 0.12936,0.05844 0.2004,0.05826 h 8.04372 c 0.07104,1.8e-4 0.14058,-0.02004 0.20046,-0.05826 0.05982,-0.03822 0.1074,-0.09288 0.13704,-0.15738 0.03126,-0.0642 0.04302,-0.13614 0.0339,-0.20694 -0.0092,-0.0708 -0.03888,-0.1374 -0.08544,-0.19152 -0.68496,-0.8229 -1.59336,-1.4301 -2.61564,-1.7484 z"
        fill="currentColor"
        stroke="currentColor"
        id="path1"
      />
    </>
  ),
  Folder: () => (
    <path
      d="M 22.153846,3.692308 H 12.381932 L 9.223846,0.53424 C 8.882308,0.195009 8.4115385,0 7.9327015,0 H 1.8461538 C 0.8284615,0 0,0.828462 0,1.846154 v 16.684615 c 0,0.979569 0.7973169,1.776923 1.7769231,1.776923 H 22.257785 C 23.218892,20.307692 24,19.526585 24,18.565292 V 5.538462 C 24,4.520769 23.171631,3.692308 22.153846,3.692308 Z M 7.92,1.841539 9.770769,3.692308 H 1.8461538 V 1.846154 Z M 22.153846,18.461538 H 1.8461538 V 5.538462 H 22.153846 Z"
      fill="#45474a"
      id="path1"
    />
  ),
  Clipboard: () => (
    <>
      <g transform="scale(0.09375)">
        {/* transform="scale(0.09375)" This scales the icon from 256x256 to 24x24 */}
        <path
          d="M166,152a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,152Zm-6-38H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm54-66V216a14,14,0,0,1-14,14H56a14,14,0,0,1-14-14V48A14,14,0,0,1,56,34H93.17a45.91,45.91,0,0,1,69.66,0H200A14,14,0,0,1,214,48ZM94,64v2h68V64a34,34,0,0,0-68,0ZM202,48a2,2,0,0,0-2-2H170.33A45.77,45.77,0,0,1,174,64v8a6,6,0,0,1-6,6H88a6,6,0,0,1-6-6V64a45.77,45.77,0,0,1,3.67-18H56a2,2,0,0,0-2,2V216a2,2,0,0,0,2,2H200a2,2,0,0,0,2-2Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0"
        />
      </g>
    </>
  ),
  Warning: () => (
    <g transform="scale(0.046875)">
      {/*transform="scale(0.046875)" scales the icon from 512x512 to 24x24 */}
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M85.57 446.25h340.86a32 32 0 0 0 28.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0 0 28.17 47.17z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="m250.26 195.39 5.74 122 5.73-121.95a5.74 5.74 0 0 0-5.79-6h0a5.74 5.74 0 0 0-5.68 5.95z"
      />
      <path fill="currentColor" d="M256 397.25a20 20 0 1 1 20-20 20 20 0 0 1-20 20z" />
    </g>
  ),
};
