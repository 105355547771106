import {Activity} from './activity';
import {CompanyOrgs} from './company';
import {TaskAssignees} from './member';
import {SortOrder} from './sort';
import {TaskIssueImpact, TaskType} from './task';
import {TaskFilterQuery} from './taskQueryfilter';
import {TaskStatusQuery} from './taskStatus';

export type IssueModel = {
  activities?: Activity[];
  cost_impact?: boolean;
  cost_tracking_number?: string;
  culpable_org_id?: string;
  impact: string;
  issue_type: string;
  task_ids: string[];
} & TaskType;

export type IssueModelDTO = {
  activities?: Activity[];
  cost_days?: number;
  cost_tracking_number?: string;
  culpable_org_id?: string;
  culpable_org?: CompanyOrgs;
  impact: TaskIssueImpact;
  issue_type: string;
  task_ids: string[];
} & TaskType;

export type IssueWithAssigneesDTO = Partial<IssueModelDTO> & {assignees?: Partial<TaskAssignees[]>};

export type IssuesParams = {
  range_params?: {
    offset?: number;
    limit?: number;
  };
  sort_params?: {
    sort_field?: string;
    sort_order?: SortOrder;
  };
  filter_params?: TaskFilterQuery & TaskStatusQuery;
};

export enum IssueStatus {
  TBA = 'tba',
  InProgress = 'in_progress',
  Closed = 'closed',
}
