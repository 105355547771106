import {Box, config} from 'folds';
import CrewsLogo from '../../../../public/res/svg/logo-crews-by-core.svg';
import {Page, PageHero, PageHeroSection} from '../../components/page';

export function WelcomePage() {
  return (
    <Page>
      <Box
        grow="Yes"
        style={{padding: config.space.S400, paddingBottom: config.space.S700}}
        alignItems="Center"
        justifyContent="Center"
      >
        <PageHeroSection>
          <PageHero
            icon={<img width="170" height="170" src={CrewsLogo} alt="Crews by Core Logo" />}
            title="Welcome to Crews by Core Chat"
            subTitle={<span />}
          />
        </PageHeroSection>
      </Box>
    </Page>
  );
}
