import classNames from 'classnames';
import {Box, as} from 'folds';
import {ComponentProps} from 'react';

import * as css from './RoomInputPlaceholder.css';

export const RoomInputPlaceholder = as<'div', ComponentProps<typeof Box>>(
  ({className, ...props}, ref) => (
    <Box className={classNames(css.RoomInputPlaceholder, className)} {...props} ref={ref} />
  ),
);
