import {QueryKey, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {TaskType} from '~/types/task';

export function useTaskById({
  projectId,
}: {
  projectId: string | undefined;
}) {
  const queryClient = useQueryClient();

  const getTaskById = useCallback(
    (taskId: string): TaskType | null => {
      if (projectId === undefined) {
        return null;
      }

      const findTaskInQuery = (queryKey: QueryKey) => {
        const tasks = queryClient.getQueryData<TaskType[]>(queryKey);
        return tasks?.find((task) => task.id === taskId) || null;
      };

      const queryKeys = queryClient.getQueryCache().findAll({
        queryKey: QUERY_CACHE_KEYS.tasksByProjectId(projectId, ''),
      });

      for (const query of queryKeys) {
        const task = findTaskInQuery(query.queryKey);
        if (task) {
          return task;
        }
      }

      return null;
    },
    [queryClient, projectId],
  );

  return getTaskById;
}
