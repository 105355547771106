import React, {ButtonHTMLAttributes} from 'react';
import './IconButton.scss';

import RawIcon from '../system-icons/RawIcon';
import Text from '../text/Text';
import Tooltip from '../tooltip/Tooltip';
import {blurOnBubbling} from './script';

type IconButtonVariant = 'surface' | 'primary' | 'positive' | 'caution' | 'danger';
type IconButtonSize = 'normal' | 'small' | 'extra-small';
type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isImage?: boolean;
  size?: IconButtonSize;
  src: string;
  tooltip?: string | null;
  tooltipPlacement?: TooltipPlacement;
  variant?: IconButtonVariant;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className = '',
      disabled = false,
      isImage = false,
      onClick,
      size = 'normal',
      src,
      tabIndex = 0,
      tooltip = null,
      tooltipPlacement = 'top',
      type = 'button',
      variant = 'surface',
      ...restProps
    },
    ref,
  ) => {
    const btn = (
      <button
        ref={ref}
        className={`ic-btn ic-btn-${variant} ${className}`}
        onMouseUp={(e) => blurOnBubbling(e, `.ic-btn-${variant}`)}
        onClick={onClick}
        type={type}
        tabIndex={tabIndex}
        disabled={disabled}
        {...restProps}
      >
        <RawIcon size={size} src={src} isImage={isImage} />
      </button>
    );
    if (tooltip === null) return btn;
    return (
      <Tooltip placement={tooltipPlacement} content={<Text variant="b2">{tooltip}</Text>}>
        {btn}
      </Tooltip>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
