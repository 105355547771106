import {useCallback} from 'react';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';

type Action = {
  action: () => void;
  event: string;
};

type ActionType = 'image' | 'userMention' | 'activityMention' | 'issueMention';

export type Actions = Partial<{
  [key in ActionType]: Action;
}>;

interface UseActionHandlerProps {
  actions: Actions;
  dependencies?: any[];
}

export const useActionHandler = ({actions, dependencies = []}: UseActionHandlerProps) => {
  const handleAction = useCallback(
    (actionType: ActionType) => {
      const selectedAction = actions[actionType];

      if (selectedAction) {
        mixpanelService.trackWithAction(selectedAction.action, selectedAction.event);
      }
    },
    [actions, ...dependencies],
  );

  return handleAction;
};
