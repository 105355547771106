import {User, UserMetadata} from 'firebase/auth';
import {ChatGroup} from '~/types/chat';
import {JourneyApi} from '~/util/api-helper';
import {ENV} from '~/util/env';
import {CompanyWorker} from '../../types/companyWorker';
import {Project} from '../../types/project';

const SIGNUP_PATH = 'loginOrSignupWorker';
const CHECK_SIGNUP = 'checksignup';

export type SupportedLoginTypes = 'phone' | 'password' | 'anonymous';

export type LoginOrSignupWorkerParams = {
  user: User;
  idToken?: string;
  loginType?: SupportedLoginTypes;
  phoneNumber?: string;
  inviteCode?: string;
};

type WorkerProjectParams = {workerId: string; showWorkerCount: boolean};

function mapSignupFields({user, loginType, phoneNumber, inviteCode}: LoginOrSignupWorkerParams) {
  const userMetaData: UserMetadata = {
    lastSignInTime: user.metadata.lastSignInTime as string,
    creationTime: user.metadata.creationTime as string,
  };

  if (!userMetaData.lastSignInTime || !userMetaData.creationTime) {
    throw new Error('User metadata is missing');
  }

  const signupFields = {
    api_key: ENV.PUBLIC_FIREBASE_API_KEY,
    refresh_token: user.refreshToken,
    firebase_uid: user.uid,
    last_login_at: new Date(userMetaData.lastSignInTime).getTime(),
    created_at: new Date(userMetaData.creationTime).getTime(),
    expiration_time: Date.parse(userMetaData.lastSignInTime) + 3600000,
    login_type: loginType === 'password' ? 'email' : loginType,
    source: 'web',
  };

  if (inviteCode) Object.assign(signupFields, {invite_code: inviteCode});
  if (loginType === 'phone') {
    Object.assign(signupFields, {phone_number: user.phoneNumber});
  }
  if (loginType === 'password') {
    Object.assign(signupFields, {email: user.email, phone_number: phoneNumber});
  }

  return signupFields;
}

class WorkerService {
  private static instance: WorkerService | null = null;

  public static getInstance(): WorkerService {
    if (!WorkerService.instance) {
      WorkerService.instance = new WorkerService();
    }
    return WorkerService.instance;
  }

  private getWorkersBasePath(workerId: string): string {
    return `workers/${workerId}`;
  }

  async checkSignUp(): Promise<boolean> {
    try {
      const response = await JourneyApi.get(`${CHECK_SIGNUP}`, {});

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data as boolean;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async loginOrSignupWorker({
    user,
    loginType = 'phone',
    phoneNumber,
    inviteCode,
  }: LoginOrSignupWorkerParams): Promise<any> {
    const body = mapSignupFields({
      user,
      loginType,
      phoneNumber,
      inviteCode,
    });

    try {
      const response = await JourneyApi.post(SIGNUP_PATH, {
        json: body,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCompanyWorkersByProjectId(projectId: string, filter?: string): Promise<CompanyWorker[]> {
    let endpoint = `projects/${projectId}/companyworkers`;

    if (filter) {
      const params = new URLSearchParams();
      params.set('company_name', filter);
      endpoint = `${endpoint}?${params}`;
    }
    const response = await JourneyApi.get(endpoint);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  }

  async getAllProjectsForWorker({
    workerId,
    showWorkerCount = false,
  }: WorkerProjectParams): Promise<Project[]> {
    const searchParams = new URLSearchParams({
      show_worker_count: showWorkerCount.toString(),
    });
    const endpoint = `workers/${workerId}/projects`;
    const response = await JourneyApi.get(endpoint, {searchParams});

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  }

  async getWorkersPorjectsById({workerId}: {workerId: string}) {
    const response = await JourneyApi.get(`${this.getWorkersBasePath(workerId)}/projects`);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  }

  async getChatsByWorkerId({workerId}: {workerId: string}): Promise<ChatGroup[]> {
    const response = await JourneyApi.get(`${this.getWorkersBasePath(workerId)}/chats`);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data: ChatGroup[] = await response.json();

    return data;
  }

  async getWorkerById(id: string): Promise<CompanyWorker> {
    const response = await JourneyApi.get(this.getWorkersBasePath(id));

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as CompanyWorker;
  }
}

export const workerService = WorkerService.getInstance();
