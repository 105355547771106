import * as Sentry from '@sentry/react';
import FocusTrap from 'focus-trap-react';
import {
  Box,
  Button,
  Dialog,
  Header,
  Icon,
  IconButton,
  Icons,
  Overlay,
  OverlayBackdrop,
  OverlayCenter,
  Spinner,
  Text,
  config,
} from 'folds';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {toast} from 'sonner';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {getHomePath} from '~/app/pages/pathUtils';
import {useLeaveRoom} from '~/mutations/useLeaveRoom';
import {useGroupInfoForRoom} from '~/queries/useGroupInfoForRoom';

type LeaveRoomPromptProps = {
  roomId: string;
  onDone: () => void;
  onCancel: () => void;
};
export function LeaveRoomPrompt({roomId, onDone, onCancel}: LeaveRoomPromptProps) {
  const mx = useMatrixClient();
  const {t} = useTranslation('leave_room');
  const navigate = useNavigate();
  const room = mx.getRoom(roomId);
  const {data: groupInfo} = useGroupInfoForRoom(roomId);
  const direct = groupInfo?.direct ?? false;
  const leaveRoomMutation = useLeaveRoom({
    onError: (e) => {
      toast.error(t('error'));
      onDone();
      Sentry.captureException(e);
    },
    onSuccess: (data) => {
      if (data.success) {
        onDone();
        // awkward staying in room while it syncs with matrix - this goes home
        navigate(getHomePath());
        toast.success(t('success', {roomName: room?.name}));
      } else {
        onDone();
        toast.warning(t('error'));
      }
    },
  });

  const handleLeave = () => {
    const userId = mx.getUserId();
    if (userId) {
      leaveRoomMutation.mutate({roomId, direct});
    }
  };

  return (
    <Overlay open backdrop={<OverlayBackdrop />}>
      <OverlayCenter>
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false,
            onDeactivate: onCancel,
            clickOutsideDeactivates: true,
          }}
        >
          <Dialog variant="Surface">
            <Header
              style={{
                padding: `0 ${config.space.S200} 0 ${config.space.S400}`,
                borderBottomWidth: config.borderWidth.B300,
              }}
              variant="Surface"
              size="500"
            >
              <Box grow="Yes">
                <Text size="H4">Leave Room</Text>
              </Box>
              <IconButton size="300" onClick={onCancel} radii="300">
                <Icon src={Icons.Cross} />
              </IconButton>
            </Header>
            <Box style={{padding: config.space.S400}} direction="Column" gap="400">
              <Box direction="Column" gap="200">
                <Text priority="400">Are you sure you want to leave this room?</Text>
              </Box>
              <Button
                disabled={leaveRoomMutation.isPending}
                type="submit"
                variant="Critical"
                onClick={handleLeave}
                after={
                  leaveRoomMutation.isPending ? (
                    <Spinner fill="Solid" variant="Critical" size="200" />
                  ) : null
                }
                aria-disabled={
                  leaveRoomMutation.isPending ||
                  leaveRoomMutation.isSuccess ||
                  leaveRoomMutation.isError
                }
              >
                <Text size="B400">{leaveRoomMutation.isPending ? 'Leaving...' : 'Leave'}</Text>
              </Button>
            </Box>
          </Dialog>
        </FocusTrap>
      </OverlayCenter>
    </Overlay>
  );
}
