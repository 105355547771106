import {IconSrc, Icons} from 'folds';
import {MatrixEvent} from 'matrix-js-sdk';
import {ReactNode} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {IMemberContent, Membership} from '~/types/matrix/room';
import {getMxIdLocalPart} from '../utils/matrix';
import {isMembershipChanged} from '../utils/room';

export type ParsedResult = {
  icon: IconSrc;
  body: ReactNode;
} | null;

export type MemberEventParser = (mEvent: MatrixEvent) => ParsedResult;

export const useMemberEventParser = (): MemberEventParser => {
  const {t} = useTranslation('room_timeline');
  const parseMemberEvent: MemberEventParser = (mEvent) => {
    const content = mEvent.getContent<IMemberContent>();
    const prevContent = mEvent.getPrevContent() as IMemberContent;
    const senderId = mEvent.getSender();
    const userId = mEvent.getStateKey();

    if (!senderId || !userId)
      return {
        icon: Icons.User,
        body: t('error.broken_event'),
      };

    const senderName = getMxIdLocalPart(senderId);
    const userName = content.displayname || getMxIdLocalPart(userId);

    if (isMembershipChanged(mEvent)) {
      if (content.membership === Membership.Invite) {
        if (prevContent.membership === Membership.Knock) {
          return {
            icon: Icons.ArrowGoRightPlus,
            body: (
              <>
                <b>{senderName}</b>
                {t('membership_changes.accepted')}
                <Trans
                  i18nKey="pluralization.pluralize_join_request"
                  values={{name: userName, reason: content.reason}}
                  components={{bold: <strong />}}
                />
              </>
            ),
          };
        }
        return null;
      }

      if (content.membership === Membership.Knock) {
        return {
          icon: Icons.ArrowGoRightPlus,
          body: (
            <>
              <b>{userName}</b>
              {t('membership_changes.request_to_join')}
              {content.reason}
            </>
          ),
        };
      }

      if (content.membership === Membership.Join) {
        return null;
      }

      if (content.membership === Membership.Leave) {
        if (prevContent.membership === Membership.Invite) {
          return {
            icon: Icons.ArrowGoRightCross,
            body:
              senderId === userId ? (
                <>
                  <b>{userName}</b>
                  {t('membership_changes.rejected_invitation')}
                  {content.reason}
                </>
              ) : (
                <>
                  <b>{senderName}</b>
                  {t('membership_changes.rejected')}
                  <Trans
                    i18nKey="pluralization.pluralize_join_request"
                    values={{name: userName, reason: content.reason}}
                    components={{bold: <strong />}}
                  />
                </>
              ),
          };
        }

        if (prevContent.membership === Membership.Knock) {
          return {
            icon: Icons.ArrowGoRightCross,
            body:
              senderId === userId ? (
                <>
                  <b>{userName}</b>
                  {t('membership_changes.revoked_joined_request')}
                  {content.reason}
                </>
              ) : (
                <>
                  <b>{senderName}</b>
                  {t('membership_changes.revoked')}
                  <Trans
                    i18nKey="pluralization.pluralize_invite"
                    values={{name: userName, reason: content.reason}}
                    components={{bold: <strong />}}
                  />
                </>
              ),
          };
        }

        if (prevContent.membership === Membership.Ban) {
          return {
            icon: Icons.ArrowGoLeft,
            body: (
              <>
                <b>{senderName}</b>
                {t('membership_changes.unbanned')}
                <b>{userName}</b> {content.reason}
              </>
            ),
          };
        }

        if (senderId === userId) {
          return {
            icon: Icons.ArrowGoLeft,
            body: (
              <>
                <b>{userName}</b>
                {t('membership_changes.left_room')}
                {content.reason}
              </>
            ),
          };
        }
        return null;
      }

      if (content.membership === Membership.Ban) {
        return {
          icon: Icons.ArrowGoLeft,
          body: (
            <>
              <b>{senderName}</b>
              {t('membership_changes.banned')}
              <b>{userName}</b> {content.reason}
            </>
          ),
        };
      }
    }

    if (content.displayname !== prevContent.displayname) {
      const prevUserName = prevContent.displayname || userId;

      return {
        icon: Icons.Mention,
        body: content.displayname ? (
          <>
            <b>{prevUserName}</b>
            {t('profile_changes.change_display_name')}
            <b>{userName}</b>
          </>
        ) : (
          <>
            <b>{prevUserName}</b>
            {t('profile_changes.remove_display_name')}
          </>
        ),
      };
    }
    if (content.avatar_url !== prevContent.avatar_url) {
      return {
        icon: Icons.User,
        body: content.displayname ? (
          <>
            <b>{userName}</b>
            {t('profile_changes.change_avatar')}
          </>
        ) : (
          <>
            <b>{userName}</b>
            {t('profile_changes.remove_avatar')}
          </>
        ),
      };
    }

    return {
      icon: Icons.User,
      body: t('error.broken_event'),
    };
  };

  return parseMemberEvent;
};
