import {Box, Text, color, config} from 'folds';
import {EventType, Room} from 'matrix-js-sdk';
import {useRef} from 'react';

import {ContainerColor} from '~/app/styles/ContainerColor.css';
import {StateEvent} from '../../../types/matrix/room';
import {useEditor} from '../../components/editor';
import {Page} from '../../components/page';
import {useMatrixClient} from '../../hooks/useMatrixClient';
import {usePowerLevelsAPI, usePowerLevelsContext} from '../../hooks/usePowerLevels';
import {useStateEvent} from '../../hooks/useStateEvent';
import {RoomInput} from './RoomInput';
import {RoomInputPlaceholder} from './RoomInputPlaceholder';
import {RoomTimeline} from './RoomTimeline';
import {RoomTombstone} from './RoomTombstone';
import {RoomViewFollowing} from './RoomViewFollowing';
import {RoomViewHeader} from './RoomViewHeader';
import {RoomViewTyping} from './RoomViewTyping';

export function RoomView({room, eventId}: {room: Room; eventId?: string}) {
  const roomInputRef = useRef(null);
  const roomViewRef = useRef(null);

  const {roomId} = room;
  const editor = useEditor();

  const mx = useMatrixClient();

  const tombstoneEvent = useStateEvent(room, StateEvent.RoomTombstone);
  const powerLevels = usePowerLevelsContext();
  const {getPowerLevel, canSendEvent} = usePowerLevelsAPI(powerLevels);
  const myUserId = mx.getUserId();
  const canMessage = myUserId
    ? canSendEvent(EventType.RoomMessage, getPowerLevel(myUserId))
    : false;

  return (
    <Page ref={roomViewRef} className={ContainerColor({variant: 'Surface'})}>
      <RoomViewHeader />
      <Box grow="Yes" direction="Column">
        <RoomTimeline
          key={roomId}
          room={room}
          eventId={eventId}
          roomInputRef={roomInputRef}
          editor={editor}
        />
        <RoomViewTyping room={room} />
      </Box>
      <Box shrink="No" direction="Column">
        <div
          style={{padding: `0 ${config.space.S400}`, backgroundColor: color.Surface.ContainerHover}}
        >
          {tombstoneEvent ? (
            <RoomTombstone
              roomId={roomId}
              body={tombstoneEvent.getContent().body}
              replacementRoomId={tombstoneEvent.getContent().replacement_room}
            />
          ) : (
            <>
              {canMessage && (
                <RoomInput
                  room={room}
                  editor={editor}
                  roomId={roomId}
                  fileDropContainerRef={roomViewRef}
                  ref={roomInputRef}
                />
              )}
              {!canMessage && (
                <RoomInputPlaceholder
                  style={{padding: config.space.S200}}
                  alignItems="Center"
                  justifyContent="Center"
                >
                  <Text align="Center">You do not have permission to post in this room</Text>
                </RoomInputPlaceholder>
              )}
            </>
          )}
        </div>
        <RoomViewFollowing room={room} />
      </Box>
    </Page>
  );
}
