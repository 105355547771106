import {Box} from 'folds';
import {ReactNode} from 'react';

type ClientLayoutProps = {
  // nav: ReactNode;
  children: ReactNode;
};
export function ClientLayout({/* nav,  */ children}: ClientLayoutProps) {
  return (
    <Box style={{height: '100%'}}>
      {/* <Box shrink="No">{nav}</Box> */}
      <Box grow="Yes">{children}</Box>
    </Box>
  );
}
