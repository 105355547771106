import {MatrixClient, MatrixEvent, RoomMember, RoomMemberEvent} from 'matrix-js-sdk';
import {useEffect, useState} from 'react';
import {Membership} from '../../types/matrix/room';

export const useRoomMembers = (mx: MatrixClient, roomId: string): RoomMember[] => {
  const [members, setMembers] = useState<RoomMember[]>([]);

  useEffect(() => {
    const room = mx.getRoom(roomId);
    let loadingMembers = true;
    let disposed = false;

    const filterMembers = (membersList: RoomMember[]) => {
      return membersList?.filter((member) => {
        if (member.name === 'corebot') {
          return false;
        }

        if (member.membership === Membership.Leave) {
          return false;
        }

        return true;
      });
    };

    const sortMembers = (membersList: RoomMember[]) => {
      return membersList?.sort((a, b) => (a.name || a.userId).localeCompare(b.name || b.userId));
    };

    const updateMemberList = (event?: MatrixEvent) => {
      if (!room || disposed || (event && event.getRoomId() !== roomId)) return;
      if (loadingMembers) return;

      const allMembers = room.getMembers();
      const filteredMembers = filterMembers(allMembers);
      const sortedMembers = sortMembers(filteredMembers);

      setMembers(sortedMembers);
    };

    if (room) {
      const initialMembers = room.getMembers();
      const filteredInitialMembers = filterMembers(initialMembers);
      const sortedInitialMembers = sortMembers(filteredInitialMembers);
      setMembers(sortedInitialMembers);
      room
        .loadMembersIfNeeded()
        .then(() => {
          loadingMembers = false;
          if (disposed) return;
          updateMemberList();
        })
        .catch((error) => {
          return new Error(error);
        });
    }

    mx.on(RoomMemberEvent.Membership, updateMemberList);
    mx.on(RoomMemberEvent.PowerLevel, updateMemberList);
    return () => {
      disposed = true;
      mx.removeListener(RoomMemberEvent.Membership, updateMemberList);
      mx.removeListener(RoomMemberEvent.PowerLevel, updateMemberList);
    };
  }, [mx, roomId]);

  return members;
};
