import classNames from 'classnames';
import {Header, as} from 'folds';
import {ReactNode} from 'react';
import * as css from './styles.css';

export type NavCategoryHeaderProps = {
  children: ReactNode;
  sticky?: boolean;
};
export const NavCategoryHeader = as<'div', NavCategoryHeaderProps>(
  ({className, sticky, ...props}, ref) => (
    <Header
      className={classNames(css.NavCategoryHeader, sticky ? css.NavCategorySticky : '', className)}
      variant="Background"
      size="300"
      {...props}
      ref={ref}
    />
  ),
);
