import classNames from 'classnames';
import {Chip, Icon, Icons, Text, as} from 'folds';
import * as css from './styles.css';

export const RoomNavCategoryButton = as<'button', {closed?: boolean}>(
  ({className, closed, children, ...props}, ref) => (
    <Chip
      className={classNames(css.CategoryButton, className)}
      variant="Background"
      radii="Pill"
      before={
        <Icon
          className={css.CategoryButtonIcon}
          size="50"
          src={closed ? Icons.ChevronRight : Icons.ChevronBottom}
        />
      }
      {...props}
      ref={ref}
    >
      <Text size="O400" priority="400" truncate>
        {children}
      </Text>
    </Chip>
  ),
);
