import {ReactNode} from 'react';

import {useMatrixClient} from '../../hooks/useMatrixClient';
import {useBindAtoms} from '../../state/hooks/useBindAtoms';

type ClientBindAtomsProps = {
  children: ReactNode;
};
export function ClientBindAtoms({children}: ClientBindAtomsProps) {
  const mx = useMatrixClient();
  useBindAtoms(mx);

  return children;
}
