import cn from 'classnames';
import {
  Avatar,
  Box,
  Button,
  Icon,
  Icons,
  Input,
  MenuItem,
  Scroll,
  Spinner,
  Text,
  color,
} from 'folds';
import {useAtom} from 'jotai';
import {ChangeEventHandler, ReactNode, useMemo, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {CustomIcon} from '~/app/atoms/system-icons/CustomIcon';
import {CustomIconSrc, CustomIcons} from '~/app/atoms/system-icons/CustomIconsStore';
import {UserAvatar} from '~/app/components/user-avatar/UserAvatar';
import {useDebounce} from '~/app/hooks/useDebounce';
import {workerAtom} from '~/app/state/worker';
import {nameInitials} from '~/app/utils/common';
import {useDistinctProjectWorkers} from '~/queries/useProjectWorkers';
import {EmptyResults, HorizontalRule} from '../CreateRoom';
import s from '../CreateRoom.module.scss';
import {useWorkerProfileInfo} from '../hooks/useWorkerProfileInfo';
import {CreateRoomFormProps} from '../types/types';

type GroupButtonProps = {
  children: ReactNode;
  disabled: boolean;
  name: CustomIconSrc;
  onClick: () => void;
};

function GroupButton({children, disabled, name, onClick}: GroupButtonProps) {
  return (
    <Button
      after={<Icon src={Icons.ChevronRight} />}
      before={
        <CustomIcon src={name} size="600" className={s.modal__chatTypeSelection__button__icon} />
      }
      className={s.modal__chatTypeSelection__button}
      disabled={disabled}
      fill="None"
      onClick={onClick}
      type="button"
      variant="Primary"
    >
      {children}
    </Button>
  );
}

export function ChatTypeSelection({
  renderNextStep,
}: {
  renderNextStep: () => void;
}) {
  const {t} = useTranslation(['create_room']);
  const {control, unregister, watch, formState} = useFormContext<CreateRoomFormProps>();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentUser] = useAtom(workerAtom);

  const selectedProject = watch('selectedProject');
  const {projectWorkers: workers, projectWorkersLoading: areWorkersLoading} =
    useDistinctProjectWorkers(selectedProject?.id, debouncedSearchTerm);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const input = evt.target.value;
    setSearchTerm(input);
    debouncedSearch(input);
  };

  const debouncedSearch = useDebounce(
    (value: string) => {
      setDebouncedSearchTerm(value);
    },
    {wait: 500},
  );

  const emptyResultsTitle = searchTerm.length > 0 ? 'members match that search' : 'members';

  const filteredWorkers = useMemo(
    () => workers.filter((worker) => worker.worker_id !== currentUser.id),
    [currentUser.id, workers],
  );

  const workerProfileInfo = useWorkerProfileInfo(filteredWorkers);

  const loading = workerProfileInfo.some(({isLoading}) => isLoading) || areWorkersLoading;

  const menuItemClass = cn({
    [s.modal__chatTypeSelection__listItem]: true,
    [s['modal__chatTypeSelection__listItem--submitting']]: formState.isSubmitting,
  });

  return (
    <Box direction="Column">
      <Box justifyContent="SpaceBetween">
        <Box direction="Row" gap="100" grow="Yes">
          <GroupButton
            onClick={renderNextStep}
            name={CustomIcons.Group}
            disabled={formState.isSubmitting || loading}
          >
            <Text size="B500">{t('cta.create_a_group')}</Text>
          </GroupButton>
        </Box>
      </Box>
      <HorizontalRule />
      <Text>{t('individual_chat.input.label')}</Text>
      <Input
        before={<Icon size="200" src={Icons.Search} />}
        className={s.modal__chatTypeSelection__input}
        onChange={handleSearch}
        placeholder={t('individual_chat.input.place_holder')}
        value={searchTerm}
        variant="Background"
      />
      {!loading ? (
        <Scroll size="0">
          <Box gap="100" direction="Column" className={s.modal__chatTypeSelection__listContainer}>
            <Controller
              control={control}
              name="selectedWorker"
              render={({field: {onChange, value, ...rest}}) => {
                return (
                  <>
                    {filteredWorkers.length > 0 ? (
                      filteredWorkers.map((worker, index) => {
                        const {data} = workerProfileInfo[index];
                        const {avatarUrl, displayName} = data || {};
                        const isSelected = value?.id === worker.id;
                        return (
                          <MenuItem
                            before={
                              loading ? (
                                <Spinner />
                              ) : (
                                <Avatar size="400">
                                  <UserAvatar
                                    alt={displayName}
                                    userId={worker.worker_full?.ext_chat_id}
                                    src={avatarUrl}
                                    renderFallback={() => (
                                      <Text size="H4">{nameInitials(displayName)}</Text>
                                    )}
                                  />
                                </Avatar>
                              )
                            }
                            className={menuItemClass}
                            key={worker.id}
                            onClick={() => {
                              unregister(['selectedWorkers', 'roomName']);
                              if (isSelected) {
                                onChange(null);
                              } else {
                                onChange(worker);
                              }
                            }}
                            radii="300"
                            type="button"
                            variant={value?.id === worker.id ? 'Success' : undefined}
                            {...rest}
                          >
                            <Text>{displayName}</Text>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <EmptyResults title={emptyResultsTitle} />
                    )}
                  </>
                );
              }}
            />
          </Box>
        </Scroll>
      ) : (
        <Skeleton
          borderRadius={4}
          className={s.modal__skeleton__worker_list}
          count={4}
          height={60}
          style={{backgroundColor: color.Background.Container}}
        />
      )}
      <HorizontalRule />
      <Button
        after={formState.isSubmitting ? <Spinner /> : null}
        before={<Icon src={Icons.Plus} />}
        disabled={formState.isSubmitting || !formState.isValid || loading}
        type="submit"
      >
        {formState.isSubmitting ? t('cta.creating_room') : t('cta.create_room')}
      </Button>
    </Box>
  );
}
