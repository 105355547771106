import EventEmitter from 'events';
import Olm from '@matrix-org/olm';
import * as sdk from 'matrix-js-sdk';
import {logger} from 'matrix-js-sdk/lib/logger';
import {firebaseService} from '~/services/Firebase/FirebaseService';

import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {getSecret} from './state/auth';

global.Olm = Olm;

if (import.meta.env.PROD) {
  logger.disableAll();
}

class InitMatrix extends EventEmitter {
  async init(baseUrl) {
    if (this.matrixClient || this.initializing) {
      logger.warn('Client is already initialized!');
      return;
    }
    this.initializing = true;

    try {
      logger.debug('Starting Matrix client initialization...');
      await this.startClient(baseUrl);
      this.setupSync();
      this.listenEvents();
      this.initializing = false;
      logger.debug('Matrix client initialization finished.');
      this.emit('init_loading_finished');
    } catch (error) {
      logger.error('Matrix client initialization error:', error);
      this.initializing = false;
      this.emit('init_loading_error', error);
    }
  }

  async startClient(baseUrl) {
    try {
      logger.debug('Starting IndexedDB store...');
      const indexedDBStore = new sdk.IndexedDBStore({
        indexedDB: global.indexedDB,
        localStorage: global.localStorage,
        dbName: 'web-sync-store',
      });
      await indexedDBStore.startup();
      logger.debug('IndexedDB store started.');

      const secret = getSecret();
      if (!secret || !secret.accessToken || !secret.userId || !secret.deviceId) {
        throw new Error('Missing or invalid secret data');
      }
      logger.debug('Creating Matrix client with baseUrl:', baseUrl);

      this.matrixClient = sdk.createClient({
        baseUrl,
        accessToken: secret.accessToken,
        userId: secret.userId,
        store: indexedDBStore,
        deviceId: secret.deviceId,
        timelineSupport: true,
        verificationMethods: ['m.sas.v1'],
      });

      logger.debug('Starting Matrix client...');
      await this.matrixClient.startClient({
        lazyLoadMembers: true,
      });
      logger.debug('Matrix client started.');

      this.matrixClient.setMaxListeners(50);
    } catch (error) {
      logger.error('Error starting Matrix client:', error);
      throw error;
    }
  }

  setupSync() {
    const sync = {
      NULL: () => {
        logger.debug('NULL state');
      },
      SYNCING: () => {
        logger.debug('SYNCING state');
      },
      PREPARED: (prevState) => {
        logger.debug('PREPARED state');
        logger.debug('Previous state:', prevState);
        global.initMatrix = this;
        if (prevState === null) {
          this.emit('init_loading_finished');
        }
      },
      RECONNECTING: () => {
        logger.debug('RECONNECTING state');
      },
      CATCHUP: () => {
        logger.debug('CATCHUP state');
      },
      ERROR: (_state, _prevState, err) => {
        logger.debug('ERROR state');
        logger.error('Sync error:', err);
      },
      STOPPED: () => {
        logger.debug('STOPPED state');
      },
    };

    this.matrixClient.on('sync', (state, prevState, nextState) => {
      if (sync[state]) {
        sync[state](prevState, nextState);
      } else {
        logger.warn(`Unknown sync state: ${state}`);
      }
    });
  }

  listenEvents() {
    this.matrixClient.on('Session.logged_out', async () => {
      logger.debug('Session logged out');
      try {
        this.matrixClient.stopClient();
        await this.matrixClient.clearStores();
        mixpanelService.cleanup();
        window.localStorage.clear();
        window.location.reload();
      } catch (error) {
        logger.error('Error during session logout:', error);
      }
    });
  }

  async logout() {
    logger.debug('Logging out...');
    this.matrixClient.stopClient();
    firebaseService.signOut();
    try {
      await this.matrixClient.logout();
      await this.matrixClient.clearStores();
      window.localStorage.clear();
      window.location.reload();
    } catch (error) {
      logger.error('Logout error:', error);
    }
  }

  clearCacheAndReload() {
    logger.debug('Clearing cache and reloading...');
    this.matrixClient.stopClient();
    this.matrixClient.store
      .deleteAllData()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        logger.error('Error clearing cache:', error);
      });
  }
}

const initMatrix = new InitMatrix();

export default initMatrix;
