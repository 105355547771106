import {useQuery} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {workerAtom} from '~/app/state/worker';
import {QUERY_CACHE_KEYS} from '../client/state/query-keys';
import {chatService} from '../services/ChatService/ChatService';

const getGroupForUser = async (workerId: string, roomId: string) => {
  const allChatsForUser = await chatService.getAllChatsForUser({
    workerId,
    filterParams: {ext_chat_id: roomId},
  });
  const response = allChatsForUser.find((group) => group.ext_chat_id === roomId);

  if (response) {
    return response;
  }
  return undefined;
};

export function useGroupInfoForRoom(roomId: string) {
  const [worker] = useAtom(workerAtom);

  return useQuery({
    queryKey: [...QUERY_CACHE_KEYS.workerChats(worker.id), roomId],
    queryFn: () => getGroupForUser(worker.id, roomId),
    enabled: !!roomId && !!worker.id,
  });
}
