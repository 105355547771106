import {CreateGroupChat, CreateIndividualChat, CreateRoomFormProps} from './types';

export function isCreateIndividualChat(
  props: CreateRoomFormProps | CreateIndividualChat,
): props is CreateIndividualChat {
  return !!props.selectedWorker && !!props.selectedProject;
}

export function isCreateGroupChat(
  props: CreateRoomFormProps | CreateGroupChat,
): props is CreateGroupChat {
  return (
    props.selectedWorkers !== undefined &&
    Array.isArray(props.selectedWorkers) &&
    !!props.roomName &&
    !!props.selectedProject
  );
}

// Utility function to narrow the type
export function narrowCreateRoomFormProps(
  props: CreateRoomFormProps,
): CreateIndividualChat | CreateGroupChat | null {
  if (isCreateIndividualChat(props)) {
    return {
      selectedProject: props.selectedProject,
      selectedWorker: props.selectedWorker,
    };
  } else if (isCreateGroupChat(props)) {
    return {
      roomName: props.roomName,
      selectedProject: props.selectedProject,
      selectedWorkers: props.selectedWorkers,
    };
  }
  return null;
}
