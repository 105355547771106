export enum TaskObjectType {
  task = 'task',
  action = 'action',
  activity = 'activity',
  summary = 'summary',
  milestone = 'milestone',
}

export enum TaskObjectSubType {
  start = 'start',
  end = 'end',
}
