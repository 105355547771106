import {IssueModel, IssueModelDTO, IssuesParams} from '~/types/issue';
import {TaskAssignees} from '~/types/member';
import {SortOrder} from '~/types/sort';
import {TaskType} from '~/types/task';
import {TaskDetailsType} from '~/types/taskDetails';
import {JourneyApi} from '~/util/api-helper';

export const SortField = {
  outlineSortKey: 'outline_sort_key',
} as const;

export enum TaskProjection {
  task = 'task',
  taskDetail = 'task_detail',
  taskMinimal = 'task_minimal',
  taskGantt = 'task_gantt',
}

export type TaskParams = {
  project_id: string;
  limit?: number;
  offset?: number;
  params: {
    object_type_list?: string[];
    q?: string;
  };
  sortField?: string;
  sortOrder?: SortOrder;
  projection?: TaskProjection;
  includeSummaryTasks?: boolean;
};

class TasksService {
  private static instance: TasksService | null = null;

  public static getInstance(): TasksService {
    if (!TasksService.instance) {
      TasksService.instance = new TasksService();
    }
    return TasksService.instance;
  }

  private getTasksPath(projectId: string): string {
    return `projects/${projectId}/tasks`;
  }

  private getIssuesPath(projectId: string): string {
    return `projects/${projectId}/issues`;
  }

  private getTasksWithFilters(
    url: string,
    {
      limit = 20,
      offset = 0,
      params,
      sortField,
      sortOrder,
      projection,
      includeSummaryTasks,
    }: TaskParams,
  ) {
    const preparedSort: Record<string, string> = {};
    if (sortField) preparedSort.sort_field = sortField;
    if (sortOrder) preparedSort.sort_order = sortOrder;

    const searchParams = new URLSearchParams({
      filter_params: JSON.stringify(params),
      offset: offset.toString(),
      limit: limit.toString(),
      ...(projection && {projection}),
      ...(includeSummaryTasks !== undefined && {
        include_summary_tasks: includeSummaryTasks.toString(),
      }),
      ...preparedSort,
    });

    return JourneyApi.get(url, {searchParams}).json<TaskDetailsType[]>();
  }

  getTasksByProject(params: TaskParams) {
    return this.getTasksWithFilters(this.getTasksPath(params.project_id), params);
  }

  async createIssue(issue: Partial<IssueModel>, isScopedCreate = false): Promise<TaskDetailsType> {
    const basePath = isScopedCreate ? 'scoped/' : '';

    if (!issue.project_id) {
      throw new Error('Project ID is required to create an issue');
    }

    const url = `${basePath}${this.getIssuesPath(issue.project_id)}`;
    const response = await JourneyApi.post(url, {
      json: issue,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as TaskDetailsType;
  }

  async addIssueAssignee(
    projectId: string,
    issueId: string,
    assignee: TaskAssignees,
  ): Promise<TaskType> {
    const url = `${this.getIssuesPath(projectId)}/${issueId}/assign`;
    const response = await JourneyApi.post(url, {
      json: assignee,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as TaskType;
  }

  private async getIssuesWithFilters<T>(
    projectId: string,
    {
      range_params: {offset, limit} = {},
      sort_params: {sort_order, sort_field} = {},
      filter_params = {},
    }: IssuesParams = {},
  ): Promise<T[]> {
    const params = new URLSearchParams();

    // Only add filter_params if it's not empty
    if (Object.keys(filter_params).length > 0) {
      params.append('filter_params', JSON.stringify(filter_params));
    }

    if (offset !== undefined) params.append('offset', offset.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sort_field) params.append('sort_field', sort_field);
    if (sort_order) params.append('sort_order', sort_order);

    const url = this.getIssuesPath(projectId);
    const fullUrl = `${url}?${params.toString()}`;

    const response = await JourneyApi.get(fullUrl, {searchParams: params});

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error: ${response.status} ${response.statusText}\n${errorText}`);
    }

    const data = await response.json();
    return data as T[];
  }

  async getTaskIssues(projectId: string, params: IssuesParams): Promise<IssueModelDTO[]> {
    return this.getIssuesWithFilters<IssueModelDTO>(projectId, params);
  }

  async getTask(taskId: string) {
    return await JourneyApi.get(`tasks/${taskId}`).then((res) => res);
  }

  getTasks(taskIds: string[]) {
    const tasksPromises = taskIds.map((taskId) => this.getTask(taskId));
    return Promise.all(tasksPromises);
  }
}

export const tasksService = TasksService.getInstance();
