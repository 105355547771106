import 'react-loading-skeleton/dist/skeleton.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Provider as JotaiProvider} from 'jotai';
import {Toaster} from 'sonner';
import {ClientConfigLoader} from '~/app/components/ClientConfigLoader';
import {ClientConfigProvider} from '~/app/hooks/useClientConfig';
import {ScreenSizeProvider, useScreenSize} from '~/app/hooks/useScreenSize';
import {ConfigConfigError, ConfigConfigLoading} from './ConfigConfig';
import {FeatureCheck} from './FeatureCheck';
import {RouterInitializer} from './RouterInitializer';
import {AuthProvider} from './auth/AuthProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const screenSize = useScreenSize();

  return (
    <ScreenSizeProvider value={screenSize}>
      <FeatureCheck>
        <ClientConfigLoader
          fallback={() => <ConfigConfigLoading />}
          error={(err, retry, ignore) => (
            <ConfigConfigError error={err} retry={retry} ignore={ignore} />
          )}
        >
          {(clientConfig) => (
            <ClientConfigProvider value={clientConfig}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <JotaiProvider>
                    <RouterInitializer clientConfig={clientConfig} screenSize={screenSize} />
                    <Toaster richColors position="top-right" />
                  </JotaiProvider>
                </AuthProvider>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="bottom-left"
                  position="right"
                />
              </QueryClientProvider>
            </ClientConfigProvider>
          )}
        </ClientConfigLoader>
      </FeatureCheck>
    </ScreenSizeProvider>
  );
}

export default App;
