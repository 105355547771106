import React, {forwardRef, Ref} from 'react';
import {InputVariants, inputRecipe, inputStyles} from './Input.css';

interface BaseInputProps {
  id?: string;
  label?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resizable?: boolean;
  minHeight?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

type InputProps = BaseInputProps & InputVariants;

const AtomInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      id,
      label = '',
      name = '',
      value = '',
      placeholder = '',
      required = false,
      type = 'text',
      onChange,
      resizable = false,
      minHeight = 100,
      onKeyDown,
      disabled = false,
      autoFocus = false,
      variant,
      size,
      outlined,
      radii,
      ...props
    },
    ref,
  ) => {
    const containerClassName = inputRecipe({variant, size, outlined, radii});

    return (
      <div className={containerClassName}>
        {label !== '' && <label htmlFor={id}>{label}</label>}
        {resizable ? (
          <textarea
            id={id}
            name={name}
            className={inputStyles.textarea}
            ref={ref as Ref<HTMLTextAreaElement>}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={disabled}
            style={{
              resize: resizable ? 'vertical' : 'none',
              minHeight: `${minHeight}px`,
            }}
            {...props}
          />
        ) : (
          <input
            id={id}
            name={name}
            className={inputStyles.input}
            type={type}
            ref={ref as Ref<HTMLInputElement>}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={disabled}
            {...props}
          />
        )}
      </div>
    );
  },
);

export default AtomInput;
