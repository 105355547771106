import type {LoadOptions} from '~/types/loadOptions';

export const QUERY_CACHE_KEYS = {
  projectWorkers: (projectId: string, search?: string) => ['useProjectWorkers', projectId, search],
  projectSubcontractors: (projectId: string) => ['useProjectSubcontractors', projectId],
  tasksByProjectId: (projectId: string, query = '') => ['useTasksByProjectId', projectId, query],
  workerChats: (workerId: string) => ['useWorkerChats', workerId],
  workerProjects: (workerId: string) => ['workerProjects', workerId],
  workerProfiles: (workerId: string) => ['workerProfiles', workerId],
  createChat: (projectId: string) => ['createChat', projectId],
  getProjectName: (projectId: string) => ['getProjectById', projectId],
  getIssues: (options: Partial<LoadOptions> & {limit?: number}) => [
    'issues',
    options.projectId,
    options.queryParams,
    options.dataRange,
    options.limit,
  ],
  getCompany: (companyId: string) => ['getCompany', companyId],
  getWorker: (workerId: string) => ['getWorker', workerId],
} as const;
