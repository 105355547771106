import {AvatarFallback, AvatarImage, color} from 'folds';
import {ReactEventHandler, ReactNode, useState} from 'react';
import colorMXID from '../../../util/colorMXID';
import * as css from './UserAvatar.css';

type UserAvatarProps = {
  userId: string;
  src?: string;
  alt?: string;
  renderFallback: () => ReactNode;
};
export function UserAvatar({userId, src, alt, renderFallback}: UserAvatarProps) {
  const [error, setError] = useState(false);

  const handleLoad: ReactEventHandler<HTMLImageElement> = (evt) => {
    evt.currentTarget.setAttribute('data-image-loaded', 'true');
  };

  if (!src || error) {
    return (
      <AvatarFallback
        style={{backgroundColor: colorMXID(userId), color: color.Surface.Container}}
        className={css.UserAvatar}
      >
        {renderFallback()}
      </AvatarFallback>
    );
  }

  return (
    <AvatarImage
      className={css.UserAvatar}
      src={src}
      alt={alt}
      onError={() => setError(true)}
      onLoad={handleLoad}
      draggable={false}
    />
  );
}
