import classNames from 'classnames';
import {ImgHTMLAttributes, forwardRef} from 'react';
import * as css from './media.css';

export const Image = forwardRef<HTMLImageElement, ImgHTMLAttributes<HTMLImageElement>>(
  ({className, alt, ...props}, ref) => (
    // biome-ignore lint/a11y/useAltText:
    <img className={classNames(css.Image, className)} alt={alt} {...props} ref={ref} />
  ),
);
