import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import {Box, Button, Icon, Icons, Input, Scroll, Spinner, Text, config} from 'folds';
import {TFunction} from 'i18next';
import {IContent, MatrixEvent, MsgType, Room} from 'matrix-js-sdk';
import {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import AtomInput from '~/app/atoms/input/Input';
import {Select} from '~/app/atoms/select/Select';
import {createSelectOptions} from '~/app/atoms/select/selectHelper';
import {ImageViewer} from '~/app/components/image-viewer';
import {Image} from '~/app/components/media';
import {ImageContent, MImage} from '~/app/components/message';
import {useFilePicker} from '~/app/hooks/useFilePicker';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {ScreenSize, useScreenSizeContext} from '~/app/hooks/useScreenSize';
import {useSetting} from '~/app/state/hooks/settings';
import {settingsAtom} from '~/app/state/settings';
import {UploadStatus} from '~/app/state/upload';
import {safeFile} from '~/app/utils/mimeTypes';
import {preprocessTranslations} from '~/app/utils/preprocessTranslations';
import {useSaveIssue} from '~/mutations/useCreateIssue';
import {useProjectSubcontractors} from '~/queries/useProjectSubcontractors';
import {useDistinctProjectWorkers} from '~/queries/useProjectWorkers';
import {useTasksByProject} from '~/queries/useTasksByProject';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {Attachment} from '~/types/matrix/room';
import {TaskDetailsType} from '~/types/taskDetails';
import {TaskStatus} from '~/types/taskStatus';
import {CompanyWorker} from '~/types/worker';
import {getImageDimension} from '~/util/common';
import * as css from './CreateIssue.css';
import {CreateIssueFormSchema, createIssueSchema} from './createIssueSchema';
import {
  formatActivityLabel,
  formatMessageContent,
  getCostImpactOptions,
  getTaskIssueImpactOptions,
  getTaskIssueTypeOptions,
  handleContent,
} from './helper';

export type TaskTypeSubset = Pick<TaskDetailsType, 'id' | 'name' | 'outline_code'>;

type CreateIssueFormProps = {
  currentUser?: CompanyWorker;
  eventId: string;
  groupId: string;
  projectId: string;
  room: Room;
  handleClose: () => void;
};

type SelectedFile = {
  status: UploadStatus;
  mxc: string;
  dimensions?: {
    height: number;
    width: number;
  };
};

export const CreateIssueForm = ({
  handleClose,
  room,
  eventId,
  currentUser,
  projectId,
  groupId,
}: CreateIssueFormProps) => {
  const {t} = useTranslation(['create_issue']);
  const mx = useMatrixClient();
  const [mediaAutoLoad] = useSetting(settingsAtom, 'mediaAutoLoad');
  const roomId = room.roomId;
  const screenSize = useScreenSizeContext();
  const isMobile = screenSize === ScreenSize.Mobile;
  const event: MatrixEvent | undefined = room.findEventById(eventId);
  const content = event?.getContent();
  const contentWithoutReplyContent = handleContent(content);
  const isImage = content?.msgtype === MsgType.Image;
  const attachments: IContent[] | Attachment[] = isImage ? [content] : (content?.attachments ?? []);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const {data: tasksList, isLoading: isActivityLoading} = useTasksByProject(projectId);
  const {projectWorkers, projectWorkersLoading} = useDistinctProjectWorkers(projectId);
  const {options: projectSubcontractors} = useProjectSubcontractors(projectId);
  const taskIssueTypeOptions = getTaskIssueTypeOptions(t);
  const issueImpactOptions = getTaskIssueImpactOptions(t);
  const issueCreationSchema = createIssueSchema(t);
  const costImpactOptions = getCostImpactOptions(t);
  const {register, handleSubmit, reset, control, formState, watch, setValue, getValues} =
    useForm<CreateIssueFormSchema>({
      resolver: zodResolver(issueCreationSchema),
      defaultValues: {
        activity: null,
        assignee: currentUser?.worker_full?.id ?? '',
        attachmentEventId: '',
        costImpact: '',
        description: formatMessageContent(contentWithoutReplyContent, mx) as string,
        endDate: '',
        issueType: '',
        liableCompany: '',
        newIssue: '',
        photos: [],
        resolvingCompany: '',
        startDate: new Date().toISOString().split('T')[0],
        status: TaskStatus.tba,
        timeImpact: '',
      },
    });

  const {mutate: createIssueMutation, isPending} = useSaveIssue({
    onSuccess: () => {
      reset();
      handleClose();
      setSelectedFiles([]);
    },
  });

  const startDate = watch('startDate');

  const onSubmit = async (data: CreateIssueFormSchema) => {
    try {
      let attachmentEventId = data.attachmentEventId;

      // If there are selected files, send the message with attachments
      if (selectedFiles.length > 0) {
        const validAttachments: Attachment[] = selectedFiles
          .filter(
            (file): file is typeof file & {mxc: string} =>
              file.status === UploadStatus.Success && !!file.mxc,
          )
          .map((file) => ({
            info: {
              h: file.dimensions?.height || 0,
              w: file.dimensions?.width || 0,
            },
            url: file.mxc,
            mxc: file.mxc,
          }));

        if (validAttachments.length > 0) {
          try {
            attachmentEventId = await sendMessageWithAttachments(validAttachments);
          } catch (error) {
            console.error('Error sending message with attachments:', error);
            Sentry.captureException(error, {
              extra: {validAttachments, name: 'CreateIssue:sendMessageWithAttachments'},
            });
            // You might want to show an error message to the user here
            return;
          }
        }
      }

      // Create the issue with the attachment event ID
      createIssueMutation({
        ...data,
        groupId,
        eventId,
        projectId,
        attachmentEventId, // Include the attachment event ID in the mutation
      });
    } catch (error) {
      Sentry.captureException(error, {extra: {data, name: 'CreateIssue:onSubmit'}});
    }
  };

  const handleFormSubmit = useCallback(
    handleSubmit((data) => {
      mixpanelService.trackWithAction(
        () => onSubmit(data),
        MixpanelEvents.Message3DotCreateIssueSubmit,
      );
    }),
    [handleSubmit, onSubmit],
  );

  const allowedStatuses = [TaskStatus.inProgress, TaskStatus.tba, TaskStatus.closed];
  /**
   * ! Do not remove !
   * t("create_issue:form.fields.status.select.closed")
   * t("create_issue:form.fields.status.select.in_progress")
   * t("create_issue:form.fields.status.select.tba")
   */
  const statusTranslations = preprocessTranslations(
    t as unknown as TFunction,
    'form.fields.status.select',
    allowedStatuses,
  );

  const formatStatusLabel = (status: TaskStatus) => {
    return statusTranslations[status] || status;
  };

  const selectActivityOptions = tasksList
    ? createSelectOptions<TaskTypeSubset, string>(tasksList, 'id', 'name', formatActivityLabel)
    : [];

  const statusOptions = createSelectOptions<TaskStatus, TaskStatus>(
    allowedStatuses,
    undefined,
    undefined,
    formatStatusLabel,
  );

  const workerOptions = createSelectOptions<CompanyWorker, string>(
    projectWorkers,
    'worker_id',
    'worker_full.full_name',
  );

  const sendMessageWithAttachments = async (attachments: Attachment[]): Promise<string> => {
    const content = {
      msgtype: 'm.text',
      body: '',
      attachments,
    };

    // Send the message with attachments
    const result = await mx.sendMessage(roomId, content);
    return result.event_id;
  };

  const handleFileSelect = useCallback(
    async (files: File[]) => {
      const newUploads = await Promise.all(
        files.map(async (file) => {
          try {
            const safeFileObj = safeFile(file);
            const uploadedFile = await mx.uploadContent(safeFileObj);
            let dimensions = {width: 0, height: 0};

            if (file.type.startsWith('image/')) {
              dimensions = await getImageDimension(file);
            }

            return {
              file: safeFileObj,
              originalFile: file,
              status: UploadStatus.Success,
              content_uri: uploadedFile.content_uri,
              mxc: uploadedFile.content_uri,
              progress: {loaded: file.size, total: file.size},
              encInfo: {},
              dimensions,
            };
          } catch (error) {
            return {
              file: safeFile(file),
              originalFile: file,
              status: UploadStatus.Error,
              error: 'Failed to upload file',
              progress: {loaded: 0, total: file.size},
              encInfo: {},
              mxc: '',
              content_uri: '',
            };
          }
        }),
      );

      setSelectedFiles((prev) => [...prev, ...newUploads]);

      const successfulUploads = newUploads.filter(
        (upload) => upload.status === UploadStatus.Success,
      );

      if (successfulUploads.length > 0) {
        const newPhotoUrls = successfulUploads.map((upload) => upload.mxc);
        setValue('photos', [...(getValues('photos') || []), ...newPhotoUrls]);
      }
    },
    [mx, setValue, getValues],
  );

  const removeSelectedFile = (fileToRemove: SelectedFile) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.mxc !== fileToRemove.mxc));
    setValue(
      'photos',
      getValues('photos')?.filter((url) => url !== fileToRemove.mxc),
    );
  };

  const pickFile = useFilePicker<true>(handleFileSelect, true);

  const handlePickFile = useCallback(() => {
    mixpanelService.trackWithAction(
      () => pickFile('image/jpeg,image/png,image/jpg'),
      MixpanelEvents.Message3DotCreateIssueAddPhotoClick,
    );
  }, [pickFile]);

  return (
    <Box className={css.CreateIssueFrom} direction="Column" gap="400" as="form">
      <Box direction="Column" gap="400">
        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="newIssue">
            {t('form.fields.issue.label')}
            <Text as="span" size="T200">
              ({t('form.common.required')})
            </Text>
          </Text>
          <Input
            {...register('newIssue')}
            placeholder={t('form.fields.issue.placeholder')}
            variant={formState.errors.newIssue ? 'Critical' : 'Background'}
          />
          {formState.errors.newIssue && (
            <Text role="alert" size="T200" className="join-alias__error">
              {formState.errors.newIssue.message}
            </Text>
          )}
        </Box>

        <Box direction={isMobile ? 'Column' : 'Row'} gap="100">
          <Box direction="Column" gap="100" grow="Yes">
            <Text size="L400" as="label" htmlFor="startDate">
              {t('form.fields.start_date.label')}
              <Text as="span" size="T200">
                ({t('form.common.required')})
              </Text>
            </Text>
            <Input {...register('startDate')} type="date" variant="Background" />
          </Box>

          <Box direction="Column" gap="100" grow="Yes">
            <Text size="L400" as="label" htmlFor="endDate">
              {t('form.fields.end_date.label')}{' '}
              <Text as="span" size="T200">
                ({t('form.common.optional')})
              </Text>
            </Text>
            <Input {...register('endDate')} type="date" min={startDate} variant="Background" />
          </Box>
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="description">
            {t('form.fields.decription.label')}
          </Text>
          <Controller
            name="description"
            control={control}
            render={({field}) => (
              <AtomInput
                {...field}
                variant={formState.errors.description ? 'Critical' : 'Background'}
                resizable
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />

          {formState.errors.description && (
            <Text role="alert" size="T200" className="join-alias__error">
              {formState.errors.description.message}
            </Text>
          )}
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="status">
            {t('form.fields.status.label')}
          </Text>
          <Controller
            name="status"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                placeHolder={t('form.fields.status.placeholder')}
                options={statusOptions}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
              />
            )}
          />
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="assignee">
            {t('form.fields.assignee.label')}
          </Text>
          <Controller
            name="assignee"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                placeHolder={t('form.fields.assignee.placholder')}
                options={workerOptions}
              />
            )}
          />
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="resolvingCompany">
            {t('form.fields.resolving_company.label')}
          </Text>
          <Controller
            name="resolvingCompany"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                isClearable
                isLoading={projectWorkersLoading}
                placeHolder={t('form.fields.resolving_company.placeholder')}
                options={projectSubcontractors}
              />
            )}
          />
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="liableCompany">
            {t('form.fields.liable_company.label')}
          </Text>
          <Controller
            name="liableCompany"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                isClearable
                isLoading={projectWorkersLoading}
                placeHolder={t('form.fields.liable_company.placeholder')}
                options={projectSubcontractors}
              />
            )}
          />
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="issueType">
            {t('form.fields.issue_type.label')}
            <Text as="span" size="T200">
              ({t('form.common.required')})
            </Text>
          </Text>
          <Controller
            name="issueType"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                variant={formState.errors.issueType ? 'Critical' : 'Background'}
                placeHolder={t('form.fields.issue_type.placeholder')}
                options={taskIssueTypeOptions}
              />
            )}
          />

          {formState.errors.issueType && (
            <Text role="alert" size="T200" className="join-alias__error">
              {formState.errors.issueType.message}
            </Text>
          )}
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="timeImpact">
            {t('form.fields.time_impact.label')}
            <Text as="span" size="T200">
              ({t('form.common.required')})
            </Text>
          </Text>
          <Controller
            name="timeImpact"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                variant={formState.errors.timeImpact ? 'Critical' : 'Background'}
                placeHolder={t('form.fields.time_impact.placeholder')}
                options={issueImpactOptions}
              />
            )}
          />

          {formState.errors.timeImpact && (
            <Text role="alert" size="T200" className="join-alias__error">
              {formState.errors.timeImpact.message}
            </Text>
          )}
        </Box>

        <Box direction="Column" gap="100">
          <Text size="L400" as="label" htmlFor="costImpact">
            {t('form.fields.cost_impact.label')}
          </Text>
          <Controller
            name="costImpact"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                placeHolder={t('form.fields.cost_impact.placeholder')}
                options={costImpactOptions}
              />
            )}
          />
        </Box>

        <Box direction="Column" gap="100" justifyContent="Center">
          <Box direction="Column" gap="100" justifyContent="Center">
            <Text size="L400" as="label" htmlFor="activity">
              {t('form.fields.activity.label')}
            </Text>
          </Box>
          <Controller
            name="activity"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                isLoading={isActivityLoading}
                value={field.value?.id || ''}
                onChange={(newValue: string | null) => {
                  const selectedTask = tasksList?.find((task) => task.id === newValue);
                  field.onChange(selectedTask);
                }}
                options={selectActivityOptions}
                placeHolder={t('form.fields.activity.placeholder')}
              />
            )}
          />
        </Box>
      </Box>

      <Box direction="Column" gap="100" justifyContent="End" style={{marginTop: config.size.X50}}>
        {attachments?.length > 0 ? (
          <Box direction="Column" gap="100" style={{marginTop: config.size.X50}}>
            <Text>{t('form.fields.gallery.label')}</Text>
            <Scroll size="300" direction="Horizontal" visibility="Hover">
              <Box
                direction="Row"
                gap="100"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  minWidth: `${attachments.length * 200}px`,
                  height: '100px',
                }}
              >
                {attachments.map((file) => {
                  return (
                    <Box key={file.url} className={css.CreateIssueImageWrapper}>
                      <MImage
                        content={{url: file.url, msgtype: MsgType.Image}}
                        renderImageContent={(contentProps) => (
                          <ImageContent
                            {...contentProps}
                            autoPlay={mediaAutoLoad}
                            renderImage={(p) => (
                              <Image {...p} className={css.CreateIssueImage} loading="lazy" />
                            )}
                            renderViewer={(p) => <ImageViewer {...p} />}
                          />
                        )}
                        outlined
                      />
                    </Box>
                  );
                })}
              </Box>
            </Scroll>
          </Box>
        ) : null}

        {selectedFiles.length > 0 && (
          <Box direction="Column" gap="100" style={{marginTop: config.size.X50}}>
            <Text>{t('form.fields.selected_files.label')}</Text>
            <Scroll size="300" direction="Horizontal" visibility="Hover">
              <Box
                direction="Row"
                gap="100"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  minWidth: `${selectedFiles.length * 110}px`,
                }}
              >
                {selectedFiles.map((file) => (
                  <Box key={file.mxc} className={css.CreateIssueImageWrapper}>
                    <Box>
                      <Button
                        variant="Critical"
                        type="button"
                        className={css.RemoveImageButton}
                        onClick={() => removeSelectedFile(file)}
                      >
                        <Icon size="50" src={Icons.Cross} />
                      </Button>
                    </Box>
                    <MImage
                      content={{url: file.mxc, msgtype: MsgType.Image}}
                      renderImageContent={(contentProps) => (
                        <ImageContent
                          {...contentProps}
                          autoPlay={mediaAutoLoad}
                          renderImage={(p) => (
                            <Image {...p} className={css.CreateIssueImage} loading="lazy" />
                          )}
                          renderViewer={(p) => <ImageViewer {...p} />}
                        />
                      )}
                      outlined
                    />
                  </Box>
                ))}
              </Box>
            </Scroll>
          </Box>
        )}

        <Button
          type="button"
          variant="Secondary"
          after={<Icon src={Icons.Photo} />}
          onClick={handlePickFile}
        >
          {t('form.cta.add_photos.idle')}
        </Button>
        <Button
          onClick={handleFormSubmit}
          type="submit"
          disabled={isPending}
          after={<Spinner size="100" style={{visibility: isPending ? 'visible' : 'hidden'}} />}
        >
          {isPending ? t('form.cta.submit.submitting') : t('form.cta.submit.idle')}
        </Button>
      </Box>
    </Box>
  );
};
