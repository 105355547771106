import {atom} from 'jotai';
import {LS_WORKER_ID_KEY} from '~/services/Firebase/FirebaseService';

type WorkerState = {
  id: string;
};

const getInitialWorkerState = (): WorkerState => {
  const storedWorkerId = localStorage.getItem(LS_WORKER_ID_KEY);
  return {
    id: storedWorkerId || '',
  };
};

const initialWorkerState: WorkerState = getInitialWorkerState();

export const workerAtom = atom(initialWorkerState, (get, set, newWorkerState: WorkerState) => {
  localStorage.setItem(LS_WORKER_ID_KEY, newWorkerState.id);
  set(workerAtom, newWorkerState);
});
