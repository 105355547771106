import {useAtomValue} from 'jotai';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  getDirectRoomPath,
  getHomeRoomPath,
  getSpacePath,
  getSpaceRoomPath,
} from '../pages/pathUtils';
import {mDirectAtom} from '../state/mDirectList';
import {roomToParentsAtom} from '../state/room/roomToParents';
import {getCanonicalAliasOrRoomId} from '../utils/matrix';
import {getOrphanParents} from '../utils/room';
import {useMatrixClient} from './useMatrixClient';

export const useRoomNavigate = () => {
  const navigate = useNavigate();
  const mx = useMatrixClient();
  const roomToParents = useAtomValue(roomToParentsAtom);
  const mDirects = useAtomValue(mDirectAtom);

  const navigateSpace = useCallback(
    (roomId: string) => {
      const roomIdOrAlias = getCanonicalAliasOrRoomId(mx, roomId);
      navigate(getSpacePath(roomIdOrAlias));
    },
    [mx, navigate],
  );

  const navigateRoom = useCallback(
    (roomId: string, eventId?: string) => {
      const roomIdOrAlias = getCanonicalAliasOrRoomId(mx, roomId);

      const orphanParents = getOrphanParents(roomToParents, roomId);
      if (orphanParents.length > 0) {
        const pSpaceIdOrAlias = getCanonicalAliasOrRoomId(mx, orphanParents[0]);
        navigate(getSpaceRoomPath(pSpaceIdOrAlias, roomIdOrAlias, eventId));
        return;
      }

      if (mDirects.has(roomId)) {
        navigate(getDirectRoomPath(roomIdOrAlias, eventId));
        return;
      }

      navigate(getHomeRoomPath(roomIdOrAlias, eventId));
    },
    [mx, navigate, roomToParents, mDirects],
  );

  return {
    navigateSpace,
    navigateRoom,
  };
};
