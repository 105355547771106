export enum GroupMemberRole {
  assignee = 'assignee',
  owner = 'owner',
  member = 'member',
  responsible = 'responsible',
}

export type GroupMember = {
  ext_chat_id?: string;
  group_id?: string;
  id?: string;
  created_by?: string;
  member_id?: string;
  member_name?: string;
  member_profile_pic_url?: string;
  member_role?: GroupMemberRole;
  member_status?: string;
  member_trade?: string;
  time_chat_last_sync?: string;
  time_created?: string;
  time_removed?: string;
};

export type TaskAssignees = Pick<
  GroupMember,
  | 'member_id'
  | 'member_name'
  | 'member_profile_pic_url'
  | 'member_role'
  | 'member_status'
  | 'member_trade'
>;

export type TaskGroup = {
  id: string;
  ext_chat_id: string;
  adhoc: boolean;
  direct: boolean;
  name: string;
  time_created: string;
  time_removed: string;
  time_updated: string;
  members: GroupMember[];
};
