import {CompanyModel} from '~/types/company';
import {JourneyApi} from '~/util/api-helper';

class CompanyService {
  private static instance: CompanyService | null = null;

  private constructor() {}

  public static getInstance(): CompanyService {
    if (!CompanyService.instance) {
      CompanyService.instance = new CompanyService();
    }
    return CompanyService.instance;
  }

  private getCompanyBasePath(companyId: string): string {
    return `companies/${companyId}`;
  }

  async getCompany(companyId: string): Promise<CompanyModel> {
    const url = this.getCompanyBasePath(companyId);
    const response = await JourneyApi.get(url);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data as CompanyModel;
  }
}

export const companyService = CompanyService.getInstance();
