import {LoginError} from '../pages/auth/login/loginUtil';

export class CustomAuthError extends Error {
  errcode: LoginError;

  constructor({errcode, message}: {errcode: LoginError; message?: string}) {
    super(message || 'Login error occurred');
    this.name = 'CustomLoginError';
    this.errcode = errcode;

    // This line is necessary for proper prototype chain inheritance in TypeScript
    Object.setPrototypeOf(this, CustomAuthError.prototype);
  }
}
