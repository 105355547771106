import {MenuItem, Text, color} from 'folds';
import {useAtom} from 'jotai';
import {
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {Editor} from 'slate';
import {v4 as uuid} from 'uuid';
import {useKeyDown} from '~/app/hooks/useKeyDown';
import {useOutlineSortKeyMap} from '~/app/hooks/useOutlineSortkey';
import {hasMentionAtom} from '~/app/state/mention';
import {onTabPress} from '~/app/utils/keyboard';
import {getWBSText} from '~/app/utils/osk';
import {useTasksByProject} from '~/queries/useTasksByProject';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {TaskType} from '~/types/task';
import {createTaskElement, moveCursor, replaceWithElement} from '../utils';
import {AutocompleteMenu} from './AutocompleteMenu';
import {AutocompleteQuery} from './autocompleteQuery';

type RoomMentionAutocompleteProps = {
  roomId: string;
  editor: Editor;
  query: AutocompleteQuery<string>;
  requestClose: () => void;
};

export function TaskMentionAutocomplete({
  roomId,
  editor,
  query,
  requestClose,
}: RoomMentionAutocompleteProps) {
  const {t} = useTranslation('task_mentions');
  const [searchTerm, setSearchTerm] = useState('');
  const [, setHasMention] = useAtom(hasMentionAtom);
  const {data: chatData} = useProjectAndGroupIdFromWorkerChats(roomId ?? '');
  const {
    data: tasksList,
    isLoading: isTasksLoading,
    error: tasksError,
  } = useTasksByProject(chatData?.projectId ?? '', query.text);
  const {oskMap} = useOutlineSortKeyMap(tasksList);

  const filteredTasks = useMemo(() => {
    if (!tasksList) return [];
    if (!searchTerm) return tasksList;
    return tasksList.filter((task) => task.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [tasksList, searchTerm]);

  useEffect(() => {
    setSearchTerm(query.text);
  }, [query.text]);

  const handleAutocomplete = useCallback(
    (task: TaskType) => {
      const fullPath = getWBSText(task?.outline_sort_key, oskMap);
      const taskElement = createTaskElement({
        id: task.id,
        name: task.name,
        status: task.status,
        wbsPath: fullPath,
        highlight: false,
      });
      setHasMention(true);
      replaceWithElement(editor, query.range, taskElement);
      moveCursor(editor, true);
      mixpanelService.track(MixpanelEvents.MessageBoxActivitySelectClick);
      requestClose();
    },
    [editor, query.range, oskMap, setHasMention, requestClose],
  );

  useKeyDown(window, (evt: KeyboardEvent) => {
    onTabPress(evt, () => {
      if (filteredTasks.length > 0) {
        handleAutocomplete(filteredTasks[0]);
      }
    });
  });

  const renderContent = () => {
    if (tasksError) {
      return (
        <MenuItem as="button" radii="300" onClick={requestClose}>
          <Text style={{flexGrow: 2}} size="B400">
            {t('auto_complete.failed_to_load_issues')}
          </Text>
        </MenuItem>
      );
    }

    if (isTasksLoading) {
      return Array(9)
        .fill(undefined)
        .map(() => (
          <MenuItem
            key={uuid()}
            as="button"
            radii="300"
            style={{position: 'relative', marginBottom: 4}}
          >
            <Skeleton
              borderRadius={4}
              count={1}
              height={40}
              style={{
                backgroundColor: color.Background.Container,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
            />
          </MenuItem>
        ));
    }

    if (filteredTasks?.length > 0) {
      return filteredTasks.map((task) => (
        <MenuItem
          key={task.id}
          as="button"
          radii="300"
          onKeyDown={(evt: ReactKeyboardEvent<HTMLButtonElement>) =>
            onTabPress(evt, () => handleAutocomplete(task))
          }
          onClick={() => handleAutocomplete(task)}
        >
          <Text style={{flexGrow: 2}} size="B400" truncate>
            {task.name}
          </Text>
        </MenuItem>
      ));
    }

    return (
      <MenuItem as="button" radii="300" onClick={requestClose}>
        <Text style={{flexGrow: 2}} size="B400">
          {t('auto_complete.no_results')}
        </Text>
      </MenuItem>
    );
  };

  return (
    <AutocompleteMenu
      headerContent={<Text size="L400">{t('auto_complete.header.title')}</Text>}
      requestClose={requestClose}
    >
      {renderContent()}
    </AutocompleteMenu>
  );
}
