import {type UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import {useCallback, useEffect, useState} from 'react';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {SortField, TaskProjection, tasksService} from '~/services/TaskService/TaskService';
import {SortOrder} from '~/types/sort';
import type {TaskDetailsType} from '~/types/taskDetails';
import {TaskObjectType} from '~/types/taskObject';
import type {TaskFilterQuery} from '~/types/taskQueryfilter';
import type {TaskStatusQuery} from '~/types/taskStatus';

export const useTasksByProject = (
  projectId: string,
  query = '',
  options?: Omit<UseQueryOptions<TaskDetailsType[], Error>, 'queryKey' | 'queryFn'>,
) => {
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const queryClient = useQueryClient();

  const debouncedSetQuery = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
    }, 250),
    [],
  );

  useEffect(() => {
    debouncedSetQuery(query);
    return () => {
      debouncedSetQuery.cancel();
    };
  }, [query, debouncedSetQuery]);

  const filterParams: Partial<TaskFilterQuery & TaskStatusQuery> = {
    object_type_list: [TaskObjectType.activity, TaskObjectType.task, TaskObjectType.summary],
    project_id: projectId,
  };

  if (debouncedQuery) {
    filterParams.q = debouncedQuery;
  }

  const offset = 0;
  const take = debouncedQuery ? 50 : 500;

  return useQuery<TaskDetailsType[], Error>({
    queryKey: QUERY_CACHE_KEYS.tasksByProjectId(projectId, debouncedQuery),
    queryFn: async () => {
      const newTasks = await tasksService.getTasksByProject({
        project_id: projectId,
        projection: TaskProjection.task,
        offset: offset,
        limit: take,
        sortField: SortField.outlineSortKey,
        sortOrder: SortOrder.ASC,
        params: filterParams,
        includeSummaryTasks: true,
      });

      // Update the full task list cache
      const fullCacheKey = QUERY_CACHE_KEYS.tasksByProjectId(projectId, '');
      const existingTasks = queryClient.getQueryData<TaskDetailsType[]>(fullCacheKey) || [];

      const updatedTasks = [...existingTasks];
      const taskMap = new Map(updatedTasks.map((task) => [task.id, task]));

      newTasks.forEach((newTask) => {
        taskMap.set(newTask.id, newTask);
      });

      const mergedTasks = Array.from(taskMap.values());

      // Update the full cache
      queryClient.setQueryData(fullCacheKey, mergedTasks);

      // If it's a search query, also update the specific search query cache
      if (debouncedQuery) {
        queryClient.setQueryData(
          QUERY_CACHE_KEYS.tasksByProjectId(projectId, debouncedQuery),
          newTasks,
        );
      }

      // Return the tasks relevant to the current query
      return debouncedQuery ? newTasks : mergedTasks;
    },
    enabled: !!projectId,
    staleTime: 0,
    networkMode: 'always',
    ...options,
  });
};
