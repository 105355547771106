import {Box, Chip, Icon, Icons, Input, Spinner, Text, config} from 'folds';
import {FormEventHandler, RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';

type SearchProps = {
  active?: boolean;
  loading?: boolean;
  searchInputRef: RefObject<HTMLInputElement>;
  onSearch: (term: string) => void;
  onReset: () => void;
};
export function SearchInput({active, loading, searchInputRef, onSearch, onReset}: SearchProps) {
  const {t} = useTranslation(['search']);
  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    const {searchInput} = evt.target as HTMLFormElement & {
      searchInput: HTMLInputElement;
    };

    const searchTerm = searchInput.value.trim() || undefined;
    if (searchTerm) {
      onSearch(searchTerm);
      mixpanelService.track(MixpanelEvents.SearchKeywordsSubmit);
    }
  };

  return (
    <Box as="form" direction="Column" gap="100" onSubmit={handleSearchSubmit}>
      <span data-spacing-node />
      <Text size="L400">{t('search:body.search_input.label')}</Text>
      <Input
        ref={searchInputRef}
        style={{paddingRight: config.space.S300}}
        name="searchInput"
        size="500"
        variant="Background"
        placeholder={t('body.search_input.placeholder')}
        autoComplete="off"
        before={
          active && loading ? (
            <Spinner variant="Secondary" size="200" />
          ) : (
            <Icon size="200" src={Icons.Search} />
          )
        }
        after={
          active ? (
            <Chip
              key="resetButton"
              type="reset"
              variant="Secondary"
              size="400"
              radii="Pill"
              outlined
              after={<Icon size="50" src={Icons.Cross} />}
              onClick={onReset}
            >
              <Text size="B300">{t('body.search_input.cta.clear')}</Text>
            </Chip>
          ) : (
            <Chip type="submit" variant="Primary" size="400" radii="Pill" outlined>
              <Text size="B300">{t('body.search_input.cta.submit')}</Text>
            </Chip>
          )
        }
      />
    </Box>
  );
}
