import {useVirtualizer} from '@tanstack/react-virtual';
import FocusTrap from 'focus-trap-react';
import {
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Icons,
  Line,
  Menu,
  MenuItem,
  PopOut,
  RadioButton,
  RectCords,
  Spinner,
  Text,
  config,
  toRem,
} from 'folds';
import {useAtomValue} from 'jotai';
import {ClientEvent, RoomEvent, SyncState} from 'matrix-js-sdk';
import {
  MouseEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {PageNav, PageNavContent, PageNavHeader} from '~/app/components/page';
import {VirtualTile} from '~/app/components/virtualizer';
import {RoomNavItem} from '~/app/features/room-nav';
import {useHomeSearchSelected} from '~/app/hooks/router/useHomeSelected';
import {useSelectedRoom} from '~/app/hooks/router/useSelectedRoom';
import {useCreateRoom} from '~/app/hooks/useCreateRoom';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useNavToActivePathMapper} from '~/app/hooks/useNavToActivePathMapper';
import {useWorker} from '~/app/hooks/useWorker';
import {getHomeRoomPath, getHomeSearchPath} from '~/app/pages/pathUtils';
import {useRoomsUnread} from '~/app/state/hooks/unread';
import {useBindAtoms} from '~/app/state/hooks/useBindAtoms';
import {muteChangesAtom} from '~/app/state/room-list/mutedRoomList';
import {roomToUnreadAtom} from '~/app/state/room/roomToUnread';
import {getCanonicalAliasOrRoomId} from '~/app/utils/matrix';
import {factoryRoomIdByActivity} from '~/app/utils/sort';
import {openCreateRoom} from '~/client/action/navigation';
import {markAsRead} from '~/client/action/notifications';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {
  NavButton,
  NavCategory,
  NavEmptyCenter,
  NavEmptyLayout,
  NavItem,
  NavItemContent,
  NavLink,
} from '../../../components/nav';
import {useHomeRooms} from './useHomeRooms';

type HomeMenuProps = {
  requestClose: () => void;
};

const LanguagePicker = () => {
  const {t, i18n} = useTranslation();
  const languages = ['en', 'ja'];
  const languageMap: Record<string, string> = {
    en: t('common:english'),
    ja: t('common:japanese'),
  };

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <>
      <Line />
      <Box justifyContent="Center" style={{flexGrow: 1}}>
        <Text size="T300" truncate>
          {t('common:language')}
        </Text>
      </Box>
      <Line />
      {languages.map((lang) => (
        <MenuItem
          after={<RadioButton checked={i18n.language === lang} size="100" />}
          key={lang}
          onClick={() => changeLanguage(lang)}
          size="300"
        >
          <Text style={{flexGrow: 1}} as="span" size="T300" truncate>
            {languageMap[lang]}
          </Text>
        </MenuItem>
      ))}
    </>
  );
};

const HomeMenu = forwardRef<HTMLDivElement, HomeMenuProps>(({requestClose}, ref) => {
  const {t} = useTranslation('common');
  const orphanRooms = useHomeRooms();
  const unread = useRoomsUnread(orphanRooms, roomToUnreadAtom);

  const handleMarkAsRead = () => {
    if (!unread) return;
    orphanRooms.forEach((rId) => markAsRead(rId));
    requestClose();
  };

  return (
    <Menu ref={ref} style={{maxWidth: toRem(160), width: '100vw'}}>
      <Box direction="Column" gap="100" style={{padding: config.space.S100}}>
        <MenuItem
          onClick={handleMarkAsRead}
          size="300"
          after={<Icon size="100" src={Icons.CheckTwice} />}
          radii="300"
          aria-disabled={!unread}
        >
          <Text style={{flexGrow: 1}} as="span" size="T300" truncate>
            {t('navbar.navitem.mark_as_read')}
          </Text>
        </MenuItem>
        <LanguagePicker />
      </Box>
    </Menu>
  );
});

function HomeHeader() {
  const [menuAnchor, setMenuAnchor] = useState<RectCords>();

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
    const cords = evt.currentTarget.getBoundingClientRect();
    setMenuAnchor((currentState) => {
      if (currentState) return undefined;
      return cords;
    });
  };

  return (
    <>
      <PageNavHeader>
        <Box alignItems="Center" grow="Yes" gap="300">
          <Box grow="Yes">
            <Text size="H4" truncate>
              Home
            </Text>
          </Box>
          <Box>
            <IconButton aria-pressed={!!menuAnchor} variant="Background" onClick={handleOpenMenu}>
              <Icon src={Icons.VerticalDots} size="200" />
            </IconButton>
          </Box>
        </Box>
      </PageNavHeader>
      <PopOut
        anchor={menuAnchor}
        position="Bottom"
        align="End"
        offset={6}
        content={
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              returnFocusOnDeactivate: false,
              onDeactivate: () => setMenuAnchor(undefined),
              clickOutsideDeactivates: true,
              isKeyForward: (evt: KeyboardEvent) => evt.key === 'ArrowDown',
              isKeyBackward: (evt: KeyboardEvent) => evt.key === 'ArrowUp',
            }}
          >
            <HomeMenu requestClose={() => setMenuAnchor(undefined)} />
          </FocusTrap>
        }
      />
    </>
  );
}

function HomeEmpty() {
  const {t} = useTranslation('create_room');
  const {handleCreateRoom} = useCreateRoom({openCreateRoom});
  const mx = useMatrixClient();

  const [syncComplete, setSyncComplete] = useState(false);

  useEffect(() => {
    const handleSyncStateChange = (state: string) => {
      if (state === SyncState.Prepared || state === SyncState.Syncing) {
        setSyncComplete(true);
      }
    };

    if (mx.isInitialSyncComplete()) {
      setSyncComplete(true);
    }

    mx.on(ClientEvent.Sync, handleSyncStateChange);

    return () => {
      mx.removeListener(ClientEvent.Sync, handleSyncStateChange);
    };
  }, [mx]);

  if (!syncComplete) {
    return (
      <NavEmptyCenter>
        <NavEmptyLayout content={<Spinner size="600" />} />
      </NavEmptyCenter>
    );
  }

  return (
    <NavEmptyCenter>
      <NavEmptyLayout
        icon={<Icon size="600" src={Icons.Hash} />}
        title={
          <Text size="H5" align="Center">
            {t('empty_state.title')}
          </Text>
        }
        content={
          <Text size="T300" align="Center">
            {t('empty_state.content')}
          </Text>
        }
        options={
          <Button onClick={handleCreateRoom} variant="Secondary" size="300">
            <Text size="B300" truncate>
              {t('cta.create_room')}
            </Text>
          </Button>
        }
      />
    </NavEmptyCenter>
  );
}

export function Home() {
  const {t} = useTranslation(['create_room', 'common']);
  const mx = useMatrixClient();
  useNavToActivePathMapper('home');
  useWorker();
  const scrollRef = useRef<HTMLDivElement>(null);
  const rooms = useHomeRooms();
  const muteChanges = useAtomValue(muteChangesAtom);
  const mutedRooms = muteChanges.added;
  const roomToUnread = useAtomValue(roomToUnreadAtom);

  const selectedRoomId = useSelectedRoom();
  const searchSelected = useHomeSearchSelected();
  const noRoomToDisplay = rooms.length === 0;
  const [lastEventTimestamp, setLastEventTimestamp] = useState(Date.now());

  useBindAtoms(mx);

  useEffect(
    function listenForTimelineEvents() {
      const handleNewEvent = () => {
        // Update the timestamp when a new event occurs
        setLastEventTimestamp(Date.now());
      };

      mx.on(RoomEvent.Timeline, handleNewEvent);

      return () => {
        mx.removeListener(RoomEvent.Timeline, handleNewEvent);
      };
    },
    [mx],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: lastEventTimestamp needed to listen for Timeline events
  const sortedRooms = useMemo(() => {
    const activitySortFunc = factoryRoomIdByActivity(mx);
    return rooms.sort((roomIdA, roomIdB) => {
      // sort by unread first
      const unreadA = roomToUnread.has(roomIdA);
      const unreadB = roomToUnread.has(roomIdB);
      if (unreadA && !unreadB) {
        return -1;
      }
      if (!unreadA && unreadB) {
        return 1;
      }
      // sort by activity second
      return activitySortFunc(roomIdA, roomIdB);
    });
  }, [mx, rooms, roomToUnread, lastEventTimestamp]);

  const virtualizer = useVirtualizer({
    count: sortedRooms.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 93,
    overscan: 5,
  });

  const {handleCreateRoom} = useCreateRoom({openCreateRoom});

  const handleNewChatSearch = useCallback(() => {
    mixpanelService.track(MixpanelEvents.ChatListSearchClick);
    return getHomeSearchPath();
  }, []);

  return (
    <PageNav>
      <HomeHeader />
      <NavCategory>
        <NavItem variant="Background" radii="400">
          <NavButton onClick={handleCreateRoom}>
            <NavItemContent>
              <Box as="span" grow="Yes" alignItems="Center" gap="200">
                <Avatar size="200" radii="400">
                  <Icon src={Icons.Plus} size="100" />
                </Avatar>
                <Box as="span" grow="Yes">
                  <Text as="span" size="Inherit" truncate>
                    {t('common:navbar.create_room')}
                  </Text>
                </Box>
              </Box>
            </NavItemContent>
          </NavButton>
        </NavItem>
        {!noRoomToDisplay ? (
          <NavItem variant="Background" radii="400" aria-selected={searchSelected}>
            <NavLink to={handleNewChatSearch()}>
              <NavItemContent>
                <Box as="span" grow="Yes" alignItems="Center" gap="200">
                  <Avatar size="200" radii="400">
                    <Icon src={Icons.Search} size="100" filled={searchSelected} />
                  </Avatar>
                  <Box as="span" grow="Yes">
                    <Text as="span" size="Inherit" truncate>
                      {t('common:navbar.search')}
                    </Text>
                  </Box>
                </Box>
              </NavItemContent>
            </NavLink>
          </NavItem>
        ) : null}
      </NavCategory>
      {noRoomToDisplay ? (
        <HomeEmpty />
      ) : (
        <PageNavContent scrollRef={scrollRef} dataListName="virtual-list-container">
          <Box direction="Column" gap="300">
            <NavCategory>
              <div
                style={{
                  position: 'relative',
                  height: virtualizer.getTotalSize(),
                }}
              >
                {virtualizer.getVirtualItems().map((vItem) => {
                  const roomId = sortedRooms[vItem.index];
                  const room = mx.getRoom(roomId);
                  if (!room) return null;
                  const selected = selectedRoomId === roomId;

                  return (
                    <VirtualTile
                      virtualItem={vItem}
                      key={vItem.index}
                      ref={virtualizer.measureElement}
                    >
                      <RoomNavItem
                        room={room}
                        selected={selected}
                        linkPath={getHomeRoomPath(getCanonicalAliasOrRoomId(mx, roomId))}
                        muted={mutedRooms.includes(roomId)}
                      />
                    </VirtualTile>
                  );
                })}
              </div>
            </NavCategory>
          </Box>
        </PageNavContent>
      )}
    </PageNav>
  );
}
