import {EncryptedAttachmentInfo} from 'browser-encrypt-attachment';
import {atom} from 'jotai';
import {atomFamily} from 'jotai/utils';
import {Descendant} from 'slate';
import {Attachment} from '~/types/matrix/room';
import {TUploadContent} from '../../utils/matrix';
import {TListAtom, createListAtom} from '../list';
import {createUploadAtomFamily} from '../upload';

export const roomUploadAtomFamily = createUploadAtomFamily();
export const editRoomUploadAtomFamily = createUploadAtomFamily();

export type TUploadItem = {
  file: TUploadContent;
  originalFile: TUploadContent;
  encInfo: EncryptedAttachmentInfo | undefined;
};

export const roomIdToUploadItemsAtomFamily = atomFamily<string, TListAtom<TUploadItem>>(
  createListAtom,
);

export const roomEditIdToUploadItemsAtomFamily = atomFamily<string, TListAtom<TUploadItem>>(
  createListAtom,
);

export type RoomIdToMsgAction =
  | {
      type: 'PUT';
      roomId: string;
      msg: Descendant[];
    }
  | {
      type: 'DELETE';
      roomId: string;
    };

const createMsgDraftAtom = () => atom<Descendant[]>([]);
export type TMsgDraftAtom = ReturnType<typeof createMsgDraftAtom>;
export const roomIdToMsgDraftAtomFamily = atomFamily<string, TMsgDraftAtom>(() =>
  createMsgDraftAtom(),
);

export type IReplyDraft = {
  userId: string;
  eventId: string;
  body: string;
  formattedBody?: string;
  attachments?: Array<Attachment>;
};
const createReplyDraftAtom = () => atom<IReplyDraft | undefined>(undefined);
export type TReplyDraftAtom = ReturnType<typeof createReplyDraftAtom>;
export const roomIdToReplyDraftAtomFamily = atomFamily<string, TReplyDraftAtom>(() =>
  createReplyDraftAtom(),
);
