import React, {useEffect} from 'react';
import './RawModal.scss';

import Modal from 'react-modal';

import navigation from '../../../client/state/navigation';

Modal.setAppElement('#root');

type ModalSize = 'large' | 'medium' | 'small';

interface RawModalProps {
  className?: string | null;
  overlayClassName?: string | null;
  isOpen: boolean;
  size?: ModalSize;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onRequestClose?: () => void;
  closeFromOutside?: boolean;
  children: React.ReactNode;
}

function RawModal({
  className = null,
  overlayClassName = null,
  isOpen,
  size = 'small',
  onAfterOpen = undefined,
  onAfterClose = undefined,
  onRequestClose = undefined,
  closeFromOutside = true,
  children,
}: RawModalProps) {
  let modalClass = className !== null ? `${className} ` : '';
  switch (size) {
    case 'large':
      modalClass += 'raw-modal__large ';
      break;
    case 'medium':
      modalClass += 'raw-modal__medium ';
      break;
    case 'small':
    default:
      modalClass += 'raw-modal__small ';
  }

  useEffect(() => {
    navigation.setIsRawModalVisible(isOpen);
  }, [isOpen]);

  const modalOverlayClass = overlayClassName !== null ? `${overlayClassName} ` : '';
  return (
    <Modal
      className={`${modalClass}raw-modal`}
      overlayClassName={`${modalOverlayClass}raw-modal__overlay`}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={closeFromOutside}
      shouldCloseOnOverlayClick={closeFromOutside}
      shouldReturnFocusAfterClose={false}
    >
      {children}
    </Modal>
  );
}

export default RawModal;
