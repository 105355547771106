import {QueryKey, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {IssueModelDTO} from '~/types/issue';

export function useIssueById({
  projectId,
}: {
  projectId: string | undefined;
}) {
  const queryClient = useQueryClient();

  const getIssueById = useCallback(
    (issueId: string): IssueModelDTO | null => {
      if (projectId === undefined) {
        return null;
      }

      const findIssueInQuery = (queryKey: QueryKey) => {
        const issues = queryClient.getQueryData<IssueModelDTO[]>(queryKey);
        return issues?.find((issue) => issue.id === issueId) || null;
      };

      const queryKeys = queryClient.getQueryCache().findAll({
        predicate: (query) => {
          const key = query.queryKey;
          return Array.isArray(key) && key[0] === 'issues' && key[1] === projectId;
        },
      });

      for (const query of queryKeys) {
        const issue = findIssueInQuery(query.queryKey);
        if (issue) {
          return issue;
        }
      }

      return null;
    },
    [queryClient, projectId],
  );

  return getIssueById;
}
