export type NestedKeyOf<T> = T extends Primitive
  ? never
  : T extends Array<infer U>
    ? NestedKeyOf<U>
    : T extends object
      ? {[K in keyof T & string]: K | `${K}.${NestedKeyOf<T[K]>}`}[keyof T & string]
      : never;

export function getNestedValue<T, K extends NestedKeyOf<T>>(obj: T, path: K): unknown {
  return path.split('.').reduce((acc, part) => {
    if (acc && typeof acc === 'object' && part in acc) {
      return (acc as Record<string, unknown>)[part];
    }
    return undefined;
  }, obj as unknown);
}
