import {ReactNode, useMemo} from 'react';
import {useMatrixClient} from '../../hooks/useMatrixClient';
import {makeClosedLobbyCategoriesAtom} from '../../state/closedLobbyCategories';
import {makeClosedNavCategoriesAtom} from '../../state/closedNavCategories';
import {ClosedLobbyCategoriesProvider} from '../../state/hooks/closedLobbyCategories';
import {ClosedNavCategoriesProvider} from '../../state/hooks/closedNavCategories';
import {NavToActivePathProvider} from '../../state/hooks/navToActivePath';
import {OpenedSidebarFolderProvider} from '../../state/hooks/openedSidebarFolder';
import {makeNavToActivePathAtom} from '../../state/navToActivePath';
import {makeOpenedSidebarFolderAtom} from '../../state/openedSidebarFolder';

type ClientInitStorageAtomProps = {
  children: ReactNode;
};
export function ClientInitStorageAtom({children}: ClientInitStorageAtomProps) {
  const mx = useMatrixClient();
  const userId = mx.getUserId()!;

  const closedNavCategoriesAtom = useMemo(() => makeClosedNavCategoriesAtom(userId), [userId]);

  const closedLobbyCategoriesAtom = useMemo(() => makeClosedLobbyCategoriesAtom(userId), [userId]);

  const navToActivePathAtom = useMemo(() => makeNavToActivePathAtom(userId), [userId]);

  const openedSidebarFolderAtom = useMemo(() => makeOpenedSidebarFolderAtom(userId), [userId]);

  return (
    <ClosedNavCategoriesProvider value={closedNavCategoriesAtom}>
      <ClosedLobbyCategoriesProvider value={closedLobbyCategoriesAtom}>
        <NavToActivePathProvider value={navToActivePathAtom}>
          <OpenedSidebarFolderProvider value={openedSidebarFolderAtom}>
            {children}
          </OpenedSidebarFolderProvider>
        </NavToActivePathProvider>
      </ClosedLobbyCategoriesProvider>
    </ClosedNavCategoriesProvider>
  );
}
