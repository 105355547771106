import {Member} from '~/services/ChatService/ChatService';
import {CompanyWorker} from '~/types/worker';

function workerToMember(worker: CompanyWorker): Member {
  return {
    member_id: worker.worker_id,
    member_role: 'member',
  };
}

export function findMembersToRemove(
  currentMembers: Member[],
  selectedWorkers: CompanyWorker[],
  currentUserId: string,
): Member[] {
  const selectedMemberIds = new Set(selectedWorkers.map((worker) => worker.worker_id));
  return currentMembers
    .filter(
      (member) => !selectedMemberIds.has(member.member_id) && member.member_id !== currentUserId,
    )
    .map((member) => ({
      member_id: member.member_id,
      member_role: member.member_role,
    }));
}

export function findMembersToAdd(
  currentMembers: Member[],
  selectedWorkers: CompanyWorker[],
): Member[] {
  const currentMemberIds = new Set(currentMembers.map((member) => member.member_id));
  return selectedWorkers
    .filter((worker) => !currentMemberIds.has(worker.worker_id))
    .map(workerToMember);
}
