import {useQuery} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {HTTPError} from 'ky';
import {QUERY_CACHE_KEYS} from '../../client/state/query-keys';
import {chatService} from '../../services/ChatService/ChatService';
import {projectService} from '../../services/ProjectService/ProjectService';
import {HttpStatusCode} from '../../types/http-status';
import {workerAtom} from '../state/worker';

const getChatForRoom = async (workerId: string, roomId: string) => {
  const chats = await chatService.getAllChatsForUser({
    workerId,
    filterParams: {ext_chat_id: roomId},
  });
  return chats?.find((chat) => chat.ext_chat_id === roomId) ?? null;
};

const getProjectForRoom = async (projectId: string) => {
  try {
    const project = await projectService.getProjectById(projectId);
    return project.optimizer_project_id ? null : project;
  } catch (error) {
    if (error instanceof HTTPError && error.response.status === HttpStatusCode.NotFound) {
      return null;
    } else {
      throw error;
    }
  }
};

export function useGetProjectByRoomId(roomId: string) {
  const [currentUser] = useAtom(workerAtom);

  const {data: chat} = useQuery({
    queryKey: [...QUERY_CACHE_KEYS.workerChats(currentUser.id), roomId],
    queryFn: async () => getChatForRoom(currentUser.id, roomId),
    enabled: !!roomId && !!currentUser,
  });

  const projectId = chat?.project_id || '';

  return useQuery({
    queryKey: QUERY_CACHE_KEYS.getProjectName(projectId),
    queryFn: async () => getProjectForRoom(projectId),
    enabled: !!projectId,
  });
}
