import {useCallback} from 'react';
import {TUploadItem} from '../state/room/roomInputDrafts';
import {fulfilledPromiseSettledResult} from '../utils/common';
import {encryptFile} from '../utils/matrix';
import {safeFile} from '../utils/mimeTypes';
import {useMatrixClient} from './useMatrixClient';

interface UseHandleFilesProps {
  roomId: string;
  setUploadBoard: (value: boolean) => void;
  setSelectedFiles: (action: {
    type: 'PUT';
    item: TUploadItem[];
  }) => void;
}

export const useHandleFiles = ({roomId, setUploadBoard, setSelectedFiles}: UseHandleFilesProps) => {
  const mx = useMatrixClient();

  const handleFiles = useCallback(
    async (files: File[]) => {
      setUploadBoard(true);
      const safeFiles = files.map(safeFile);
      const fileItems: TUploadItem[] = [];

      if (mx.isRoomEncrypted(roomId)) {
        const encryptFiles = fulfilledPromiseSettledResult(
          await Promise.allSettled(safeFiles.map((f) => encryptFile(f))),
        );
        encryptFiles.forEach((ef) => fileItems.push(ef));
      } else {
        safeFiles.forEach((f) => fileItems.push({file: f, originalFile: f, encInfo: undefined}));
      }
      setSelectedFiles({
        type: 'PUT',
        item: fileItems,
      });
    },
    [setSelectedFiles, roomId, setUploadBoard, mx?.isRoomEncrypted],
  );

  return handleFiles;
};
