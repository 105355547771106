import {IContent, MatrixClient} from 'matrix-js-sdk';
import {MutableRefObject, useCallback} from 'react';
import {BaseEditor} from 'slate';
import {toPlainText} from '../components/editor';
import {
  getAudioMsgContent,
  getFileMsgContent,
  getImageMsgContent,
  getVideoMsgContent,
} from '../features/room/msgContent';
import {TUploadItem} from '../state/room/roomInputDrafts';
import {UploadSuccess} from '../state/upload';
import {UploadBoardImperativeHandlers} from './useUploadSendHandler';

type UseSendUploadProps = {
  mx: MatrixClient;
  roomId: string;
  editor: BaseEditor;
  selectedFiles: TUploadItem[];
  setSelectedFiles: (action: {type: 'RESET'} | {type: 'PUT'; item: TUploadItem[]}) => void;
  uploadBoardHandlers: MutableRefObject<UploadBoardImperativeHandlers | undefined>;
};

export const useSendUpload = ({
  mx,
  roomId,
  editor,
  selectedFiles,
  setSelectedFiles,
  uploadBoardHandlers,
}: UseSendUploadProps) => {
  const handleSendUpload = useCallback(
    async (uploads: UploadSuccess[]): Promise<IContent[]> => {
      const contentsPromises = uploads?.map(async (upload) => {
        const fileItem = selectedFiles.find((f) => f.file === upload.file);
        if (!fileItem) throw new Error('Broken upload');

        if (fileItem.file.type.startsWith('image')) {
          return getImageMsgContent(mx, fileItem, upload.mxc);
        }
        if (fileItem.file.type.startsWith('video')) {
          return getVideoMsgContent(mx, fileItem, upload.mxc);
        }
        if (fileItem.file.type.startsWith('audio')) {
          return getAudioMsgContent(fileItem, upload.mxc);
        }
        return getFileMsgContent(fileItem, upload.mxc);
      });

      const contents = await Promise.all(contentsPromises);

      const plaintext = toPlainText(editor.children).trim();

      if (plaintext === '') {
        await Promise.all(contents.map((content) => mx.sendMessage(roomId, content)));
        setSelectedFiles({type: 'RESET'});
      }

      return contents;
    },
    [editor.children, mx, roomId, selectedFiles, setSelectedFiles],
  );

  const processUploadResult = useCallback(async () => {
    const sendResult = await uploadBoardHandlers?.current?.handleSend();
    if (!sendResult || !Array.isArray(sendResult)) {
      return [];
    }
    return handleSendUpload(sendResult);
  }, [handleSendUpload, uploadBoardHandlers]);

  return {
    handleSendUpload,
    processUploadResult,
  } as const;
};

export type UseSendUploadReturn = ReturnType<typeof useSendUpload>;
