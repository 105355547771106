import {HTMLReactParserOptions} from 'html-react-parser';
import {IContent, MsgType, Room} from 'matrix-js-sdk';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {Attachment} from '~/types/matrix/room';
import {PdfViewer} from './Pdf-viewer';
import {ImageViewer} from './image-viewer';
// import { UrlPreviewCard, UrlPreviewHolder } from './url-preview';
import {Image, MediaControl, Video} from './media';
import {
  AudioContent,
  DownloadFile,
  FileContent,
  ImageContent,
  MAudio,
  MBadEncrypted,
  MEmote,
  MFile,
  MImage,
  MLocation,
  MNotice,
  MText,
  MVideo,
  ReadPdfFile,
  ReadTextFile,
  RenderBody,
  ThumbnailContent,
  UnsupportedContent,
  VideoContent,
} from './message';
import {TextViewer} from './text-viewer';

type RenderMessageContentProps = {
  displayName: string;
  msgType: string;
  ts: number;
  edited?: boolean;
  getContent: <T>() => T;
  mediaAutoLoad?: boolean;
  urlPreview?: boolean;
  highlightRegex?: RegExp;
  htmlReactParserOptions: HTMLReactParserOptions;
  outlineAttachment?: boolean;
  isReplyEvent?: boolean;
  replyAttachments?: Array<Attachment>;
  isReplyToImageEvent?: boolean;
  responseImage?: IContent;
  room: Room;
  mEventId?: string;
};

export function RenderMessageContent({
  displayName,
  msgType,
  // ts,
  edited,
  getContent,
  mediaAutoLoad,
  // urlPreview,
  highlightRegex,
  htmlReactParserOptions,
  outlineAttachment,
  replyAttachments,
  isReplyEvent,
  isReplyToImageEvent,
  responseImage,
  room,
  mEventId,
}: RenderMessageContentProps) {
  const {data: chatData} = useProjectAndGroupIdFromWorkerChats(room.roomId ?? '');
  const renderFile = () => (
    <MFile
      content={getContent()}
      renderFileContent={({body, mimeType, info, encInfo, url}) => (
        <FileContent
          body={body}
          mimeType={mimeType}
          renderAsPdfFile={() => (
            <ReadPdfFile
              body={body}
              mimeType={mimeType}
              url={url}
              encInfo={encInfo}
              renderViewer={(p) => <PdfViewer {...p} />}
            />
          )}
          renderAsTextFile={() => (
            <ReadTextFile
              body={body}
              mimeType={mimeType}
              url={url}
              encInfo={encInfo}
              renderViewer={(p) => <TextViewer {...p} />}
            />
          )}
        >
          <DownloadFile body={body} mimeType={mimeType} url={url} encInfo={encInfo} info={info} />
        </FileContent>
      )}
      outlined={outlineAttachment}
    />
  );

  if (msgType === MsgType.Text) {
    const attachments: Array<Attachment | IContent> = [];

    const isReplyWithImages = isReplyEvent && replyAttachments && replyAttachments?.length > 0;
    if (isReplyToImageEvent && responseImage) {
      attachments.push(responseImage);
    } else {
      const content = getContent<{attachments: Array<Attachment>}>();
      if (content?.attachments) {
        attachments.push(...content.attachments);
      }
    }

    return (
      <MText
        edited={edited}
        content={getContent()}
        isReplyEvent={isReplyEvent ?? false}
        renderBody={(props) => (
          <>
            <RenderBody
              {...props}
              room={room}
              highlightRegex={highlightRegex}
              htmlReactParserOptions={htmlReactParserOptions}
              hasAttachments={attachments.length > 0}
              projectId={chatData?.projectId ?? ''}
              mEventId={mEventId}
              replyAttachments={isReplyWithImages ? replyAttachments : []}
            />

            <br />

            {attachments?.map((attachment) =>
              attachment.url ? (
                <MImage
                  key={attachment.url}
                  content={{url: attachment.url, msgtype: MsgType.Image}}
                  renderImageContent={(contentProps) => (
                    <ImageContent
                      {...contentProps}
                      autoPlay={mediaAutoLoad}
                      renderImage={(p) => <Image {...p} loading="lazy" />}
                      renderViewer={(p) => <ImageViewer {...p} />}
                    />
                  )}
                  outlined={outlineAttachment}
                />
              ) : null,
            )}
          </>
        )}
      />
    );
  }

  if (msgType === MsgType.Emote) {
    return (
      <MEmote
        displayName={displayName}
        edited={edited}
        content={getContent()}
        renderBody={(props) => (
          <RenderBody
            {...props}
            room={room}
            highlightRegex={highlightRegex}
            htmlReactParserOptions={htmlReactParserOptions}
          />
        )}
      />
    );
  }

  if (msgType === MsgType.Notice) {
    return (
      <MNotice
        edited={edited}
        content={getContent()}
        renderBody={(props) => (
          <RenderBody
            {...props}
            room={room}
            highlightRegex={highlightRegex}
            htmlReactParserOptions={htmlReactParserOptions}
          />
        )}
      />
    );
  }

  if (msgType === MsgType.Image) {
    return (
      <MImage
        content={getContent()}
        renderImageContent={(props) => (
          <ImageContent
            {...props}
            autoPlay={mediaAutoLoad}
            renderImage={(p) => <Image {...p} loading="lazy" />}
            renderViewer={(p) => <ImageViewer {...p} />}
          />
        )}
        outlined={outlineAttachment}
      />
    );
  }

  if (msgType === MsgType.Video) {
    return (
      <MVideo
        content={getContent()}
        renderAsFile={renderFile}
        renderVideoContent={({body, info, mimeType, url, encInfo}) => (
          <VideoContent
            body={body}
            info={info}
            mimeType={mimeType}
            url={url}
            encInfo={encInfo}
            renderThumbnail={
              mediaAutoLoad
                ? () => (
                    <ThumbnailContent
                      info={info}
                      renderImage={(src) => (
                        <Image alt={body} title={body} src={src} loading="lazy" />
                      )}
                    />
                  )
                : undefined
            }
            renderVideo={(p) => <Video {...p} />}
          />
        )}
        outlined={outlineAttachment}
      />
    );
  }

  if (msgType === MsgType.Audio) {
    return (
      <MAudio
        content={getContent()}
        renderAsFile={renderFile}
        renderAudioContent={(props) => (
          <AudioContent {...props} renderMediaControl={(p) => <MediaControl {...p} />} />
        )}
        outlined={outlineAttachment}
      />
    );
  }

  if (msgType === MsgType.File) {
    return renderFile();
  }

  if (msgType === MsgType.Location) {
    return <MLocation content={getContent()} />;
  }

  if (msgType === 'm.bad.encrypted') {
    return <MBadEncrypted />;
  }

  return <UnsupportedContent />;
}
