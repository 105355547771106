import {MatrixClient} from 'matrix-js-sdk';
import {mDirectAtom, useBindMDirectAtom} from '../mDirectList';
import {allInvitesAtom, useBindAllInvitesAtom} from '../room-list/inviteList';
import {muteChangesAtom, mutedRoomsAtom, useBindMutedRoomsAtom} from '../room-list/mutedRoomList';
import {allRoomsAtom, useBindAllRoomsAtom} from '../room-list/roomList';
import {roomToParentsAtom, useBindRoomToParentsAtom} from '../room/roomToParents';
import {roomToUnreadAtom, useBindRoomToUnreadAtom} from '../room/roomToUnread';
import {roomIdToTypingMembersAtom, useBindRoomIdToTypingMembersAtom} from '../typingMembers';

export const useBindAtoms = (mx: MatrixClient) => {
  useBindMDirectAtom(mx, mDirectAtom);
  useBindAllInvitesAtom(mx, allInvitesAtom);
  useBindAllRoomsAtom(mx, allRoomsAtom);
  useBindRoomToParentsAtom(mx, roomToParentsAtom);
  useBindMutedRoomsAtom(mx, mutedRoomsAtom);
  useBindRoomToUnreadAtom(mx, roomToUnreadAtom, muteChangesAtom);

  useBindRoomIdToTypingMembersAtom(mx, roomIdToTypingMembersAtom);
};
