import {Box, as} from 'folds';
import {ReactNode} from 'react';
import * as css from './layout.css';

type ModernLayoutProps = {
  before?: ReactNode;
  isCurrentUser?: boolean;
};

export const ModernLayout = as<'div', ModernLayoutProps>(
  ({before, children, isCurrentUser, ...props}, ref) => (
    <Box className={css.messageBoxStyle({isCurrentUser})} gap="300" {...props} ref={ref}>
      <Box className={css.ModernBefore} shrink="No">
        {before}
      </Box>
      <Box grow="Yes" direction="Column">
        {children}
      </Box>
    </Box>
  ),
);
