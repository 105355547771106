import {IContent, ISendEventResponse, MatrixClient} from 'matrix-js-sdk';
import {CustomEventTypes} from '~/types/event';
import {IssueModelDTO} from '~/types/issue';
import {TaskType} from '~/types/task';
import {extractIssueIds, extractTaskIds} from './extract';
import {getWbsChain} from './osk';

type HandleDataBycoreContentParams = {
  content: IContent;
  roomId: string;
  mx: MatrixClient;
  oskMap: Map<string, TaskType>;
  getIssueById: (issueId: string) => IssueModelDTO | null;
  getTaskById: (taskId: string) => TaskType | null;
  isMentionPublic: boolean;
};

export async function handleDataBycoreContent({
  content,
  roomId,
  mx,
  oskMap,
  getIssueById,
  getTaskById,
  isMentionPublic,
}: HandleDataBycoreContentParams): Promise<ISendEventResponse> {
  const issueIds = extractIssueIds(content);
  const taskIds = extractTaskIds(content);
  const uniqueActivities = new Map();
  const uniqueIssues = new Map();

  const issues = issueIds
    .map((id: string) => {
      const issue = getIssueById(id);
      if (issue && !uniqueIssues.has(issue.id)) {
        const {id, name, status} = issue;
        const issueObject = {issue_id: id, name, status};
        uniqueIssues.set(id, issueObject);
        return issueObject;
      }
      return null;
    })
    .filter(Boolean);

  const activities = taskIds
    .map((id: string) => {
      const task = getTaskById(id);
      if (task && !uniqueActivities.has(task.id)) {
        const {id, name, status, outline_sort_key} = task;
        const activity = {
          task_id: id,
          name,
          status,
          wbs_chain: getWbsChain(outline_sort_key, oskMap),
        };
        uniqueActivities.set(id, activity);
        return activity;
      }
      return null;
    })
    .filter(Boolean);

  const customContent: IContent = {
    ...content,
    activities,
    issues,
    mention_is_not_public: !isMentionPublic,
    type: CustomEventTypes.Mention,
  };

  return await mx.sendEvent(roomId, CustomEventTypes.Mention, customContent);
}

export async function handleDataBycoreContentEdit(params: {
  content: IContent;
  roomId: string;
  mx: MatrixClient;
  oskMap: Map<string, TaskType>;
  getIssueById: (issueId: string) => IssueModelDTO | null;
  getTaskById: (taskId: string) => TaskType | null;
  isMentionPublic: boolean;
}): Promise<ISendEventResponse> {
  const {content, roomId, mx, oskMap, getIssueById, getTaskById, isMentionPublic} = params;

  const issueIds = extractIssueIds(content);
  const taskIds = extractTaskIds(content);
  const uniqueActivities = new Map();
  const uniqueIssues = new Map();

  const issues = issueIds
    .map((id: string) => {
      const issue = getIssueById(id);
      if (issue && !uniqueIssues.has(issue.id)) {
        const {id, name, status} = issue;
        const issueObject = {issue_id: id, name, status};
        uniqueIssues.set(id, issueObject);
        return issueObject;
      }
      return null;
    })
    .filter(Boolean);

  const activities = taskIds
    .map((id: string) => {
      const task = getTaskById(id);
      if (task && !uniqueActivities.has(task.id)) {
        const {id, name, status, outline_sort_key} = task;
        const activity = {
          task_id: id,
          name,
          status,
          wbs_chain: getWbsChain(outline_sort_key, oskMap),
        };
        uniqueActivities.set(id, activity);
        return activity;
      }
      return null;
    })
    .filter(Boolean);

  const customContent: IContent = {
    ...content,
    activities,
    issues,
    'm.new_content': {
      ...content['m.new_content'],
      activities,
      issues,
      mention_is_not_public: isMentionPublic,
    },
    mention_is_not_public: isMentionPublic,
    type: CustomEventTypes.Mention,
  };

  return await mx.sendEvent(roomId, CustomEventTypes.Mention, customContent);
}
