import {User, onAuthStateChanged} from 'firebase/auth';
import React, {useState, useEffect, useContext, createContext, useMemo, useRef} from 'react';
import initMatrix from '~/client/initMatrix';
import cons from '~/client/state/cons';
import {firebaseService} from '~/services/Firebase/FirebaseService';

export type AuthContextType = {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | null;
};

export const AuthContext = createContext<AuthContextType>({
  loading: false,
  isAuthenticated: false,
  user: null,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export function checkMatrixSession() {
  const accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);
  const userId = localStorage.getItem(cons.secretKey.USER_ID);
  const deviceId = localStorage.getItem(cons.secretKey.DEVICE_ID);
  const baseUrl = localStorage.getItem(cons.secretKey.BASE_URL);

  return !!(accessToken && userId && deviceId && baseUrl);
}

export function AuthProvider({children}: {children: React.ReactNode}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const prevUser = useRef<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseService.auth, (currentUser) => {
      setUser((user) => {
        prevUser.current = user;
        return currentUser;
      });

      // Handle Firebase token expiration or logout
      if (prevUser.current && !currentUser) {
        initMatrix.logout();
        firebaseService.signOut();
      }

      const hasMatrixSession = checkMatrixSession();
      setIsAuthenticated(!!currentUser && !currentUser?.isAnonymous && hasMatrixSession);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      loading,
      user,
    }),
    [isAuthenticated, loading, user],
  );

  return (
    <AuthContext.Provider value={contextValue}>{!loading ? children : null}</AuthContext.Provider>
  );
}
