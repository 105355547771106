export const MixpanelEvents = {
  CreateRoomClick: 'CreateRoomClick',
  Create1on1AddUserClick: 'Create1on1AddUserClick',
  Create1on1RoomClick: 'Create1on1RoomClick',
  CreateGroupAddUserClick: 'CreateGroupAddUserClick',
  CreateGroupClick: 'CreateGroupClick',
  ChatList3DotMarkReadClick: 'ChatList3DotMarkReadClick',
  ChatListChatRoom3DotMarkReadClick: 'ChatListChatRoom3DotMarkReadClick',
  ChatListChatRoom3DotLeaveRoomClick: 'ChatListChatRoom3DotLeaveRoomClick',
  ChatListChatRoom3DotEditRoomClick: 'ChatListChatRoom3DotEditRoomClick',
  ChatListSearchClick: 'ChatListSearchClick',
  SearchFiltersSelectRoomsClick: 'SearchFiltersSelectRoomsClick',
  SearchKeywordsSubmit: 'SearchKeywordsSubmit',
  SearchResultsOpenClick: 'SearchResultsOpenClick',
  ChatRoom3DotMarkReadClick: 'ChatRoom3DotMarkReadClick',
  ChatRoom3DotLeaveRoomClick: 'ChatRoom3DotLeaveRoomClick',
  ChatRoom3DotEditRoomClick: 'ChatRoom3DotEditRoomClick',
  ChatRoomSearchClick: 'ChatRoomSearchClick',
  ChatRoomEditRoomClick: 'ChatRoomEditRoomClick',
  ChatRoomEditRoomSubmitClick: 'ChatRoomEditRoomSubmitClick',
  MessageBoxPhotoButtonClick: 'MessageBoxPhotoButtonClick',
  MessageBoxUserMentionClick: 'MessageBoxUserMentionClick',
  MessageBoxActivityMentionClick: 'MessageBoxActivityMentionClick',
  MessageBoxActivitySelectClick: 'MessageBoxActivitySelectClick',
  MessageBoxIssueMentionClick: 'MessageBoxIssueMentionClick',
  MessageBoxIssueSelectClick: 'MessageBoxIssueSelectClick',
  MessageBoxMentionUnselectClick: 'MessageBoxMentionUnselectClick',
  MessageBoxEmojiClick: 'MessageBoxEmojiClick',
  MessageBoxEmojiSelectClick: 'MessageBoxEmojiSelectClick',
  MessageBoxSendClick: 'MessageBoxSendClick',
  MessageContextReactionClick: 'MessageContextReactionClick',
  MessageContextReactionSelectClick: 'MessageContextReactionSelectClick',
  MessageContextReplyClick: 'MessageContextReplyClick',
  MessageContextEditClick: 'MessageContextEditClick',
  MessageContextEditUserMentionClick: 'MessageContextEditUserMentionClick',
  MessageContextEditActivityMentionClick: 'MessageContextEditActivityMentionClick',
  MessageContextEditIssueMentionClick: 'MessageContextEditIssueMentionClick',
  MessageContextEditEmojiClick: 'MessageContextEditEmojiClick',
  MessageContextEditSaveClick: 'MessageContextEditSaveClick',
  MessageContextEditCancelClick: 'MessageContextEditCancelClick',
  Message3DotClick: 'Message3DotClick',
  Message3DotRecentReactionsClick: 'Message3DotRecentReactionsClick',
  Message3DotCreateIssueClick: 'Message3DotCreateIssueClick',
  Message3DotCreateIssueAddPhotoClick: 'Message3DotCreateIssueAddPhotoClick',
  Message3DotCreateIssueSubmit: 'Message3DotCreateIssueSubmit',
  Message3DotCreateIssueXClick: 'Message3DotCreateIssueXClick',
  Message3DotAddReactionClick: 'Message3DotAddReactionClick',
  Message3DotAddReactionSelectClick: 'Message3DotAddReactionSelectClick',
  Message3DotViewReactionsClick: 'Message3DotViewReactionsClick',
  Message3DotReplyClick: 'Message3DotReplyClick',
  Message3DotEditMessageClick: 'Message3DotEditMessageClick',
  Message3DotEditUserMentionClick: 'Message3DotEditUserMentionClick',
  Message3DotEditActivityMentionClick: 'Message3DotEditActivityMentionClick',
  Message3DotEditIssueMentionClick: 'Message3DotEditIssueMentionClick',
  Message3DotEditEmojiClick: 'Message3DotEditEmojiClick',
  Message3DotEditSaveClick: 'Message3DotEditSaveClick',
  Message3DotEditCancelClick: 'Message3DotEditCancelClick',
  Message3DotReadReceiptsClick: 'Message3DotReadReceiptsClick',
  Message3DotDeleteClick: 'Message3DotDeleteClick',
  Message3DotDeleteConfirmClick: 'Message3DotDeleteConfirmClick',
  MessageActivityMentionBannerClick: 'MessageActivityMentionBannerClick',
  MessageIssueMentionBannerClick: 'MessageIssueMentionBannerClick',
} as const;
