import "src/app/components/message/layout/layout.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/components/message/layout/layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WVyXLjIBCG734KLqlKaoILebwk8iVvkkKiJfdYAqVB3qb87lMgb3gZJRct8H+9I30sYVuQrMGyz6SsNzu7fmN/B4yJp3BjLJP5siTTasVzUxlK2UrSM+fma6rEbPkyHzC2HzA2mnyPqE/E5Mc+Zj/2kYgfOtkPhsdCiAA2xqJDo1NmHebLrZc50xzpz2Q63hQlJPQyv4RHAfb73XtyA3zFwO9e4MrDuBewMTDpBVwMTHuBNgZmvcAqBt4DIDXWsqvxeQhHQojaMpAWOGpuWje/lHJ0QN1TblrtUoa6QI0OIgcyOPh/62dxTNmv82Oga0klah6aLiJpfrN/TPeQfxiqRiqFurzTz9uG9WvaYDMzpIA4SYWtTZm4Uo3ENedX4uD/9CSnD/sbvkblFilLxHA6mhDUPoDTmniKKNMdmi7lg9nhiXIk9fE4hefCUO1bXVuWtxnmPIMdAj2LVyaGb/4ynfnr+yykLSssNbdQFf44SnKR7yb4Nq2rUIP3K7p4mTUVqjvH/VFn7tb4pp7z3smaxJP1FeJTaJtKblNWVLDxNkp5+zW5OokUSA9whQR5V0Eya06wArLx0NtH6ki1uu7vRBy69Kgs9hspy6C5swHfrOlFhOuunyugojLrlC1QKdBh+hbogNtG5pAybdYkmzBesHH8rIeqwsai9Uaz1jmjr0znLVkfZ2NQO6B7unTh7b2ym/XC5K3lK7SYVRCsBe8KckOHj1mrFZCfxSirTRCvDSmeEchlysKN+5VIuO2El6k2BLxL9kK369pttOMWd5CyZDgR466V3jtfAJYL59fH79OR37igk+43d6dju9Cxzq7bVpAydLLC3OMfNSiUzOYEoJnUij3XqI+TNJu+NZuXYPfkxx1+wxey0fnDsB/s/wG0Q40uiwgAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var AvatarBase = '_1gmxzswo';
export var BubbleContent = '_1gmxzswv';
export var BubbleBefore = '_1gmxzswt';
export var Username = '_1gmxzsww';
export var CompactHeader = '_1gmxzswn _1mqalmd1 _1mqalmd0 _1gmxzsw0';
export var StickySection = '_1gmxzsw0';
export var messageBoxStyle = _7a468({defaultClassName:'_1gmxzswq _1gmxzswp',variantClassNames:{isCurrentUser:{true:'_1gmxzswr',false:'_1gmxzsws'}},defaultVariants:{},compoundVariants:[]});
export var MessageBase = _7a468({defaultClassName:'_1gmxzswc _1mqalmd1 _1mqalmd0',variantClassNames:{space:{'0':'_1gmxzsw2','100':'_1gmxzsw3','200':'_1gmxzsw4','300':'_1gmxzsw5','400':'_1gmxzsw6','500':'_1gmxzsw7'},collapse:{true:'_1gmxzswj'},autoCollapse:{true:'_1gmxzswb'},highlight:{true:'_1gmxzsw9'},selected:{true:'_1gmxzswa'}},defaultVariants:{space:'400'},compoundVariants:[]});
export var MessageTextBody = _7a468({defaultClassName:'_1gmxzswx',variantClassNames:{preWrap:{true:'_1gmxzswy'},jumboEmoji:{true:'_1gmxzswz'},emote:{true:'_1gmxzsw10'}},defaultVariants:{},compoundVariants:[]});
export var ModernBefore = '_1gmxzswt';