import * as Sentry from '@sentry/react';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {ENV} from '~/util/env';

export function initSentry() {
  if (import.meta.env.PROD && ENV.PUBLIC_SENTRY_DSN) {
    Sentry.init({
      tracesSampleRate: 0.2,
      dsn: ENV.PUBLIC_SENTRY_DSN,
      replaysSessionSampleRate: process.env.SENTRY_ORG !== 'bycore' ? 1 : 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      release: ENV.PUBLIC_SENTRY_RELEASE,
    });
  }
}
