export enum TaskStatus {
  approved = 'approved',
  assigned = 'assigned',
  blocked = 'blocked',
  closed = 'closed',
  done = 'done',
  inProgress = 'in_progress',
  rework = 'rework',
  tba = 'tba',
  verified = 'verified',
  archived = 'archived',
  unblocked = 'unblocked',
  notDone = 'not_done',
}

export type TaskStatusQuery = {
  status?: TaskStatus;
  statusList?: TaskStatus[];
  includeArchived?: boolean;
  deleted?: boolean;
};
