import {useCallback, useEffect, useState} from 'react';
import {TaskType} from '~/types/task';

export function useOutlineSortKeyMap(initialTasks: TaskType[] | undefined) {
  const [oskMap, setOskMap] = useState<Map<string, TaskType>>(new Map());

  useEffect(() => {
    if (initialTasks) {
      setOskMap(new Map(initialTasks.map((task) => [task.outline_sort_key, task])));
    }
  }, [initialTasks]);

  const addTaskToOskMap = useCallback((outlineSortKey: string, task: TaskType) => {
    setOskMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(outlineSortKey, task);
      return newMap;
    });
  }, []);

  const updateTaskOskMap = useCallback((outlineSortKey: string, taskUpdate: Partial<TaskType>) => {
    setOskMap((prevMap) => {
      if (prevMap.has(outlineSortKey)) {
        const newMap = new Map(prevMap);
        const existingTask = prevMap.get(outlineSortKey);
        if (existingTask) {
          newMap.set(outlineSortKey, {...existingTask, ...taskUpdate});
        }
        return newMap;
      }
      return prevMap;
    });
  }, []);

  const removeTaskOskMap = useCallback((outlineSortKey: string) => {
    setOskMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(outlineSortKey);
      return newMap;
    });
  }, []);

  const clearOskMap = useCallback(() => {
    setOskMap(new Map());
  }, []);

  return {
    oskMap,
    addTaskToOskMap,
    updateTaskOskMap,
    removeTaskOskMap,
    clearOskMap,
  };
}
