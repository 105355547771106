import {useCallback, useRef} from 'react';
import {Editor, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';
import {AutocompletePrefix} from '../components/editor';

type UsePrefixQueryProps = {
  editor: Editor;
  handleKeyUp?: (event: React.KeyboardEvent) => void;
};

export function usePrefixQuery({editor, handleKeyUp}: UsePrefixQueryProps) {
  const lastActionRef = useRef<Record<AutocompletePrefix, 'insert' | 'remove'>>({
    [AutocompletePrefix.TaskMention]: 'remove',
    [AutocompletePrefix.UserMention]: 'remove',
    [AutocompletePrefix.Emoticon]: 'remove',
    [AutocompletePrefix.Command]: 'remove',
    [AutocompletePrefix.Issue]: 'remove',
  });

  const handlePrefixQuery = useCallback(
    (prefix: AutocompletePrefix) => {
      const domNode = ReactEditor.toDOMNode(editor, editor);

      if (editor.selection) {
        const [start] = Editor.edges(editor, editor.selection);
        const textBefore = Editor.string(editor, {
          anchor: Editor.start(editor, []),
          focus: start,
        });

        if (lastActionRef.current[prefix] === 'remove' || !textBefore.endsWith(prefix)) {
          // Insert the prefix
          const needsSpace = textBefore.length > 0 && !textBefore.endsWith(' ');

          Editor.withoutNormalizing(editor, () => {
            if (needsSpace) {
              Transforms.insertText(editor, ' ');
            }
            Transforms.insertText(editor, prefix);
          });

          // Trigger the existing keyup handler if it exists
          if (typeof handleKeyUp === 'function') {
            const keyupEvent = new KeyboardEvent('keyup', {
              key: prefix,
              bubbles: true,
              cancelable: true,
            });
            handleKeyUp(keyupEvent as unknown as React.KeyboardEvent);
          }

          lastActionRef.current[prefix] = 'insert';
        } else {
          // Remove the prefix
          Editor.withoutNormalizing(editor, () => {
            Transforms.move(editor, {distance: 1, unit: 'character', reverse: true});
            Transforms.delete(editor);
            // Remove preceding space if exists
            if (editor.selection) {
              const [newStart] = Editor.edges(editor, editor.selection);
              const charBefore = Editor.string(editor, {
                anchor: Editor.before(editor, newStart) || newStart,
                focus: newStart,
              });
              if (charBefore === ' ') {
                Transforms.delete(editor, {unit: 'character', reverse: true});
              }
            }
          });

          lastActionRef.current[prefix] = 'remove';
        }
      }

      // Focus the editor and move cursor to the end
      if (domNode && domNode.focus) {
        domNode.focus();
        const domSelection = window.getSelection();
        if (domSelection) {
          domSelection.selectAllChildren(domNode);
          domSelection.collapseToEnd();
        }
      }
    },
    [editor, handleKeyUp],
  );

  return handlePrefixQuery;
}
