import {fetchAllGenerator} from '~/app/utils/batchDataFetcher';
import {sentryLog} from '~/app/utils/logger';
import {IssueModelDTO} from '~/types/issue';
import {LoadOptions} from '~/types/loadOptions';
import {SortOrder} from '~/types/sort';
import {tasksService} from '../TaskService/TaskService';

class IssuesService {
  private static instance: IssuesService;

  public static getInstance(): IssuesService {
    if (!IssuesService.instance) {
      IssuesService.instance = new IssuesService();
    }
    return IssuesService.instance;
  }

  public async loadIssues({
    queryParams,
    projectId,
    dataRange,
  }: Partial<LoadOptions>): Promise<IssueModelDTO[]> {
    if (!projectId) {
      throw new Error('Project ID is required to load issues');
    }

    const allIssues: IssueModelDTO[] = [];

    for await (const batch of fetchAllGenerator<IssueModelDTO>({
      request: async (offset: number, take: number) => {
        try {
          let schedIntersect: [string, string] | undefined;
          if (dataRange && dataRange.length >= 2) {
            schedIntersect = [dataRange[0].toISOString(), dataRange[1].toISOString()];
          }

          const filterParams = {
            ...queryParams,
            ...(schedIntersect ? {sched_intersect: schedIntersect} : {}),
          };

          const issues = await tasksService.getTaskIssues(projectId, {
            range_params: {
              offset,
              limit: take,
            },
            sort_params: {
              sort_field: 'description',
              sort_order: SortOrder.DESC,
            },
            filter_params: filterParams,
          });

          return {
            data: issues,
            headers: {
              get: (name: string) =>
                name.toLowerCase() === 'x-total-count' ? issues.length.toString() : null,
            },
          };
        } catch (error) {
          sentryLog.error('Error fetching issues:', error as Error, {
            extra: {
              tag: 'IssuesService.loadIssues',
            },
          });
          throw error;
        }
      },
      initialTake: 500,
    })) {
      allIssues.push(...batch);
    }

    return allIssues;
  }
}

export const issuesService = IssuesService.getInstance();
