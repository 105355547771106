import * as Sentry from '@sentry/react';
import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {toast} from 'sonner';
import {CreateIssueFormSchema} from '~/app/organisms/create-issue/createIssueSchema';
import {toShortIso} from '~/app/utils/dates';
import {tasksService} from '~/services/TaskService/TaskService';
import {IssueModel} from '~/types/issue';
import {GroupMemberRole} from '~/types/member';
import {TaskDetailsType} from '~/types/taskDetails';

type SaveIssueParams = {
  onSuccess: (data: TaskDetailsType) => void;
  onError?: (error: unknown) => void;
};

export const useSaveIssue = ({
  onSuccess,
  onError,
}: SaveIssueParams): UseMutationResult<
  TaskDetailsType,
  unknown,
  CreateIssueFormSchema & {projectId: string; eventId: string; groupId: string}
> => {
  const {t} = useTranslation(['create_issue']);
  return useMutation({
    mutationFn: async (values) => {
      const preparedStartDate = values.startDate ? toShortIso(values.startDate) : undefined;
      const preparedEndDate = values.endDate ? toShortIso(values.endDate) : undefined;

      // Prepare ext_chat_events array
      const ext_chat_events = [
        {
          event_id: values.eventId,
          group_id: values.groupId,
          event_media_urls: [],
          event_formatted_body: '',
        },
      ];

      // Add the new attachment event if it exists
      if (values.attachmentEventId) {
        ext_chat_events.push({
          event_id: values.attachmentEventId,
          group_id: values.groupId,
          event_media_urls: [],
          event_formatted_body: '',
        });
      }

      const preparedIssue: Partial<IssueModel> = {
        cost_impact: Boolean(values.costImpact),
        description: values.description,
        impact: values.timeImpact,
        issue_type: values.issueType,
        name: values.newIssue,
        project_id: values.projectId,
        start_date: preparedStartDate,
        status: values.status,
        task_ids: values?.activity?.id ? [values.activity.id] : [],
        // api throws if no end_date is not provided
        ...(preparedEndDate && {end_date: preparedEndDate}),
        // api throws if no responsible_org_id is not provided
        ...(values.resolvingCompany ? {responsible_org_id: values.resolvingCompany} : {}),
        // api throws if no culpable_org_id is not provided
        ...(values.liableCompany ? {culpable_org_id: values.liableCompany} : {}),
        ext_chat_events: ext_chat_events,
      };

      const createdIssue = await tasksService.createIssue(preparedIssue);

      if (values.assignee !== createdIssue?.responsible?.[0]?.member_id) {
        await tasksService.addIssueAssignee(values.projectId, createdIssue.id, {
          member_id: values.assignee,
          member_role: GroupMemberRole.responsible,
        });
      }

      return createdIssue;
    },
    onSuccess: (data) => {
      toast.success(t('form.toast_message.success'));
      onSuccess(data);
    },
    onError: (err: unknown) => {
      Sentry.captureException(err);
      toast.error(t('form.toast_message.error'));
      onError?.(err);
    },
  });
};
