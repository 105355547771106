import {TFunction} from 'i18next';

export function preprocessTranslations(t: TFunction, prefix: string, keys: string[]) {
  return keys.reduce(
    (acc, key) => {
      acc[key] = t(`${prefix}.${key}`);
      return acc;
    },
    {} as Record<string, string>,
  );
}
