import {enableMapSet} from 'immer';
import {createRoot} from 'react-dom/client';
import '@fontsource/inter/variable.css';
import 'folds/dist/style.css';
import {configClass, varsClass} from 'folds';
import './index.scss';

import settings from './client/state/settings';

import App from '~/app/pages/App';
import {initSentry} from '~/shared/sentry';
import {initI18n} from './i18n.mjs';
import {mixpanelService} from './services/MixPanelService/MixPanelService';
import {ENV} from './util/env';

enableMapSet();
initSentry();
initI18n();
mixpanelService.initialize(ENV.PUBLIC_MIXPANEL_TOKEN);

document.body.classList.add(configClass, varsClass);
settings.applyTheme();

const mountApp = () => {
  const rootContainer = document.getElementById('root');

  if (rootContainer === null) {
    console.error('Root container element not found!');
    return;
  }

  const root = createRoot(rootContainer);
  root.render(<App />);
};

mountApp();
