import {useQuery} from '@tanstack/react-query';
import {SelectOption} from '~/app/atoms/select/Select';
import {sortAlphabetically} from '~/app/utils/sort';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {projectService} from '~/services/ProjectService/ProjectService';

export const useProjectSubcontractors = (projectId: string) => {
  const queryKey = QUERY_CACHE_KEYS.projectSubcontractors(projectId);

  const fetchSubcontractors = async (): Promise<SelectOption<string>[]> => {
    const orgs = await projectService.getOrgs(projectId);
    const preparedOrgs = orgs.map((sub) => ({
      value: sub.id,
      label: sub?.group?.name ?? '',
    })) as SelectOption<string>[];
    return sortAlphabetically(preparedOrgs, 'label');
  };

  const {data, isLoading: isFetching} = useQuery({
    queryKey,
    queryFn: fetchSubcontractors,
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  // Provide an empty array as the default value for options
  const options = data ?? [];

  return {options, isFetching};
};
