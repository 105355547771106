import {Icon, Icons} from 'folds';
import {ReactNode, forwardRef} from 'react';
import ReactSelect, {
  Props as ReactSelectProps,
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';
import {SelectVariants, selectRecipe, selectStyles} from './Select.css';
import {customStyles} from './reactSelectStyles';

export type SelectOption<T = string> = {
  value: T;
  label: string;
};

type SelectProps<T> = Omit<
  ReactSelectProps<SelectOption<T>, false>,
  'options' | 'value' | 'onChange'
> &
  SelectVariants & {
    options: SelectOption<T>[];
    value: T | undefined;
    onChange: (value: T | null) => void;
    placeHolder: string;
    before?: ReactNode;
    after?: ReactNode;
  };

export const Select = forwardRef<HTMLDivElement, SelectProps<string>>(
  (
    {
      className,
      variant = 'Background',
      size,
      outlined,
      radii,
      before,
      after,
      options,
      placeHolder,
      value,
      onChange,
      ...props
    },
    ref,
  ) => {
    const containerClassName = selectRecipe({variant, size, outlined, radii});

    const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption<string>, false>) => {
      const isOpen = props.selectProps.menuIsOpen;

      return (
        <components.DropdownIndicator {...props}>
          <Icon src={isOpen ? Icons.ChevronTop : Icons.ChevronBottom} size="100" />
        </components.DropdownIndicator>
      );
    };

    const ClearIndicator = (props: ClearIndicatorProps<SelectOption<string>, false>) => (
      <components.ClearIndicator {...props}>
        <Icon src={Icons.Cross} size="100" />
      </components.ClearIndicator>
    );

    const handleChange = (selectedOption: SelectOption<string> | null) => {
      if (onChange) {
        onChange(selectedOption ? selectedOption.value : null);
      }
    };

    const selectedOption = value ? options.find((option) => option.value === value) : null;

    return (
      <div
        className={`${containerClassName} ${className || ''}`}
        data-ui-before={before ? true : undefined}
        data-ui-after={after ? true : undefined}
        ref={ref}
      >
        {before}
        <ReactSelect<SelectOption<string>, false>
          {...props}
          value={selectedOption}
          onChange={handleChange}
          options={options}
          classNamePrefix="react-select"
          className={selectStyles.select}
          components={{DropdownIndicator, ClearIndicator}}
          styles={customStyles}
          placeholder={placeHolder}
        />
        {after}
      </div>
    );
  },
);
