import {useEffect, useState} from 'react';

import cons from '~/client/state/cons';
import navigation from '~/client/state/navigation';

import RoomSettings from '../room/RoomSettings';
import Settings from '../settings/Settings';
import SpaceSettings from '../space-settings/SpaceSettings';

function Windows() {
  const [inviteUser, changeInviteUser] = useState({
    isOpen: false,
    roomId: undefined,
    term: undefined,
  });

  function openInviteUser(roomId, searchTerm) {
    changeInviteUser({
      isOpen: true,
      roomId,
      searchTerm,
    });
  }

  useEffect(() => {
    navigation.on(cons.events.navigation.INVITE_USER_OPENED, openInviteUser);
    return () => {
      navigation.removeListener(cons.events.navigation.INVITE_USER_OPENED, openInviteUser);
    };
  }, []);

  return (
    <>
      <Settings />
      <SpaceSettings />
      <RoomSettings />
    </>
  );
}

export default Windows;
