import {IContent} from 'matrix-js-sdk';
import {MutableRefObject, useCallback, useImperativeHandle, useRef} from 'react';
import {Upload, UploadStatus, UploadSuccess} from '../state/upload'; // Import types from your state

export type UploadBoardImperativeHandlers = {
  handleSend: () => Promise<UploadSuccess[] | Promise<void>>;
};

interface UseUploadSendHandlerProps {
  uploads: Upload[];
  onSend: (uploads: UploadSuccess[]) => Promise<IContent[]>;
  imperativeHandlerRef: MutableRefObject<UploadBoardImperativeHandlers | undefined>;
  onError?: (error: Error) => void;
}

export const useUploadSendHandler = ({
  uploads,
  onSend,
  imperativeHandlerRef,
  onError,
}: UseUploadSendHandlerProps) => {
  const sendingRef = useRef(false);

  const handleSend = useCallback(async () => {
    if (sendingRef.current) {
      console.warn('Send operation already in progress');
      return [];
    }

    sendingRef.current = true;

    try {
      const successfulUploads = uploads.filter(
        (upload): upload is UploadSuccess => upload.status === UploadStatus.Success,
      );

      if (successfulUploads.length === 0) {
        console.warn('No successful uploads to send');
        return [];
      }

      await onSend(successfulUploads);

      return successfulUploads;
    } catch (error) {
      console.error('Error in handleSend:', error);
      onError?.(error instanceof Error ? error : new Error(String(error)));
      return [];
    } finally {
      sendingRef.current = false;
    }
  }, [uploads, onSend, onError]);

  useImperativeHandle(
    imperativeHandlerRef,
    () => ({
      handleSend,
    }),
    [handleSend],
  );

  const getSendingStatus = useCallback(() => sendingRef.current, []);

  return {
    handleSend,
    isSending: sendingRef.current,
    getSendingStatus,
  };
};
