import {CompanyWorker} from '~/types/worker';

export function filterDuplicatedProjectWorkers(workers: CompanyWorker[]) {
  if (!workers) return [];
  return workers.reduce((acc, cur) => {
    if (!acc.find((w) => w.worker_id === cur.worker_id)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CompanyWorker[]);
}
