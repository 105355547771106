import {useAtomValue} from 'jotai';
import {useMatrixClient} from '../../../hooks/useMatrixClient';
import {useOrphanRooms} from '../../../state/hooks/roomList';
import {mDirectAtom} from '../../../state/mDirectList';
import {allRoomsAtom} from '../../../state/room-list/roomList';
import {roomToParentsAtom} from '../../../state/room/roomToParents';

export const useHomeRooms = () => {
  const mx = useMatrixClient();
  const mDirects = useAtomValue(mDirectAtom);
  const roomToParents = useAtomValue(roomToParentsAtom);
  const rooms = useOrphanRooms(mx, allRoomsAtom, mDirects, roomToParents);
  return rooms;
};
