import ky, {Options} from 'ky';
import {firebaseService} from '~/services/Firebase/FirebaseService';
import {HEADERS} from '~/types/headers';
import {ENV} from './env';

export const JourneyApi = ky.create({
  prefixUrl: ENV.PUBLIC_JOURNEY_API_URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await firebaseService.getUserIdToken();
        if (token && !request.headers.has(HEADERS.Authorization)) {
          request.headers.set(HEADERS.Authorization, `Bearer ${token}`);
        }
      },
    ],
  },
} satisfies Options);

export const ChatApi = ky.create({
  prefixUrl: ENV.PUBLIC_MATRIX_API_URL,
} satisfies Options);
