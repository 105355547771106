import {useMutation} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {HTTPError} from 'ky';
import {workerAtom} from '~/app/state/worker';
import {LeaveRoomResult, chatService} from '~/services/ChatService/ChatService';
import {NonSuccessResponse} from '../services/handlers';

type LeaveRoomProps = {
  onSuccess: (data: LeaveRoomResult | NonSuccessResponse) => void;
  onError: (e: HTTPError | unknown) => void;
};

export const useLeaveRoom = ({onError, onSuccess}: LeaveRoomProps) => {
  const [currentUser] = useAtom(workerAtom);
  return useMutation({
    mutationFn: ({roomId, direct}: {roomId: string; direct: boolean}) =>
      chatService.leaveRoom(roomId, currentUser.id, direct),
    onError,
    onSuccess,
  });
};
