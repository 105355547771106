import to from 'await-to-js';
import {FirebaseError} from 'firebase/app';
import {LoginResponse} from 'matrix-js-sdk';
import {useCallback, useEffect} from 'react';
import {autoDiscovery, specVersions} from '~/app/cs-api';
import {ClientConfig, clientAllowedServer} from '~/app/hooks/useClientConfig';
import {CustomAuthError} from '~/app/utils/custom-errors';
import {updateLocalStore} from '~/client/action/auth';
import {chatService} from '~/services/ChatService/ChatService';
import {firebaseService} from '~/services/Firebase/FirebaseService';
import {workerService} from '~/services/WorkerService/WorkerService';
import {HttpStatusCode} from '~/types/http-status';
import {
  deleteAfterLoginRedirectPath,
  getAfterLoginRedirectPath,
} from '../../afterLoginRedirectPath';
import {getHomePath} from '../../pathUtils';

export enum GetBaseUrlError {
  NotAllow = 'NotAllow',
  NotFound = 'NotFound',
}
export const factoryGetBaseUrl = (clientConfig: ClientConfig, server: string) => {
  const getBaseUrl = async (): Promise<string> => {
    if (!clientAllowedServer(clientConfig, server)) {
      throw new Error(GetBaseUrlError.NotAllow);
    }

    const [, discovery] = await to(autoDiscovery(fetch, server));

    let mxIdBaseUrl: string | undefined;
    const [, discoveryInfo] = discovery ?? [];

    if (discoveryInfo) {
      mxIdBaseUrl = discoveryInfo['m.homeserver'].base_url;
    }

    if (!mxIdBaseUrl) {
      throw new Error(GetBaseUrlError.NotFound);
    }
    const [, versions] = await to(specVersions(fetch, mxIdBaseUrl));
    if (!versions) {
      throw new Error(GetBaseUrlError.NotFound);
    }
    return mxIdBaseUrl;
  };
  return getBaseUrl;
};

export enum LoginError {
  Forbidden = 'Forbidden',
  RateLimited = 'RateLimited',
  Unknown = 'Unknown',
}

export type CustomLoginResponse = {
  baseUrl: string;
  response: LoginResponse;
};

const createAuthError = (error: any): CustomAuthError => {
  if (error.status === HttpStatusCode.Forbidden || error.status === HttpStatusCode.Unauthorized) {
    return new CustomAuthError({
      errcode: LoginError.Forbidden,
    });
  }

  return new CustomAuthError({
    errcode: LoginError.Unknown,
  });
};

export type EmailPasswordLoginResponse = {
  workerId: string;
} & LoginResponse;

export type LoginRequest = {
  email: string;
  password: string;
};

export const login = async (
  data: LoginRequest,
  baseUrl: string,
): Promise<EmailPasswordLoginResponse> => {
  try {
    const [signInError] = await to<any, FirebaseError>(
      firebaseService.signInWithEmailAndPassword(data.email, data.password),
    );

    if (signInError) {
      if (
        signInError.code === 'auth/user-not-found' ||
        signInError.code === 'auth/wrong-password'
      ) {
        throw new CustomAuthError({
          errcode: LoginError.Forbidden,
        });
      }

      if (signInError.code === 'auth/too-many-requests') {
        throw new CustomAuthError({
          errcode: LoginError.RateLimited,
        });
      }

      throw new CustomAuthError({
        errcode: LoginError.Unknown,
      });
    }

    const [userError, loggedInUser] = await to(firebaseService.getCurrentUser());

    if (userError) {
      throw createAuthError(userError);
    }

    const [checkSignUpError, checkSignUp] = await to(workerService.checkSignUp());

    if (checkSignUpError) {
      throw createAuthError(checkSignUpError);
    }

    if (checkSignUp && loggedInUser) {
      const [tokenError, tokenId] = await to(firebaseService.getUserIdToken());

      if (tokenError) {
        throw createAuthError(tokenError);
      }

      const [workerError, currentWorker] = await to(
        workerService.loginOrSignupWorker({
          user: loggedInUser,
          idToken: tokenId,
          loginType: 'password',
        }),
      );

      if (workerError) {
        throw createAuthError(workerError);
      }

      const [matrixSessionError, matrixSession] = await to(
        chatService.initializeMatrixSession(currentWorker.id, baseUrl),
      );

      if (matrixSessionError) {
        throw createAuthError(matrixSessionError);
      }

      return {workerId: currentWorker?.id, ...matrixSession};
    }

    // If we reach here, it means checkSignUp was false or loggedInUser was null
    throw new CustomAuthError({
      errcode: LoginError.Unknown,
    });
  } catch (error) {
    if (error instanceof CustomAuthError) {
      throw error;
    }
    // If it's an unexpected error type, throw a generic Unknown error
    throw new CustomAuthError({
      errcode: LoginError.Unknown,
    });
  }
};

export const useLoginComplete = (response?: EmailPasswordLoginResponse) => {
  const performNavigation = useCallback(() => {
    if (!response) {
      // TODO: Add sentry
      // sentry error here
      return;
    }

    try {
      const {user_id, access_token, device_id, well_known} = response;

      if (!well_known || !well_known['m.homeserver']) {
        throw new Error('Missing or incomplete well_known data');
      }

      const baseUrl = well_known['m.homeserver'].base_url;

      if (!baseUrl) {
        throw new Error('Missing base_url in well_known data');
      }

      updateLocalStore(access_token, device_id, user_id, baseUrl);

      const afterLoginRedirectUrl = getAfterLoginRedirectPath();
      deleteAfterLoginRedirectPath();

      const targetPath = afterLoginRedirectUrl ?? getHomePath();

      // Replace the current history entry
      window.history.replaceState(null, '', targetPath);

      // Navigate to the new URL
      window.location.assign(targetPath);
    } catch (error) {
      // TODO: Add sentry
      // sentry error here
      console.error('useLoginComplete: Error during navigation process', error);
    }
  }, [response]);

  useEffect(() => {
    performNavigation();
  }, [performNavigation]);
};
