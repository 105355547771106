import {Box} from 'folds';
import {useParams} from 'react-router-dom';
import {PowerLevelsContextProvider, usePowerLevels} from '~/app/hooks/usePowerLevels';
import {useRoom} from '~/app/hooks/useRoom';
import {RoomView} from './RoomView';

export function Room() {
  const {eventId} = useParams();
  const room = useRoom();

  const powerLevels = usePowerLevels(room);

  return (
    <PowerLevelsContextProvider value={powerLevels}>
      <Box grow="Yes">
        <RoomView room={room} eventId={eventId} />
      </Box>
    </PowerLevelsContextProvider>
  );
}
