import {atomWithStorage} from 'jotai/utils';

export type GetLocalStorageItem<T> = (key: string, defaultValue?: T) => T;
export type SetLocalStorageItem<T> = (key: string, value: T) => void;

export const getLocalStorageItem = <T>(key: string, defaultValue?: T): T => {
  const item = localStorage.getItem(key);
  if (item === null || item === 'undefined') return defaultValue as T;
  try {
    return JSON.parse(item) as T;
  } catch {
    return defaultValue as T;
  }
};

export const setLocalStorageItem = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const atomWithLocalStorage = <T>(
  key: string,
  customGetItem: GetLocalStorageItem<T> = getLocalStorageItem,
  customSetItem: SetLocalStorageItem<T> = setLocalStorageItem,
) => {
  const defaultValue = customGetItem(key);
  return atomWithStorage<T>(key, defaultValue, {
    getItem: (k, fallback) => customGetItem(k, fallback),
    setItem: customSetItem,
    removeItem: (k) => localStorage.removeItem(k),
  });
};
