import cn from 'classnames';
import {
  Avatar,
  Box,
  Button,
  Icon,
  Icons,
  Input,
  MenuItem,
  Scroll,
  Spinner,
  Text,
  color,
} from 'folds';
import i18next from 'i18next';
import {useAtom} from 'jotai';
import {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {UserAvatar} from '~/app/components/user-avatar';
import {useDebounce} from '~/app/hooks/useDebounce';
import {workerAtom} from '~/app/state/worker';
import {nameInitials} from '~/app/utils/common';
import {useDistinctProjectWorkers} from '~/queries/useProjectWorkers';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import {CompanyWorker} from '~/types/worker';
import {Pill} from '../../../atoms/pill/Pill';
import {EmptyResults, HorizontalRule} from '../CreateRoom';
import s from '../CreateRoom.module.scss';
import {EditRoomFormProps} from '../EditRoom';
import {useWorkerProfileInfo} from '../hooks/useWorkerProfileInfo';
import {CreateRoomFormProps} from '../types/types';
import {DMInfoBox} from './GroupParticipantSelection.css';

type FormProps = CreateRoomFormProps | EditRoomFormProps;

type GroupParticipantSelectionProps = {
  editing?: boolean;
  isDirectMessage?: boolean;
};

export function GroupParticipantSelection({
  editing,
  isDirectMessage,
}: GroupParticipantSelectionProps) {
  const {t} = useTranslation(['create_room']);
  const {control, watch, unregister, formState, register} = useFormContext<FormProps>();
  const {
    append: addWorker,
    fields: workerFields,
    remove: removeWorker,
  } = useFieldArray({control, name: 'selectedWorkers'});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser] = useAtom(workerAtom);

  const selectedProject = watch('selectedProject');

  const {projectWorkers: workers, projectWorkersLoading: areWorkersLoading} =
    useDistinctProjectWorkers(selectedProject?.id, debouncedSearchTerm);

  const debouncedSearch = useDebounce(
    (value: string) => {
      setDebouncedSearchTerm(value);
    },
    {wait: 500},
  );

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const input = evt.target.value;
    setSearchTerm(input);
    debouncedSearch(input);
  };

  const hasCheckedValues = (watch('selectedWorkers')?.length ?? 0) > 0;

  const emptyResultsTitle = searchTerm.length > 0 ? 'members match that search' : 'members';

  const handleWorkerSelection = useCallback(
    (worker: CompanyWorker) => {
      const index = workerFields.findIndex((field) => field.worker_id === worker.worker_id);
      if (index > -1) {
        removeWorker(index);
      } else {
        addWorker(worker);
        mixpanelService.track(MixpanelEvents.CreateGroupAddUserClick);
      }
    },
    [workerFields, addWorker, removeWorker],
  );

  useEffect(() => {
    if (hasCheckedValues || editing) {
      if ('selectedWorker' in control._fields) {
        unregister('selectedWorker');
      }
    }
  }, [hasCheckedValues, unregister, editing, control._fields]);

  const filteredWorkers = useMemo(
    () => workers.filter((worker) => worker.worker_id !== currentUser.id),
    [currentUser.id, workers],
  );

  const workerProfileInfo = useWorkerProfileInfo(filteredWorkers);

  const loading = workerProfileInfo.some(({isLoading}) => isLoading) || areWorkersLoading;

  const menuItemClass = cn({
    [s.modal__groupSelection__listItem]: true,
    [s['modal__groupSelection__listItem--submitting']]: formState.isSubmitting,
  });

  return (
    <Box direction="Column">
      <Box alignItems="Center">
        <Text>
          {editing ? t('edit_room.room_name.label') : t('group_chat.room_name.input.label')}
        </Text>
        {isDirectMessage ? (
          <Pill className={DMInfoBox} before={Icons.Info} title={t('dm_message')} />
        ) : null}
      </Box>
      <Controller
        control={control}
        name="roomName"
        render={({field}) => (
          <>
            <Input
              autoComplete="off"
              className={s.modal__groupSelection__input__name}
              maxLength={75}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  register('roomName');
                }
              }}
              placeholder={t('group_chat.room_name.input.place_holder')}
              variant={formState.errors.roomName ? 'Critical' : 'Background'}
              {...field}
            />
            {formState.errors.roomName ? (
              <Text
                className={s['modal__groupSelection__input__name--error']}
                as="label"
                htmlFor="roomName"
                size="L400"
              >
                {i18next.t(formState.errors.roomName.message)}
              </Text>
            ) : null}
          </>
        )}
      />
      <Input
        before={<Icon size="200" src={Icons.Search} />}
        disabled={formState.isSubmitting}
        className={s.modal__groupSelection__input__search}
        placeholder={t('group_chat.search.input.place_holder')}
        onChange={handleSearch}
        value={searchTerm ?? ''}
        variant="Background"
      />
      {!loading ? (
        <Scroll size="0" visibility="Hover">
          <Box gap="100" direction="Column" className={s.modal__groupSelection__listContainer}>
            <Controller
              name="selectedWorkers"
              control={control}
              render={({field: {value, ...rest}}) => (
                <>
                  {filteredWorkers.length > 0 ? (
                    filteredWorkers.map((worker, index) => {
                      const {data} = workerProfileInfo[index];
                      const {avatarUrl, displayName} = data || {};
                      const isChecked = workerFields?.some(
                        (wkr: CompanyWorker) => wkr.worker_id === worker.worker_id,
                      );
                      return (
                        <MenuItem
                          after={
                            isChecked ? (
                              <Icon src={Icons.Check} color="inherit" />
                            ) : (
                              <Icon src={Icons.Plus} />
                            )
                          }
                          before={
                            loading ? (
                              <Spinner />
                            ) : (
                              <Avatar size="400">
                                <UserAvatar
                                  alt={displayName}
                                  userId={worker?.worker_full?.ext_chat_id}
                                  src={avatarUrl}
                                  renderFallback={() => (
                                    <Text size="H4">{nameInitials(displayName)}</Text>
                                  )}
                                />
                              </Avatar>
                            )
                          }
                          className={menuItemClass}
                          key={worker.worker_id}
                          onClick={() => handleWorkerSelection(worker)}
                          radii="300"
                          variant={isChecked ? 'Success' : undefined}
                          type="button"
                          {...rest}
                        >
                          <Text>{displayName}</Text>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <EmptyResults title={emptyResultsTitle} />
                  )}
                </>
              )}
            />
          </Box>
        </Scroll>
      ) : (
        <Skeleton
          borderRadius={4}
          className={s.modal__skeleton__worker_list}
          count={4}
          height={60}
          style={{backgroundColor: color.Background.Container}}
        />
      )}
      <HorizontalRule />
      <Button
        after={formState.isSubmitting ? <Spinner /> : null}
        before={<Icon src={Icons.Plus} />}
        disabled={!hasCheckedValues || formState.isSubmitting}
        type="submit"
      >
        {formState.isSubmitting
          ? editing
            ? t('create_room:edit_room.cta.editing_room')
            : t('create_room:cta.creating_group')
          : editing
            ? t('create_room:edit_room.cta.edit_room')
            : t('create_room:cta.create_group')}
      </Button>
    </Box>
  );
}
