import * as Sentry from '@sentry/react';
import {useMutation} from '@tanstack/react-query';
import i18next from 'i18next';
import {MatrixClient} from 'matrix-js-sdk';
import {sleep} from 'matrix-js-sdk/lib/utils';
import {toast} from 'sonner';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {CreateGroupProps, chatService} from '~/services/ChatService/ChatService';
import {useRoomNavigate} from '../app/hooks/useRoomNavigate';

type CreateChatHookProps = {
  projectId: string;
  onSuccess: () => void;
  onError: (e: unknown) => void;
};

const RoomErrorClassNames = {
  RoomCreationError: 'RoomCreationError',
  RoomNavigationError: 'RoomNavigationError',
} as const;

class RoomCreationError extends Error {
  constructor(message: string) {
    super(i18next.t(message));
    this.name = RoomErrorClassNames.RoomCreationError;

    // set explicitly
    // This issue is more prominent when TypeScript code is transpiled to older versions of JavaScript.
    Object.setPrototypeOf(this, RoomCreationError.prototype);
  }
}

class RoomNavigationError extends Error {
  constructor(
    message: string,
    public roomId: string,
  ) {
    super(i18next.t(message, {roomId}));
    this.name = RoomErrorClassNames.RoomNavigationError;

    // set explicitly
    // This issue is more prominent when TypeScript code is transpiled to older versions of JavaScript.
    Object.setPrototypeOf(this, RoomNavigationError.prototype);
  }
}
/**
 * !Do not Remove
 * t('create_room:errors.room_creation.failed')
 * t('create_room:errors.room_creation.timeout')
 * t('create_room:errors.room_creation.polling_failed')
 * t('create_room:errors.room_creation.id_undefined')
 * t('create_room:errors.room_creation.unable_to_join_room')
 */
const RoomErrorMessages = {
  roomCreationError: 'create_room:errors.room_creation.failed',
  roomCreationTimeoutError: 'create_room:errors.room_creation.timeout',
  roomCreationPollingError: 'create_room:errors.room_creation.polling_failed',
  roomIdUndefinedError: 'create_room:errors.room_creation.id_undefined',
  roomJoinError: 'create_room:errors.room_creation.unable_to_join_room',
} as const;

/**
 * !Do not Remove
 * t('create_room:toasts.errors.room_id_undefined')
 * t('create_room:toasts.errors.room_creation')
 * t('create_room:toasts.errors.room_navigation')
 * t('create_room:toasts.errors.unexpected')
 */
const ToastErrorMessages = {
  roomIdUndefinedError: 'create_room:toasts.errors.room_id_undefined',
  roomCreationError: (message: string) =>
    i18next.t('create_room:toasts.errors.room_creation', {message}),
  roomNavigationError: (roomId: string) =>
    i18next.t('create_room:toasts.errors.room_navigation', {roomId}),
  unexpectedError: 'create_room:toasts.errors.unexpected',
} as const;

const createRoom = async (
  params: CreateGroupProps,
  matrixClient: MatrixClient,
  navigateRoom: (roomId: string) => void,
) => {
  let createResponse;

  try {
    createResponse = await chatService.createRoom(params);
  } catch (error) {
    throw new RoomCreationError(RoomErrorMessages.roomCreationError);
  }

  let groupResponse;
  try {
    groupResponse = await chatService.pollCreatedRoomForUser(createResponse.id);
  } catch (error) {
    throw new RoomCreationError(RoomErrorMessages.roomCreationTimeoutError);
  }

  if (!groupResponse) {
    throw new RoomCreationError(RoomErrorMessages.roomCreationPollingError);
  }

  const roomId = groupResponse.ext_chat_id;

  if (roomId === undefined) {
    throw new RoomCreationError(RoomErrorMessages.roomIdUndefinedError);
  }

  try {
    // Wait for invitation using polling
    await waitForInvitation(matrixClient, roomId);

    // Now try to join the room
    await matrixClient.joinRoom(roomId);

    // Force sync after joining
    matrixClient.retryImmediately();

    // Navigate after successful join
    navigateRoom(roomId);
  } catch (error) {
    throw new RoomCreationError(RoomErrorMessages.roomJoinError);
  }
};

const waitForInvitation = async (
  matrixClient: MatrixClient,
  roomId: string,
  maxAttempts = 20,
  delayMs = 2_000,
  syncTimeout = 35_000, // Slightly longer than sync cycle (30s)
): Promise<void> => {
  let attempts = 0;
  const startTime = Date.now();

  Sentry.addBreadcrumb({
    category: 'room',
    message: 'Starting invitation wait',
    level: 'info',
    data: {roomId, maxAttempts, delayMs, syncTimeout},
  });

  /**
   * Checks if the user has been invited to or is already a member of the room
   * @returns boolean - True if user is invited or joined, false otherwise
   */
  const checkInvitation = () => {
    // Try to get the room from the client
    const room = matrixClient.getRoom(roomId);
    if (room) {
      // If room exists, check user's membership status
      const membership = room.getMyMembership();
      const isInvitedOrJoined = membership === 'invite' || membership === 'join';

      Sentry.addBreadcrumb({
        category: 'room',
        message: 'Checked room membership',
        level: 'debug',
        data: {roomId, membership, hasAccess: isInvitedOrJoined},
      });

      return isInvitedOrJoined;
    }

    // If room not found, check pending invites
    const pendingInvites = matrixClient
      .getRooms()
      .filter((r) => r.roomId === roomId && r.getMyMembership() === 'invite');

    Sentry.addBreadcrumb({
      category: 'room',
      message: 'Checked pending invites',
      level: 'debug',
      data: {roomId, pendingInvitesCount: pendingInvites.length},
    });

    return pendingInvites.length > 0;
  };

  // Continue polling until max attempts reached
  while (attempts < maxAttempts) {
    Sentry.addBreadcrumb({
      category: 'room',
      message: 'Starting sync attempt',
      level: 'info',
      data: {
        roomId,
        attempt: attempts + 1,
        timeElapsed: Date.now() - startTime,
      },
    });

    try {
      // Force an immediate sync and wait for timeout
      await Promise.race([matrixClient.retryImmediately(), sleep(syncTimeout)]);
    } catch (error) {
      Sentry.captureException(error, {
        level: 'warning',
        tags: {roomId},
        extra: {
          attempt: attempts + 1,
          timeElapsed: Date.now() - startTime,
        },
      });
    }

    // Check if invitation exists after sync
    if (checkInvitation()) {
      const duration = Date.now() - startTime;

      Sentry.addBreadcrumb({
        category: 'room',
        message: 'Invitation found',
        level: 'info',
        data: {
          roomId,
          attempts: attempts + 1,
          duration,
        },
      });

      return;
    }

    // Wait before next attempt
    await sleep(delayMs);
    attempts++;
  }

  // If max attempts reached without finding invitation, throw error
  const duration = Date.now() - startTime;
  const error = new Error(
    `Timeout waiting for room invitation after ${(maxAttempts * delayMs) / 1000} seconds`,
  );

  Sentry.captureException(error, {
    level: 'error',
    tags: {roomId},
    extra: {
      attempts,
      duration,
      maxAttempts,
      delayMs,
      syncTimeout,
    },
  });

  throw error;
};

export const useCreateRoomMutation = (params: CreateChatHookProps) => {
  const {onError, onSuccess, projectId} = params;
  const {navigateRoom} = useRoomNavigate();
  const matrix = useMatrixClient();

  return useMutation({
    mutationKey: QUERY_CACHE_KEYS.createChat(projectId),
    mutationFn: async (params: CreateGroupProps) => {
      try {
        return await createRoom(params, matrix, navigateRoom);
      } catch (error) {
        Sentry.captureException(error, {
          level: 'error',
          tags: {
            feature: 'room_creation',
            projectId: params.project_id,
          },
          extra: {
            params,
            errorType: error instanceof RoomCreationError ? 'RoomCreationError' : 'Unknown',
            errorMessage: error instanceof Error ? error.message : String(error),
          },
        });

        throw error;
      }
    },
    onSuccess,
    onError: (error: unknown) => {
      if (error instanceof RoomCreationError) {
        if (error.message === RoomErrorMessages.roomIdUndefinedError) {
          toast.error(ToastErrorMessages.roomIdUndefinedError);
        } else {
          toast.error(ToastErrorMessages.roomCreationError(error.message));
        }
      } else if (error instanceof RoomNavigationError) {
        toast.warning(ToastErrorMessages.roomNavigationError(error.roomId));
        onSuccess();
      } else {
        toast.error(ToastErrorMessages.unexpectedError);
        onSuccess();
      }
      onError(error);
    },
  });
};
