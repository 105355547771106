import FocusTrap from 'focus-trap-react';
import {Scroll} from 'folds';
import React, {useEffect} from 'react';
import {drawerPanelStyles} from './Drawer.css';

interface SlidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
}

export const Drawer: React.FC<SlidePanelProps> = ({isOpen, onClose, children, size = 'medium'}) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden'; // Prevent scrolling when panel is open
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = ''; // Restore scrolling when panel is closed
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <FocusTrap
      active={isOpen}
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
      }}
    >
      <div className={drawerPanelStyles.panel({isOpen, size})}>
        <Scroll size="0" hideTrack>
          <div className={drawerPanelStyles.content}>{children}</div>
        </Scroll>
      </div>
    </FocusTrap>
  );
};
