import {atom} from 'jotai';

export type ListAction<T> =
  | {
      type: 'PUT';
      item: T | T[];
    }
  | {
      type: 'DELETE';
      item: T | T[];
    }
  | {
      type: 'RESET';
    };

export const createListAtom = <T>() => {
  const baseListAtom = atom<T[]>([]);
  return atom<T[], [ListAction<T>], undefined>(
    (get) => get(baseListAtom),
    (get, set, action) => {
      if (action.type === 'RESET') {
        set(baseListAtom, []);
        return;
      }

      const items = get(baseListAtom);

      let newItems: T[] = [];
      if ('item' in action) {
        if (Array.isArray(action.item)) {
          newItems = action.item;
        } else {
          newItems = [action.item];
        }
      }

      if (action.type === 'DELETE') {
        set(
          baseListAtom,
          items.filter((item) => !newItems.includes(item)),
        );
        return;
      }
      if (action.type === 'PUT') {
        set(baseListAtom, [...items, ...newItems]);
      }
    },
  );
};

export type TListAtom<T> = ReturnType<typeof createListAtom<T>>;
