import {ReactNode, isValidElement} from 'react';
import './Dialog.scss';

import Header, {TitleWrapper} from '../../atoms/header/Header';
import RawModal from '../../atoms/modal/RawModal';
import ScrollView from '../../atoms/scroll/ScrollView';
import Text from '../../atoms/text/Text';

interface DialogProps {
  className?: string | null;
  isOpen: boolean;
  title: ReactNode;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  contentOptions?: ReactNode;
  onRequestClose?: () => void;
  closeFromOutside?: boolean;
  children: ReactNode;
  invisibleScroll?: boolean;
}

function Dialog({
  className = null,
  isOpen,
  title,
  onAfterOpen,
  onAfterClose,
  contentOptions = null,
  onRequestClose,
  closeFromOutside = true,
  children,
  invisibleScroll = false,
}: DialogProps) {
  return (
    <RawModal
      className={`${className === null ? '' : `${className} `}dialog-modal`}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      closeFromOutside={closeFromOutside}
      size="small"
    >
      <div className="dialog">
        <div className="dialog__content">
          <Header>
            <TitleWrapper>
              {!isValidElement(title) ? (
                <Text variant="h2" weight="medium" primary>
                  {title}
                </Text>
              ) : (
                title
              )}
            </TitleWrapper>
            {contentOptions}
          </Header>
          <div className="dialog__content__wrapper">
            <ScrollView autoHide={!invisibleScroll} invisible={invisibleScroll}>
              <div className="dialog__content-container">{children}</div>
            </ScrollView>
          </div>
        </div>
      </div>
    </RawModal>
  );
}

export default Dialog;
