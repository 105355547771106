import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {projectService} from '~/services/ProjectService/ProjectService';
import {filterDuplicatedProjectWorkers} from '~/util/worker';

const ENTITY_LIMIT = 1000;
const SORT_PARAMS = {
  field: 'full_name',
  order: 'ASC',
};

export const useProjectWorkers = (projectId: string, searchParams?: string) => {
  const fetchProjectWorkers = async ({pageParam = 0}) => {
    const formattedSortFields = {};
    const workers = await projectService.getProjectWorkers({
      projectId,
      filterParams: {
        blended_status: ['active', 'invited'],
        ...(searchParams && {wildcard: searchParams.trim()}),
      },
      sortField: SORT_PARAMS.field,
      sortOrder: SORT_PARAMS.order,
      offset: pageParam,
      limit: ENTITY_LIMIT,
      ...formattedSortFields,
    });

    return {
      data: workers,
      nextCursor: workers.length === ENTITY_LIMIT ? pageParam + ENTITY_LIMIT : undefined,
    };
  };

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: QUERY_CACHE_KEYS.projectWorkers(projectId, JSON.stringify(searchParams)),
    queryFn: fetchProjectWorkers,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: !!projectId,
  });

  return {
    projectWorkers: data?.pages.flatMap((page) => page.data) ?? [],
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  };
};

export const useDistinctProjectWorkers = (projectId: string, searchParams?: string) => {
  const {projectWorkers, isLoading} = useProjectWorkers(projectId, searchParams);
  const uniqueWorkers = useMemo(() => {
    if (projectWorkers?.length) {
      return filterDuplicatedProjectWorkers(projectWorkers);
    }
    return [];
  }, [projectWorkers]);
  return {projectWorkers: uniqueWorkers, projectWorkersLoading: isLoading};
};
