import {IContent} from 'matrix-js-sdk';
import {DATA_BYCORE_ISSUE_ID, DATA_BYCORE_TASK_ID} from '~/client/state/constants';

export function extractIssueIds(content: IContent): string[] {
  // Create the regex pattern
  const issueIdRegex = new RegExp(`${DATA_BYCORE_ISSUE_ID}=['"](.*?)['"]`, 'g');

  // Find all matches
  const matches: RegExpMatchArray[] = Array.from(content.formatted_body.matchAll(issueIdRegex));

  // Extract and return the issue IDs
  return matches.map((match) => match[1]);
}

export function extractTaskIds(content: IContent): string[] {
  // Create the regex pattern
  const taskIdRegex = new RegExp(`${DATA_BYCORE_TASK_ID}=['"](.*?)['"]`, 'g');

  // Find all matches
  const matches: RegExpMatchArray[] = Array.from(content.formatted_body.matchAll(taskIdRegex));

  // Extract and return the task IDs
  return matches.map((match) => match[1]);
}
