import * as Sentry from '@sentry/react';
import {
  Outlet,
  Route,
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  redirect,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {useContext, useEffect, useRef} from 'react';
import {PageRoot} from '../components/page';
import {Room} from '../features/room';
import {ClientConfig} from '../hooks/useClientConfig';
import {MatrixClientContext} from '../hooks/useMatrixClient';
import {ScreenSize} from '../hooks/useScreenSize';
import {MobileFriendlyPageNav} from './MobileFriendly';
import {setAfterLoginRedirectPath} from './afterLoginRedirectPath';
import {AuthLayout, Login, ResetPassword} from './auth';
import {ClientBindAtoms, ClientLayout, ClientRoot} from './client';
import {ClientInitStorageAtom} from './client/ClientInitStorageAtom';
import {WelcomePage} from './client/WelcomePage';
import {Home, HomeRouteRoomProvider, HomeSearch} from './client/home';
import {getAppPathFromHref, getHomePath, getLoginPath, getOriginBaseUrl} from './pathUtils';
import {
  HOME_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  _CREATE_PATH,
  _JOIN_PATH,
  _ROOM_PATH,
  _SEARCH_PATH,
} from './paths';

export const ExternalNavigationHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const mx = useContext(MatrixClientContext);
  const hasHandledInitialNavigation = useRef(false);
  const initialUrl = useRef(window.location.href);

  useEffect(() => {
    if (mx && !hasHandledInitialNavigation.current) {
      const originBaseUrl = getOriginBaseUrl();

      const path = getAppPathFromHref(originBaseUrl, initialUrl.current);

      if (path !== location.pathname) {
        navigate(path, {replace: true});
      }
      hasHandledInitialNavigation.current = true;
    }
  }, [location, mx, navigate]);

  return <Outlet />;
};

export const createRouter = (
  isAuthenticated: boolean,
  clientConfig: ClientConfig,
  screenSize: ScreenSize,
) => {
  const {hashRouter} = clientConfig;
  const mobile = screenSize === ScreenSize.Mobile;

  const routes = createRoutesFromElements(
    <Route>
      <Route
        index
        loader={() => {
          if (isAuthenticated) return redirect(getHomePath());
          const afterLoginPath = getAppPathFromHref(getOriginBaseUrl(), window.location.href);
          if (afterLoginPath) setAfterLoginRedirectPath(afterLoginPath);
          return redirect(getLoginPath());
        }}
      />

      <Route
        loader={() => {
          if (isAuthenticated) {
            return redirect(getHomePath());
          }
          return null;
        }}
        element={<AuthLayout />}
      >
        <Route path={LOGIN_PATH} element={<Login />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
      </Route>

      <Route
        loader={() => {
          if (!isAuthenticated) {
            const afterLoginPath = getAppPathFromHref(
              getOriginBaseUrl(hashRouter),
              window.location.href,
            );
            if (afterLoginPath) setAfterLoginRedirectPath(afterLoginPath);
            return redirect(getLoginPath());
          }
          return null;
        }}
        element={
          <ClientRoot>
            <ClientInitStorageAtom>
              <ClientBindAtoms>
                <ClientLayout>
                  <ExternalNavigationHandler />
                </ClientLayout>
              </ClientBindAtoms>
            </ClientInitStorageAtom>
          </ClientRoot>
        }
      >
        <Route
          path={HOME_PATH}
          element={
            <PageRoot
              nav={
                <MobileFriendlyPageNav path={HOME_PATH}>
                  <Home />
                </MobileFriendlyPageNav>
              }
            >
              <Outlet />
            </PageRoot>
          }
        >
          {mobile ? null : <Route index element={<WelcomePage />} />}
          <Route path={_CREATE_PATH} element={<p>create</p>} />
          <Route path={_JOIN_PATH} element={<p>join</p>} />
          <Route path={_SEARCH_PATH} element={<HomeSearch />} />
          <Route
            path={_ROOM_PATH}
            element={
              <HomeRouteRoomProvider>
                <Room />
              </HomeRouteRoomProvider>
            }
          />
        </Route>
      </Route>
      <Route
        path="/*"
        loader={() => {
          if (isAuthenticated) return redirect(getHomePath());
          const afterLoginPath = getAppPathFromHref(getOriginBaseUrl(), window.location.href);
          if (afterLoginPath) setAfterLoginRedirectPath(afterLoginPath);
          return redirect(getLoginPath());
        }}
      />
    </Route>,
  );

  const wrappedCreateRouter = Sentry.wrapCreateBrowserRouter(
    hashRouter?.enabled ? createHashRouter : createBrowserRouter,
  );

  return wrappedCreateRouter(routes, {
    basename: hashRouter?.enabled ? hashRouter.basename : import.meta.env.BASE_URL,
  });
};
