import {useQuery} from '@tanstack/react-query';
import {Box, Icon, Icons, Input, MenuItem, Scroll, Text, color} from 'folds';
import {useAtom} from 'jotai';
import {ChangeEventHandler, useMemo, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {QUERY_CACHE_KEYS} from '../../../../client/state/query-keys';
import {workerService} from '../../../../services/WorkerService/WorkerService';
import {workerAtom} from '../../../state/worker';
import {EmptyResults} from '../CreateRoom';
import s from '../CreateRoom.module.scss';
import {CreateRoomFormProps} from '../types/types';

interface ProjectSelectionProps {
  renderNextStep: () => void;
}

export function ProjectSelection({renderNextStep}: ProjectSelectionProps) {
  const {t} = useTranslation(['create_room']);
  const {control} = useFormContext<CreateRoomFormProps>();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser] = useAtom(workerAtom);

  const {data: projects, isLoading: areProjectsLoading} = useQuery({
    queryKey: QUERY_CACHE_KEYS.workerProjects(currentUser.id),
    queryFn: () =>
      workerService.getAllProjectsForWorker({workerId: currentUser.id, showWorkerCount: true}),
    enabled: !!currentUser.id,
  });

  const filteredProjects = useMemo(
    () =>
      projects?.filter(
        (project) =>
          project.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          !('optimizer_project_id' in project),
      ),
    [projects, searchTerm],
  );

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const input = evt.target.value;
    setSearchTerm(input);
  };

  const emptyResultsTitle = searchTerm.length > 0 ? 'projects match that search' : 'projects';

  return (
    <>
      <Text priority="300" size="H6" className={s.modal__projectSelection__header__text}>
        {t('header.subtitle')}
      </Text>
      <Input
        before={<Icon size="200" src={Icons.Search} />}
        placeholder={t('project_search.input.place_holder')}
        onChange={handleSearch}
        className={s.modal__projectSelection__input}
        value={searchTerm}
        variant="Background"
      />
      <Scroll size="300" visibility="Hover">
        <Box gap="300" direction="Column" className={s.modal__projectSelection__listContainer}>
          {areProjectsLoading ? (
            <Skeleton
              borderRadius={0}
              className={s.modal__skeleton}
              count={6}
              height={60}
              style={{backgroundColor: color.Background.Container}}
            />
          ) : (
            <Controller
              name="selectedProject"
              control={control}
              render={({field: {onChange, value}}) => (
                <>
                  {filteredProjects ? (
                    filteredProjects
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((project) => (
                        <MenuItem
                          after={
                            project.worker_count > 0 ? (
                              <Text>
                                <Text size="C400" as="span" priority="300">
                                  {project.worker_count}
                                </Text>
                                <Text
                                  as="span"
                                  className={s.modal__projectSelection__listItem__afterText}
                                  size="C400"
                                >
                                  members
                                </Text>
                              </Text>
                            ) : null
                          }
                          className={s.modal__projectSelection__listItem}
                          key={project.id}
                          onClick={() => {
                            onChange(project);
                            renderNextStep();
                          }}
                          radii="300"
                          size="400"
                          type="submit"
                          variant={value?.id === project.id ? 'Background' : undefined}
                          value={project.id}
                        >
                          <Text>{project.name}</Text>
                        </MenuItem>
                      ))
                  ) : (
                    <EmptyResults title={emptyResultsTitle} />
                  )}
                </>
              )}
            />
          )}
        </Box>
      </Scroll>
    </>
  );
}
