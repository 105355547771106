import {Box, Text} from 'folds';
import {useTranslation} from 'react-i18next';
import * as css from './styles.css';

export function AuthFooter() {
  const {t} = useTranslation('common');
  return (
    <Box className={css.AuthFooter} justifyContent="Center" gap="400" wrap="Wrap">
      <Text as="a" href="https://crews.bycore.com/" target="_blank" rel="noreferrer" size="T300">
        ©️{new Date().getFullYear()} CrewsByCore
      </Text>
    </Box>
  );
}
