import {CompanyOrgs} from '~/types/company';
import {CompanyWorker, CompanyWorkerRequestParams} from '~/types/worker';
import {JourneyApi} from '~/util/api-helper';
import {Project} from '../../types/project';

class ProjectService {
  private static instance: ProjectService | null = null;

  public static getInstance(): ProjectService {
    if (!ProjectService.instance) {
      ProjectService.instance = new ProjectService();
    }
    return ProjectService.instance;
  }

  private getProjectsPath(projectId: string): string {
    return `projects/${projectId}`;
  }

  async getProjectWorkers({
    projectId,
    offset,
    limit,
    filterParams,
    sortOrder,
    sortField,
  }: CompanyWorkerRequestParams & {projectId: string | undefined}): Promise<CompanyWorker[]> {
    const searchParams = new URLSearchParams({
      ...(offset !== undefined && {offset: offset.toString()}),
      ...(limit !== undefined && {limit: limit.toString()}),
      ...(filterParams && {
        filter_params: JSON.stringify({
          ...filterParams,
        }),
      }),
      ...(sortOrder && {sort_order: sortOrder}),
      ...(sortField && {sort_field: sortField}),
    });

    if (projectId) {
      const response = await JourneyApi.get(`${this.getProjectsPath(projectId)}/companyworkers`, {
        searchParams,
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      return data as CompanyWorker[];
    }
    return [];
  }

  async getOrgs(projectId: string): Promise<CompanyOrgs[]> {
    const response = await JourneyApi.get(`${this.getProjectsPath(projectId)}/companyorgs`);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data: CompanyOrgs[] = await response.json();

    return data.sort((companyA, companyB) => {
      const nameA = companyA.group?.name ?? '';
      const nameB = companyB.group?.name ?? '';
      return nameA.localeCompare(nameB);
    });
  }

  async getProjectById(projectId: string) {
    const response = await JourneyApi.get(`${this.getProjectsPath(projectId)}`);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json<Project>();
  }
}

export const projectService = ProjectService.getInstance();
