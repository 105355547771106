import {initializeApp} from 'firebase/app';
import {
  type Auth,
  AuthCredential,
  type ConfirmationResult,
  EmailAuthProvider,
  type MultiFactorError,
  type MultiFactorInfo,
  PhoneAuthCredential,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  type User,
  applyActionCode,
  browserLocalPersistence,
  getAuth,
  getMultiFactorResolver,
  inMemoryPersistence,
  linkWithCredential,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from 'firebase/auth';
import {ENV} from '~/util/env';

const {
  PUBLIC_FIREBASE_API_KEY,
  PUBLIC_FIREBASE_AUTH_DOMAIN,
  PUBLIC_FIREBASE_DATABASE_URL,
  PUBLIC_FIREBASE_MEASUREMENT_ID,
  PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  PUBLIC_FIREBASE_PROJECT_ID,
  PUBLIC_FIREBASE_STORAGE_BUCKET,
} = ENV;

export interface FirebaseTokenProvider {
  getUserIdToken(): Promise<string>;
}

export const FirebaseConfig = {
  apiKey: PUBLIC_FIREBASE_API_KEY,
  authDomain: PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: PUBLIC_FIREBASE_DATABASE_URL,
  projectId: PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const LS_WORKER_ID_KEY = 'workerId';

class Firebase implements FirebaseTokenProvider {
  public auth!: Auth;

  private testPhone = '+1234567890';

  constructor(config: typeof FirebaseConfig) {
    initializeApp(config);
    this.auth = getAuth();
  }

  static get phoneMultiFactorGenerator() {
    return PhoneMultiFactorGenerator;
  }

  toggleRecapchaAutoVerificationForTesting(phone?: string) {
    const phoneWithoutFormatting = phone ? phone.replace(/[()\s-]/g, '') : phone;
    this.auth.settings.appVerificationDisabledForTesting =
      phoneWithoutFormatting && this.testPhone
        ? phoneWithoutFormatting === this.testPhone ||
          `+1${phoneWithoutFormatting}` === this.testPhone
        : false;
  }

  initRecaptchaVerifier = (container: string, recaptchaSize: string) =>
    new RecaptchaVerifier(this.auth, container, {size: recaptchaSize});

  signInWithPhone = (phone: string, recaptchaVerifier: RecaptchaVerifier) =>
    signInWithPhoneNumber(this.auth, phone, recaptchaVerifier);

  signInWithCredential = async (credential: AuthCredential) =>
    signInWithCredential(this.auth, credential);

  signInWithCustomToken = async (token: string) => {
    await setPersistence(this.auth, inMemoryPersistence);
    return signInWithCustomToken(this.auth, token);
  };

  turnOnPersistence = async () => {
    await setPersistence(this.auth, browserLocalPersistence);
  };

  confirmSignInWithMobile = async (confirmation: string, confirmationResult: ConfirmationResult) =>
    confirmationResult.confirm(confirmation);

  signInAnonymously = async () => signInAnonymously(this.auth);

  linkWithPhoneCredential = async (confirmResult: ConfirmationResult, verificationCode: string) => {
    const credential: PhoneAuthCredential = PhoneAuthProvider.credential(
      confirmResult.verificationId,
      verificationCode,
    );

    return linkWithCredential(this.auth.currentUser as User, credential)
      .then((usercred) => usercred.user)
      .catch((error) => Promise.reject(error));
  };

  createUserWithEmailAndPassword = async (email: string, password: string) => {
    const credential = EmailAuthProvider.credential(email, password);

    return linkWithCredential(this.auth.currentUser as User, credential)
      .then((usercred) => usercred.user)
      .catch((error) => Promise.reject(error));
  };

  signInWithEmailAndPassword = async (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password);

  getUserIdToken = async () => {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdToken();
    }
    throw new Error('No current user found');
  };

  getCurrentUser = async () => this.auth.currentUser;

  signOut = async () => {
    localStorage.removeItem(LS_WORKER_ID_KEY);
    await this.auth.signOut();
    return true;
  };

  setWorkerIdToLS(workerId: string) {
    localStorage.setItem(LS_WORKER_ID_KEY, workerId);
  }

  getWorkerIdFromLS() {
    return localStorage.getItem(LS_WORKER_ID_KEY);
  }

  removeWorkerIdFromLS() {
    localStorage.removeItem(LS_WORKER_ID_KEY);
  }

  deleteUser = async () => {
    if (this.auth.currentUser) {
      return this.auth.currentUser.delete();
    }
    return null;
  };

  sendPasswordResetEmail = async (emailAddress: string) =>
    sendPasswordResetEmail(this.auth, emailAddress);

  sendVerificationEmailLink = (): Promise<void> =>
    sendEmailVerification(this.auth.currentUser as User);

  applyActionCode = async (oobCode: string) => applyActionCode(this.auth, oobCode);

  reauthenticateWithCredential = async (credentials: AuthCredential) =>
    reauthenticateWithCredential(this.auth.currentUser as User, credentials);

  getPhoneAuthProvider = () => new PhoneAuthProvider(this.auth);

  getMultiFactorResolverByError = (error: MultiFactorError) =>
    getMultiFactorResolver(this.auth, error);

  getPhoneMultiFactorResolver = (hints: MultiFactorInfo[]): MultiFactorInfo => {
    const resolver = hints.find(({factorId}) => factorId === PhoneMultiFactorGenerator.FACTOR_ID);
    if (!resolver) {
      throw new Error('No matching MultiFactorInfo found');
    }
    return resolver;
  };
}

export const firebaseService = new Firebase(FirebaseConfig);
