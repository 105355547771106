import {as, toRem} from 'folds';
import {randomNumberBetween} from '../../../utils/common';
import {CompactLayout, MessageBase} from '../layout';
import {LinePlaceholder} from './LinePlaceholder';

export const CompactPlaceholder = as<'div'>(({...props}, ref) => (
  <MessageBase>
    <CompactLayout
      {...props}
      ref={ref}
      before={
        <>
          <LinePlaceholder style={{maxWidth: toRem(50)}} />
          <LinePlaceholder style={{maxWidth: toRem(randomNumberBetween(40, 100))}} />
        </>
      }
    >
      <LinePlaceholder style={{maxWidth: toRem(randomNumberBetween(120, 500))}} />
    </CompactLayout>
  </MessageBase>
));
