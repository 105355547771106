import CreateRoom from '~/app/organisms/create-room/CreateRoom';
import SpaceAddExisting from '../../molecules/space-add-existing/SpaceAddExisting';
import EmojiVerification from '../emoji-verification/EmojiVerification';
import JoinAlias from '../join-alias/JoinAlias';
import ProfileViewer from '../profile-viewer/ProfileViewer';
import Search from '../search/Search';

import ReusableDialog from '../../molecules/dialog/ReusableDialog';
import {EditRoom} from '../create-room/EditRoom';

function Dialogs() {
  return (
    <>
      <ProfileViewer />
      <CreateRoom />
      <EditRoom />
      <JoinAlias />
      <SpaceAddExisting />
      <Search />
      <EmojiVerification />
      <ReusableDialog />
    </>
  );
}

export default Dialogs;
