import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {useTranslation} from 'react-i18next';
import {toast} from 'sonner';
import {useMatrixClient} from '../app/hooks/useMatrixClient';
import {workerAtom} from '../app/state/worker';
import {QUERY_CACHE_KEYS} from '../client/state/query-keys';
import {CreateGroupProps, Member, chatService} from '../services/ChatService/ChatService';

type UpdateGroupMembersParams = CreateGroupProps & {
  group_id: string;
  membersToRemove: Member[];
  membersToAdd: Member[];
};

const updateRoom = async (params: UpdateGroupMembersParams) => {
  const {group_id, membersToRemove, membersToAdd, ...updates} = params;
  if (membersToRemove.length > 0) {
    await chatService.removeMembersFromGroup({group_id, membersToRemove});
  }
  if (membersToAdd.length > 0) {
    await chatService.addMembersToGroup({group_id, membersToAdd});
  }
  return await chatService.updateGroupById({group_id, ...updates});
};

export function useEditRoomMutation(roomId: string) {
  const {t} = useTranslation('create_room');
  const queryClient = useQueryClient();
  const [currentUser] = useAtom(workerAtom);
  const mx = useMatrixClient();
  return useMutation({
    mutationFn: updateRoom,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [...QUERY_CACHE_KEYS.workerChats(currentUser.id), roomId],
      });
      toast.success(t('edit_room.modal.toast.success', {name: data.name}));
    },
    onError: () => {
      toast.error(t('edit_room.modal.toast.error'));
    },
  });
}
