import {
  Box,
  Checkbox,
  Dialog,
  Icon,
  IconButton,
  Icons,
  Line,
  Overlay,
  OverlayBackdrop,
  OverlayCenter,
  PopOut,
  Scroll,
  Spinner,
  Text,
  config,
  toRem,
} from 'folds';
import parse from 'html-react-parser';
import {isKeyHotkey} from 'is-hotkey';
import {useAtom, useAtomValue} from 'jotai';
import {EventType, IContent, MsgType, Room} from 'matrix-js-sdk';
import {
  KeyboardEventHandler,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Editor, Element as SlateElement, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';
import {CustomIcon} from '~/app/atoms/system-icons/CustomIcon';
import {CustomIcons} from '~/app/atoms/system-icons/CustomIconsStore';
import {UseStateProvider} from '~/app/components/UseStateProvider';
import {
  AUTOCOMPLETE_PREFIXES,
  AutocompletePrefix,
  AutocompleteQuery,
  BlockType,
  CustomEditor,
  EmoticonAutocomplete,
  TaskMentionAutocomplete,
  Toolbar,
  UserMentionAutocomplete,
  createEmoticonElement,
  customHtmlEqualsPlainText,
  getAutocompleteQuery,
  getBeginCommand,
  getPrevWorldRange,
  isEmptyEditor,
  moveCursor,
  resetEditor,
  resetEditorHistory,
  toMatrixCustomHTML,
  toPlainText,
  trimCommand,
  trimCustomHtml,
} from '~/app/components/editor';
import {IssueMentionAutocomplete} from '~/app/components/editor/autocomplete/IssuMentionAutocomplete';
import {EmojiBoard, EmojiBoardTab} from '~/app/components/emoji-board';
import {ReplyLayout} from '~/app/components/message';
import {UploadBoard, UploadBoardContent, UploadBoardHeader} from '~/app/components/upload-board';
import {UploadCardRenderer} from '~/app/components/upload-card';
import {Command, SHRUG} from '~/app/hooks/useCommands';
import {useElementSizeObserver} from '~/app/hooks/useElementSizeObserver';
import {useFileDropZone} from '~/app/hooks/useFileDrop';
import {useFilePasteHandler} from '~/app/hooks/useFilePasteHandler';
import {useFilePicker} from '~/app/hooks/useFilePicker';
import {useIssueById} from '~/app/hooks/useGetIssueById';
import {useTaskById} from '~/app/hooks/useGetTaskById';
import {Actions, useActionHandler} from '~/app/hooks/useHandleAction';
import {useHandleFiles} from '~/app/hooks/useHandleFiles';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {useOutlineSortKeyMap} from '~/app/hooks/useOutlineSortkey';
import {usePrefixQuery} from '~/app/hooks/usePrefixQuery';
import {useSendUpload} from '~/app/hooks/useSendUpload';
import {useTypingStatusUpdater} from '~/app/hooks/useTypingStatusUpdater';
import {UploadBoardImperativeHandlers} from '~/app/hooks/useUploadSendHandler';
import {formatMessageContent} from '~/app/organisms/create-issue/helper';
import {getReactCustomHtmlParser} from '~/app/plugins/react-custom-html-parser';
import {useSetting} from '~/app/state/hooks/settings';
import {hasMentionAtom, isMentionPublicAtom} from '~/app/state/mention';
import {
  roomIdToMsgDraftAtomFamily,
  roomIdToReplyDraftAtomFamily,
  roomIdToUploadItemsAtomFamily,
  roomUploadAtomFamily,
} from '~/app/state/room/roomInputDrafts';
import {roomToParentsAtom} from '~/app/state/room/roomToParents';
import {settingsAtom} from '~/app/state/settings';
import {
  Upload,
  UploadStatus,
  createUploadFamilyObserverAtom,
  uploadObserverStatus,
} from '~/app/state/upload';
import {handleDataBycoreContent} from '~/app/utils/customEvent';
import {getImageUrlBlob, loadImageElement} from '~/app/utils/dom';
import {sentryLog} from '~/app/utils/logger';
import {TUploadContent, getImageInfo, getMxIdLocalPart} from '~/app/utils/matrix';
import {
  extractHrefIfAnchor,
  getAllParents,
  getMemberDisplayName,
  getReplyBody,
  parseReplyBody,
  parseReplyFormattedBody,
  trimReplyFromBody,
} from '~/app/utils/room';
import {mobileOrTablet} from '~/app/utils/user-agent';
import {DATA_BYCORE_ISSUE_ID, DATA_BYCORE_TASK_ID} from '~/client/state/constants';
import {useTasksByProject} from '~/queries/useTasksByProject';
import {useProjectAndGroupIdFromWorkerChats} from '~/queries/useWorkerChats';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {MixpanelEvents} from '~/shared/mixpanelEvents';
import colorMXID from '~/util/colorMXID';
import * as css from './RoomInput.css';

interface RoomInputProps {
  editor: Editor;
  fileDropContainerRef: RefObject<HTMLElement>;
  roomId: string;
  room: Room;
}

export const RoomInput = forwardRef<HTMLDivElement, RoomInputProps>(
  ({editor, fileDropContainerRef, roomId, room}, ref) => {
    const {t} = useTranslation(['common', 'editor']);
    const mx = useMatrixClient();
    const [enterForNewline] = useSetting(settingsAtom, 'enterForNewline');
    const [isMarkdown] = useSetting(settingsAtom, 'isMarkdown');
    const emojiBtnRef = useRef<HTMLButtonElement>(null);
    const roomToParents = useAtomValue(roomToParentsAtom);
    const [isMentionPublic, setIsMentionPublic] = useAtom(isMentionPublicAtom);
    const [hasMention, setHasMention] = useAtom(hasMentionAtom);
    const [msgDraft, setMsgDraft] = useAtom(roomIdToMsgDraftAtomFamily(roomId));
    const [replyDraft, setReplyDraft] = useAtom(roomIdToReplyDraftAtomFamily(roomId));
    const [uploadBoard, setUploadBoard] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFiles, setSelectedFiles] = useAtom(roomIdToUploadItemsAtomFamily(roomId));
    const {data: chatData} = useProjectAndGroupIdFromWorkerChats(room.roomId ?? '');
    const [autocompleteQuery, setAutocompleteQuery] =
      useState<AutocompleteQuery<AutocompletePrefix>>();
    const getIssueById = useIssueById({
      projectId: chatData?.projectId,
    });
    const getTaskById = useTaskById({
      projectId: chatData?.projectId,
    });
    const {data: tasksList} = useTasksByProject(chatData?.projectId ?? '');
    const {oskMap} = useOutlineSortKeyMap(tasksList);
    room.loadMembersIfNeeded();

    const uploadFamilyObserverAtom = createUploadFamilyObserverAtom(
      roomUploadAtomFamily,
      selectedFiles.map((f) => f.file),
    );

    const [isAnyUploading] = useAtom(uploadObserverStatus);

    const uploadBoardHandlers = useRef<UploadBoardImperativeHandlers>();

    const imagePackRooms: Room[] = useMemo(() => {
      const allParentSpaces = [roomId].concat(Array.from(getAllParents(roomToParents, roomId)));
      return allParentSpaces.reduce<Room[]>((list, rId) => {
        const r = mx.getRoom(rId);
        if (r) list.push(r);
        return list;
      }, []);
    }, [mx, roomId, roomToParents]);

    const [toolbar] = useSetting(settingsAtom, 'editorToolbar');

    const sendTypingStatus = useTypingStatusUpdater(mx, roomId);

    const handleFiles = useHandleFiles({
      roomId,
      setUploadBoard,
      setSelectedFiles,
    });

    const {handleSendUpload, processUploadResult} = useSendUpload({
      mx,
      roomId,
      editor,
      selectedFiles,
      setSelectedFiles,
      uploadBoardHandlers,
    });

    const pickFile = useFilePicker(handleFiles, true);
    const handlePaste = useFilePasteHandler(handleFiles);
    const dropZoneVisible = useFileDropZone(fileDropContainerRef, handleFiles);
    const [hideStickerBtn, setHideStickerBtn] = useState(true);

    useElementSizeObserver(
      useCallback(() => document.body, []),
      useCallback((_width) => setHideStickerBtn(true), []),
    );

    useEffect(() => {
      Transforms.insertFragment(editor, msgDraft);
    }, [editor, msgDraft]);

    useEffect(() => {
      if (!mobileOrTablet()) ReactEditor.focus(editor);
      return () => {
        if (!isEmptyEditor(editor)) {
          const parsedDraft = JSON.parse(JSON.stringify(editor.children));
          setMsgDraft(parsedDraft);
        } else {
          setMsgDraft([]);
        }
        resetEditor(editor);
        resetEditorHistory(editor);
      };
    }, [roomId, editor, setMsgDraft]);

    const handleRemoveUpload = useCallback(
      (upload: TUploadContent | TUploadContent[]) => {
        const uploads = Array.isArray(upload) ? upload : [upload];
        setSelectedFiles({
          type: 'DELETE',
          item: selectedFiles.filter((f) => uploads.find((u) => u === f.file)),
        });
        uploads.forEach((u) => roomUploadAtomFamily.remove(u));
      },
      [setSelectedFiles, selectedFiles],
    );

    const handleCancelUpload = (uploads: Upload[]) => {
      uploads.forEach((upload) => {
        if (upload.status === UploadStatus.Loading) {
          mx.cancelUpload(upload.promise);
        }
      });
      handleRemoveUpload(uploads.map((upload) => upload.file));
    };

    function resetEditorState() {
      resetEditor(editor);
      resetEditorHistory(editor);
      setReplyDraft(undefined);
      sendTypingStatus(false);
      setAutocompleteQuery(undefined);
      setIsMentionPublic(true);

      checkForMentions();
      // Clear selectedFiles and attachments after sending
      setSelectedFiles({type: 'RESET'});
    }

    const submit = useCallback(async () => {
      if (isSubmitting) {
        return;
      }
      try {
        setIsSubmitting(true);
        let plainText = toPlainText(editor.children).trim();

        if (plainText === '') {
          if (selectedFiles.length > 0) {
            uploadBoardHandlers.current?.handleSend();
          }
          return;
        }

        // From here on, we're handling text messages (with or without attachments)
        const commandName = getBeginCommand(editor);

        let customHtml = trimCustomHtml(
          toMatrixCustomHTML(editor.children, {
            allowTextFormatting: true,
            allowBlockMarkdown: isMarkdown,
            allowInlineMarkdown: isMarkdown,
          }),
        );
        let msgType = MsgType.Text;

        if (commandName) {
          plainText = trimCommand(commandName, plainText);
          customHtml = trimCommand(commandName, customHtml);
        }
        if (commandName === Command.Me) {
          msgType = MsgType.Emote;
        } else if (commandName === Command.Notice) {
          msgType = MsgType.Notice;
        } else if (commandName === Command.Shrug) {
          plainText = `${SHRUG} ${plainText}`;
          customHtml = `${SHRUG} ${customHtml}`;
        }

        let body = plainText;
        let formattedBody = customHtml;

        const content: IContent = {
          msgtype: msgType,
          body,
        };

        if (replyDraft) {
          const {isReplyToReply, replyBody} = getReplyBody(replyDraft);

          let currentReplyBody: string;

          if (isReplyToReply) {
            currentReplyBody = replyBody;
          } else {
            currentReplyBody = replyBody;
          }

          body = parseReplyBody(replyDraft.userId, currentReplyBody) + body;
          formattedBody =
            parseReplyFormattedBody({
              roomId,
              userId: replyDraft.userId,
              eventId: replyDraft.eventId,
              replyBody,
            }) + formattedBody;

          content['m.relates_to'] = {
            'm.in_reply_to': {
              event_id: replyDraft.eventId,
            },
          };
        }

        const sendResult = await uploadBoardHandlers.current?.handleSend();

        content.body = formatMessageContent(body, mx);

        const hasAttachments = sendResult?.length;

        const attachments = hasAttachments ? await processUploadResult() : [];

        content.attachments = attachments;

        if (replyDraft || !customHtmlEqualsPlainText(formattedBody, body)) {
          content.format = 'org.matrix.custom.html';
          content.formatted_body = formatMessageContent(formattedBody, mx);
        }

        const hasDataBycoreIssueId = content.formatted_body?.includes(DATA_BYCORE_ISSUE_ID);
        const hasDataBycoreTaskId = content.formatted_body?.includes(DATA_BYCORE_TASK_ID);

        if (hasDataBycoreIssueId || hasDataBycoreTaskId) {
          try {
            await handleDataBycoreContent({
              content,
              roomId,
              mx,
              oskMap,
              getIssueById,
              getTaskById,
              isMentionPublic,
            });
            // Early return to prevent sending a duplicate message
            resetEditorState();
            return;
          } catch (e) {
            setIsSubmitting(false);
            sentryLog.error('Failed to send data bycore content', e, {
              tags: {
                name: 'RoomInput.submit',
              },
            });
          } finally {
            setIsSubmitting(false);
          }
        }
        await mx.sendMessage(roomId, content);
        resetEditorState();
      } catch (e) {
        sentryLog.error('Failed to send room content', e, {
          tags: {
            name: 'RoomInput.submit',
          },
        });
      } finally {
        setIsSubmitting(false);
        mixpanelService.track(MixpanelEvents.MessageBoxSendClick);
      }
    }, [
      editor,
      getIssueById,
      getTaskById,
      isMarkdown,
      isMentionPublic,
      isSubmitting,
      mx,
      oskMap,
      replyDraft,
      roomId,
      selectedFiles.length,
    ]);

    const handleKeyDown: KeyboardEventHandler = useCallback(
      (evt) => {
        if (isKeyHotkey('mod+enter', evt) || (!enterForNewline && isKeyHotkey('enter', evt))) {
          evt.preventDefault();
          submit();
        }
        if (isKeyHotkey('escape', evt)) {
          evt.preventDefault();
          setReplyDraft(undefined);
        }
      },
      [submit, setReplyDraft, enterForNewline],
    );

    const checkForMentions = useCallback(() => {
      let roomMentions = 0;
      let issueMentions = 0;

      // Traverse all nodes in the editor
      for (const [node] of Editor.nodes(editor, {
        at: [],
        match: (n) =>
          SlateElement.isElement(n) && (n.type === BlockType.Issue || n.type === BlockType.Task),
      })) {
        if (SlateElement.isElement(node)) {
          if (node.type === BlockType.Issue) {
            issueMentions++;
          } else if (node.type === BlockType.Task) {
            roomMentions++;
          }
        }
      }

      const hasMentions = roomMentions > 0 || issueMentions > 0;
      setHasMention(hasMentions);
    }, [editor, setHasMention]);

    const handleKeyUp: KeyboardEventHandler = useCallback(
      (evt) => {
        if (isKeyHotkey('escape', evt)) {
          evt.preventDefault();
          return;
        }

        sendTypingStatus(!isEmptyEditor(editor));

        const prevWordRange = getPrevWorldRange(editor);
        const query = prevWordRange
          ? getAutocompleteQuery<AutocompletePrefix>(editor, prevWordRange, AUTOCOMPLETE_PREFIXES)
          : undefined;
        checkForMentions();
        setAutocompleteQuery(query);
      },
      [editor, sendTypingStatus],
    );

    const handleCloseAutocomplete = useCallback(() => {
      setAutocompleteQuery(undefined);
      ReactEditor.focus(editor);
    }, [editor]);

    const handleEmoticonSelect = (key: string, shortcode: string) => {
      editor.insertNode(createEmoticonElement(key, shortcode));
      moveCursor(editor);
    };

    const handleStickerSelect = async (mxc: string, shortcode: string, label: string) => {
      const stickerUrl = mx.mxcUrlToHttp(mxc);
      if (!stickerUrl) return;

      const info = await getImageInfo(
        await loadImageElement(stickerUrl),
        await getImageUrlBlob(stickerUrl),
      );

      mx.sendEvent(roomId, EventType.Sticker, {
        body: label,
        url: mxc,
        info,
      });
    };

    const handlePrefixQuery = usePrefixQuery({editor, handleKeyUp});

    const {isReplyToReply, replyBody} = getReplyBody(replyDraft);
    const bodyToRender = isReplyToReply ? replyBody : replyDraft?.formattedBody || '';
    const trimmedBody = extractHrefIfAnchor(trimReplyFromBody(bodyToRender));
    const parserOptions = getReactCustomHtmlParser(mx, room, {});
    const parsedContent = parse(bodyToRender ?? '', parserOptions);

    function isParsedContentEmpty(content: string | JSX.Element | JSX.Element[]): boolean {
      if (Array.isArray(content)) {
        return content.length === 0;
      }
      return false;
    }

    const actions: Actions = {
      image: {
        action: () => pickFile('image/jpeg,image/png,image/jpg'),
        event: MixpanelEvents.MessageBoxPhotoButtonClick,
      },
      userMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.UserMention),
        event: MixpanelEvents.MessageBoxMentionUnselectClick,
      },
      activityMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.TaskMention),
        event: MixpanelEvents.MessageBoxActivityMentionClick,
      },
      issueMention: {
        action: () => handlePrefixQuery(AutocompletePrefix.Issue),
        event: MixpanelEvents.MessageBoxIssueMentionClick,
      },
    };

    const handleAction = useActionHandler({
      actions,
      dependencies: [pickFile, handlePrefixQuery],
    });

    return (
      <div ref={ref} className={css.EditorContainer}>
        {selectedFiles.length > 0 && (
          <UploadBoard
            header={
              <UploadBoardHeader
                open={uploadBoard}
                onToggle={() => setUploadBoard(!uploadBoard)}
                uploadFamilyObserverAtom={uploadFamilyObserverAtom}
                onSend={handleSendUpload}
                imperativeHandlerRef={uploadBoardHandlers}
                onCancel={handleCancelUpload}
              />
            }
          >
            {uploadBoard && (
              <Scroll size="300" hideTrack visibility="Hover">
                <UploadBoardContent>
                  {Array.from(selectedFiles)
                    .reverse()
                    .map((fileItem, index) => (
                      <UploadCardRenderer
                        // biome-ignore lint/suspicious/noArrayIndexKey:
                        key={(fileItem.file as File).name + index}
                        file={fileItem.file}
                        isEncrypted={!!fileItem.encInfo}
                        uploadAtom={roomUploadAtomFamily(fileItem.file)}
                        onRemove={handleRemoveUpload}
                      />
                    ))}
                </UploadBoardContent>
              </Scroll>
            )}
          </UploadBoard>
        )}
        <Overlay
          open={dropZoneVisible}
          backdrop={<OverlayBackdrop />}
          style={{pointerEvents: 'none'}}
        >
          <OverlayCenter>
            <Dialog variant="Primary">
              <Box
                direction="Column"
                justifyContent="Center"
                alignItems="Center"
                gap="500"
                style={{padding: toRem(60)}}
              >
                <Icon size="600" src={Icons.File} />
                <Text size="H4" align="Center">
                  {`Drop Files in "${room?.name || 'Room'}"`}
                </Text>
                <Text align="Center">{t('editor:images.drag_and_drop_message')}</Text>
              </Box>
            </Dialog>
          </OverlayCenter>
        </Overlay>
        {autocompleteQuery?.prefix === AutocompletePrefix.UserMention ? (
          <UserMentionAutocomplete
            room={room}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.TaskMention ? (
          <TaskMentionAutocomplete
            roomId={room.roomId}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.Emoticon ? (
          <EmoticonAutocomplete
            imagePackRooms={imagePackRooms}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        {autocompleteQuery?.prefix === AutocompletePrefix.Issue ? (
          <IssueMentionAutocomplete
            room={room}
            editor={editor}
            query={autocompleteQuery}
            requestClose={handleCloseAutocomplete}
          />
        ) : null}
        <CustomEditor
          editableName="RoomInput"
          editor={editor}
          placeholder={t('editor:input.placeholder')}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onPaste={handlePaste}
          top={
            <>
              {replyDraft ? (
                <div>
                  <Box
                    alignItems="Center"
                    gap="300"
                    style={{padding: `${config.space.S200} ${config.space.S300} 0`}}
                  >
                    <IconButton
                      onClick={() => setReplyDraft(undefined)}
                      variant="SurfaceVariant"
                      size="300"
                      radii="300"
                    >
                      <Icon src={Icons.Cross} size="50" />
                    </IconButton>
                    <ReplyLayout
                      userColor={colorMXID(replyDraft.userId)}
                      username={
                        <Text size="T300" truncate>
                          <b>
                            {getMemberDisplayName(room, replyDraft.userId) ??
                              getMxIdLocalPart(replyDraft.userId) ??
                              replyDraft.userId}
                          </b>
                        </Text>
                      }
                    >
                      <Text size="T300" truncate>
                        {parsedContent}
                        {isParsedContentEmpty(parsedContent) ? trimmedBody : null}
                      </Text>
                    </ReplyLayout>
                  </Box>
                </div>
              ) : null}

              {hasMention ? (
                <Box
                  alignItems="Center"
                  gap="300"
                  style={{padding: `${config.space.S200} ${config.space.S300} 0`}}
                >
                  <label
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Checkbox
                      variant="Primary"
                      size="50"
                      checked={isMentionPublic}
                      // @ts-expect-error: types are incorrect
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        mixpanelService.trackWithAction(
                          () => setIsMentionPublic(newValue),
                          MixpanelEvents.MessageBoxMentionUnselectClick,
                        );
                      }}
                    />
                    <Text
                      size="T300"
                      truncate
                      style={{
                        marginLeft: '8px',
                        userSelect: 'none',
                      }}
                    >
                      {t('common:mention.message')}
                    </Text>
                  </label>
                </Box>
              ) : null}
            </>
          }
          before={
            <>
              <IconButton
                onClick={() => handleAction('image')}
                variant="SurfaceVariant"
                size="300"
                radii="300"
              >
                <Icon src={Icons.Plus} />
              </IconButton>
              <IconButton
                variant="SurfaceVariant"
                size="300"
                radii="300"
                onClick={() => handleAction('userMention')}
              >
                <Icon src={Icons.User} />
              </IconButton>
              <IconButton
                variant="SurfaceVariant"
                size="300"
                radii="300"
                onClick={() => handleAction('activityMention')}
              >
                <CustomIcon src={CustomIcons.Clipboard} />
              </IconButton>
              <IconButton
                variant="SurfaceVariant"
                size="300"
                radii="300"
                onClick={() => handleAction('issueMention')}
              >
                <CustomIcon src={CustomIcons.Warning} />
              </IconButton>
              <UseStateProvider initial={undefined}>
                {(emojiBoardTab: EmojiBoardTab | undefined, setEmojiBoardTab) => (
                  <PopOut
                    offset={16}
                    alignOffset={-44}
                    position="Top"
                    align="End"
                    anchor={
                      emojiBoardTab === undefined
                        ? undefined
                        : (emojiBtnRef.current?.getBoundingClientRect() ?? undefined)
                    }
                    content={
                      <EmojiBoard
                        tab={emojiBoardTab}
                        onTabChange={setEmojiBoardTab}
                        imagePackRooms={imagePackRooms}
                        returnFocusOnDeactivate={false}
                        onEmojiSelect={handleEmoticonSelect}
                        onCustomEmojiSelect={handleEmoticonSelect}
                        onStickerSelect={handleStickerSelect}
                        requestClose={() => {
                          setEmojiBoardTab(undefined);
                          if (!mobileOrTablet()) ReactEditor.focus(editor);
                        }}
                      />
                    }
                  >
                    {!hideStickerBtn && (
                      <IconButton
                        aria-pressed={emojiBoardTab === EmojiBoardTab.Sticker}
                        onClick={() => setEmojiBoardTab(EmojiBoardTab.Sticker)}
                        variant="SurfaceVariant"
                        size="300"
                        radii="300"
                      >
                        <Icon
                          src={Icons.Sticker}
                          filled={emojiBoardTab === EmojiBoardTab.Sticker}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      ref={emojiBtnRef}
                      aria-pressed={
                        hideStickerBtn ? !!emojiBoardTab : emojiBoardTab === EmojiBoardTab.Emoji
                      }
                      onClick={() => {
                        mixpanelService.trackWithAction(
                          () => setEmojiBoardTab(EmojiBoardTab.Emoji),
                          MixpanelEvents.MessageBoxEmojiClick,
                        );
                      }}
                      variant="SurfaceVariant"
                      size="300"
                      radii="300"
                    >
                      <Icon
                        src={Icons.Smile}
                        filled={
                          hideStickerBtn ? !!emojiBoardTab : emojiBoardTab === EmojiBoardTab.Emoji
                        }
                      />
                    </IconButton>
                  </PopOut>
                )}
              </UseStateProvider>
            </>
          }
          after={
            <IconButton
              disabled={isSubmitting || isAnyUploading}
              onClick={submit}
              variant="Surface"
              className={css.IconButtonOverride}
              fill="Soft"
              size="300"
              radii="300"
            >
              <Text size="T400" className={css.IconButtonTextOverride}>
                {t('common:cta.send')}
              </Text>
              {isSubmitting ? (
                <Spinner size="100" />
              ) : (
                <Icon src={Icons.Send} size="100" filled className={css.IconRotate} />
              )}
            </IconButton>
          }
          bottom={
            toolbar && (
              <div>
                <Line variant="SurfaceVariant" size="300" />
                <Toolbar />
              </div>
            )
          }
        />
      </div>
    );
  },
);
