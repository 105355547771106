import "src/app/components/emoji-board/EmojiBoard.css.tsx.vanilla.css!=!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/components/emoji-board/EmojiBoard.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62U0W6jMBBF3/MV87JSWcnIQAMp+ZjKYAOzJXbWGEK72n9fxVDXpAnNSn2Embl35szI4RAXWI0U/mwADmwkJ+SmySHOtDjsNwDzd8na8iGidDgBgRh+wsD0AyHPUfo4VrWI+iA4ZzcC68bkEO/CKN7OEgUrX2qteslJqVql87lY/U45zba28ErgyQYKpblwkXe/eBtAp1rki4qdV0E049h3nwrpnDOSrmFcnT4l1DZBDUJX7TncIOdC7jd/N+HEKrKsZi5JmGTfMudRdWhQyRy0aJnBQXiWsbU8Ms5R1pcdR10A1EtO/P4iSn/c15urf5zMXD+dwfLldaJmjDrkQNLMir4RlFyMOURe+XZh787AxdPVWcwEY4qSd0N/vMzWl73uzlMcFUojtBfffcFqbw9d1yhvmE9BYtTRGgMcUBJ32KHb9j1ntkqd3boI4a/j6QteCzjs5u7sOFdh+Fv8QMN6o/Yrxjq4rraOJfk2LMUSC73WjUsu/ZuM5wW6lc7flZKGdPgmPn61KAVZ5jlNvngGLjST/9K875hunzzPm/N7ZRtaZ5v6VIQ/QXYxQbactrqHoCp+idKQCk0OpZKGof9u1r5EeiGRrkr8A1evzZemBgAA\"}!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@22.7.5_sass@1.79.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var CustomEmojiImg = 'v2bifxf _1mqalmd1 _1mqalmd0';
export var EmojiBoardTab = 'v2bifx7';
export var EmojiGroupContent = 'v2bifxb _1mqalmd1 _1mqalmd0';
export var NativeEmojiSidebarStack = 'v2bifx4';
export var EmojiItem = 'v2bifxd _1mqalmd1 _1mqalmd0 _1bugis90';
export var SidebarContent = 'v2bifx2';
export var Base = 'v2bifx0';
export var EmojiPreview = 'v2bifxc _1mqalmd1 _1mqalmd0';
export var Footer = 'v2bifx8';
export var Header = 'v2bifx6';
export var Sidebar = 'v2bifx1';
export var SidebarStack = 'v2bifx3';
export var EmojiGroup = 'v2bifx9';
export var SidebarDivider = 'v2bifx5';
export var StickerImg = 'v2bifxg _1mqalmd1 _1mqalmd0';
export var StickerItem = 'v2bifxe v2bifxd _1mqalmd1 _1mqalmd0 _1bugis90';
export var EmojiGroupLabel = 'v2bifxa';