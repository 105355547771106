import {color, toRem} from 'folds';
import {StylesConfig} from 'react-select';
import {SelectOption} from './Select';

export const customStyles: StylesConfig<SelectOption<string>, false> = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    minHeight: 'unset',
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none',
    },
    width: '100%',
    padding: 0,
    position: 'relative',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 32px 0 0', // Remove left padding
    width: '100%',
  }),
  singleValue: (base) => ({
    ...base,
    position: 'relative',
    left: 0,
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base) => ({
    ...base,
    position: 'relative',
    left: 0,
    margin: 0,
    padding: 0,
  }),
  input: (base) => ({
    ...base,
    color: 'inherit',
    margin: 0,
    padding: 0,
    position: 'relative',
    left: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    position: 'absolute',
    right: toRem(-16),
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    margin: 0,
    borderRadius: toRem(8),
    boxShadow: 'none',
    left: 0, // Align with the shifted input
    backgroundColor: color.Background.Container,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  option: (base) => ({
    ...base,
    borderRadius: toRem(8),
    paddingLeft: toRem(16),
  }),
};
