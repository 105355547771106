import {CompanyOrgs} from './company';
import {FeedbackByDateTask, FeedbackRollupDetails} from './feedback';
import {TaskAssignees} from './member';
import {ProjectCustomFieldDef} from './project';
import {TaskDetailsType} from './taskDetails';
import {TaskObjectSubType, TaskObjectType} from './taskObject';
import {TaskStatus} from './taskStatus';

export type TaskCompletionUnits = '%' | 'LF' | 'CY' | 'EA' | '';

export type TaskCreationSource = 'importer' | 'c4' | 'admin' | 'ios' | 'android' | 'web';

export type TaskCustomField = Pick<ProjectCustomFieldDef, 'internal_field_name'> & {value: string};

export type ChatEvent = {
  event_formatted_body: string;
  event_id: string;
  event_media_urls: string[];
  group_id: string;
};

export type IssueStatusTaskIDsPair = {
  issue_task_ids: string[];
  issue_task_status: TaskStatus;
};

export type PunchList = {
  items: {
    item_name: string;
    complete: boolean;
  }[];
};

export type TaskHistoryType = {
  is_shared_login: boolean;
  guest_name: string;
  status?: TaskStatus;
  time_updated?: string;
  worker_id?: string;
  worker_name?: string;
};

export type TaskRisk = {
  labels: {
    label: string;
    source: string;
    is_deleted: boolean;
    time_updated: string;
  }[];
};

export type TaskPerDateOverrideColor = {
  [key: string]: {
    abbrev: string;
    lookahead_color: string;
    issue_day_type?: string;
    issue_ids?: string[];
  };
};

export type TaskCost = {
  amount: number;
  currency: 'USD' | 'BRL';
};

export type TaskType = {
  actual_end?: string;
  actual_start?: string;
  actual_end_date?: string;
  actual_start_date?: string;
  start_date: string;
  end_date: string;
  sched_end_date: string;
  sched_start_date: string;
  abbrev?: string;
  assignment_count: number;
  calendar_days: boolean;
  completion_amount: string;
  completion_target: string;
  completion_unit: TaskCompletionUnits;
  created_by: string;
  created_using: TaskCreationSource;
  date_list: string[];
  description: string;
  duration: number;
  est_labor_hours: number;
  ext_chat_events?: ChatEvent[];
  id: string;
  issue_task_ids: string[];
  status_issue_task_ids_pairs?: IssueStatusTaskIDsPair[];
  keywords: string;
  location: string;
  lookahead_color: string;
  name: string;
  object_type: TaskObjectType;
  object_subtype: TaskObjectSubType;
  outline_is_leaf: boolean;
  outline_sort_key: string;
  parent_task?: TaskDetailsType;
  project_id: string;
  punchlist: PunchList;
  responsible_org_id?: string;
  responsible_org: CompanyOrgs;
  responsible: TaskAssignees[];
  status: TaskStatus;
  status_history: TaskHistoryType[];
  time_created: string;
  time_updated: string;
  risk?: TaskRisk;
  time_removed: string;
  type?: string;
  unique_id?: string;
  projected_labor: string;
  actual_labor: string;
  csi_code: string;
  phase_code: string;
  cost_code: string;
  custom_code: string;
  per_date_override?: TaskPerDateOverrideColor;
  custom_fields?: TaskCustomField[];
  feedback_by_date?: FeedbackByDateTask[];
  feedback_rollup?: FeedbackRollupDetails;
};

type Assignee = {
  created_by: string;
  member_id: string;
  member_name: string;
  member_profile_pic_url: string;
  member_role: 'owner';
  member_status: string;
  member_trade: string;
  time_chat_last_sync: string;
};

export type Cost = {
  amount: number;
  currency: 'USD';
};

export type PerDateOverride = {
  abbrev: string;
  issue_day_type: 'move';
  issue_ids: string[];
  lookahead_color: 'aqua';
};

export type Punchlist = {
  items: PunchlistItem[];
};

type PunchlistItem = {
  complete: boolean;
  item_name: string;
};

export type ResponsibleOrg = {
  abbrev: string;
  company_id: string;
  contact_worker_id: string;
  group: Group;
  group_id: string;
  id: string;
  lookahead_color: 'bulldozer';
  nb_workers: number;
  time_created: string;
  time_removed: string;
  type: 'org';
};

type Group = {
  adhoc: boolean;
  company_id: string;
  direct: boolean;
  ext_chat_id: string;
  id: string;
  members: GroupMember[];
  name: string;
  notifybot: boolean;
  project_id: string;
  time_created: string;
  time_removed: string;
  time_updated: string;
};

type GroupMember = Assignee & {
  ext_chat_id: string;
  group_id: string;
  id: string;
};

export type StatusHistory = {
  ext_chat_id: string;
  guest_name: string;
  is_shared_login: boolean;
  status: 'approved';
  time_updated: string;
  worker_id: string;
  worker_name: string;
};

export type CustomField = {
  internal_field_name: string;
  value: string;
};

export type FeedbackRollup = {
  average_labor: string;
  completion_amount: string;
};

export type Risk = {
  labels: RiskLabel[];
};

export type RiskLabel = {
  confidence: string;
  is_deleted: boolean;
  label: 'acceptable_substrate';
  source: 'human';
  time_updated: string;
};

export type StatusIssueTaskIdsPair = {
  issue_task_ids: string[];
  issue_task_status: 'approved';
};

export type Contributor = {
  id: string;
  task_status_from: 'approved';
  task_status_to: 'approved';
  time_created: string;
  worker_id: string;
};

export type FeedbackByDate = {
  feedback_by_date: FeedbackByDateItem[];
  field: string;
};

type FeedbackByDateItem = {
  date: string;
  field: string;
  tags: string[];
  time_created: string;
  value: string;
  worker_id: string;
};

export enum TaskIssueType {
  DesignEngineering = 'design_engineering',
  DrawingDiscrepancies = 'drawing_discrepancies',
  LaborWorkforce = 'labor_workforce',
  LackofPlanning = 'lack_of_planning',
  Manufacturing = 'manufacturing',
  MaterialEquipment = 'material_equipment',
  Other = 'other',
  Quality = 'quality',
  Safety = 'safety',
  SiteConditionsAccess = 'site_conditions_access',
  TradeCoordination = 'trade_coordination',
  Weather = 'weather',
}

export enum TaskIssueImpact {
  None = 'none',
  Partial = 'partial',
  DayForDay = 'day_for_day',
}
