import cn from 'classnames';
import {Box, IconSrc, Text} from 'folds';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {CustomIcon} from '../system-icons/CustomIcon';
import {CustomIconSrc} from '../system-icons/CustomIconsStore';
import s from './Pill.module.scss';

type PillPropsBase = {
  after?: IconSrc | CustomIconSrc;
  before?: IconSrc | CustomIconSrc;
  className?: string;
  truncate?: boolean;
};

type PillPropsLoading = PillPropsBase & {
  loading: true;
  title?: never;
};

type PillPropsNotLoading = PillPropsBase & {
  loading?: never;
  title: string;
};

type PillProps = PillPropsLoading | PillPropsNotLoading;

export function Pill({after, before, className, loading, title, truncate}: PillProps) {
  const {t} = useTranslation('common');

  const renderIcon = (src: IconSrc | CustomIconSrc | undefined) => {
    if (!src) {
      return null;
    }
    return <CustomIcon src={src} size="50" />;
  };

  const containerClass = cn(s.container, className, {
    [s['container--truncate']]: truncate,
  });

  if (loading) {
    return (
      <Box alignItems="Start" justifyContent="Center" className={containerClass}>
        <Skeleton width={150} height={20} />
      </Box>
    );
  }

  return (
    <Box alignItems="Start" justifyContent="Center" className={containerClass}>
      {renderIcon(before)}
      <Text className={s.text}>{title}</Text>
      {renderIcon(after)}
    </Box>
  );
}
