import {useQuery} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {workerAtom} from '~/app/state/worker';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {workerService} from '~/services/WorkerService/WorkerService';
import {ChatGroup} from '~/types/chat';

export const useProjectAndGroupIdFromWorkerChats = (roomId: string) => {
  const [worker] = useAtom(workerAtom);

  return useQuery<ChatGroup[], Error, {projectId: string | undefined; groupId: string | undefined}>(
    {
      queryKey: QUERY_CACHE_KEYS.workerChats(worker.id),
      queryFn: async () => {
        const data = await workerService.getChatsByWorkerId({workerId: worker.id});
        return data;
      },
      select: (data) => {
        const foundProject = data.find((chat) => chat.ext_chat_id === roomId);
        return {
          projectId: foundProject?.project_id,
          groupId: foundProject?.group_id,
        };
      },
      enabled: !!worker.id,
    },
  );
};
