import {t} from 'i18next';

/**
 * A rapper for dynamic translation keys.
 * @example
 * dynamicT('namespace:common.buttons.', 'submit')
 *
 * @remarks
 * The staticPart should include the namespace and any static parts of the translation key.
 * The namespace is required and should be included in the staticPart parameter.
 * For example, if your translation key is "common:buttons.submit",
 * the staticPart should be "common:buttons." and the dynamicPart should be "submit".
 */
export function dynamicT<T extends string>(staticPart: string, dynamicPart: T): string {
  const key = `${staticPart}${dynamicPart}` as const;
  const result = t(key as string);
  if (typeof result !== 'string') {
    return String(result);
  }
  return result;
}
