import React from 'react';
import './ScrollView.scss';

interface ScrollViewProps {
  horizontal?: boolean;
  vertical?: boolean;
  autoHide?: boolean;
  invisible?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

const ScrollView = React.forwardRef<HTMLDivElement, ScrollViewProps>(
  (
    {
      horizontal = false,
      vertical = true,
      autoHide = false,
      invisible = false,
      onScroll = undefined,
      children,
    },
    ref,
  ) => {
    let scrollbarClasses = '';
    if (horizontal) scrollbarClasses += ' scrollbar__h';
    if (vertical) scrollbarClasses += ' scrollbar__v';
    if (autoHide) scrollbarClasses += ' scrollbar--auto-hide';
    if (invisible) scrollbarClasses += ' scrollbar--invisible';

    return (
      <div onScroll={onScroll} ref={ref} className={`scrollbar${scrollbarClasses}`}>
        {children}
      </div>
    );
  },
);

ScrollView.displayName = 'ScrollView';

export default ScrollView;
