import {useEffect, useState} from 'react';
import cons from '~/client/state/cons';
import navigation from '~/client/state/navigation';

type EditModalState = {
  isOpen: boolean;
  roomId: string | null;
};

type CreateModalState = Omit<EditModalState, 'roomId'>;

const initialEditState = {isOpen: false, roomId: ''};
const initialCreateState = {isOpen: false};

export function useWindowToggle() {
  const [createState, setCreateState] = useState<CreateModalState>(initialCreateState);
  const [editState, setEditState] = useState<EditModalState>(initialEditState);

  useEffect(() => {
    const handleCreateOpen = () => {
      setCreateState({isOpen: true});
      setEditState(initialEditState);
    };

    const handleEditOpen = (roomId: string) => {
      setEditState({isOpen: true, roomId});
      setCreateState(initialCreateState);
    };

    navigation.on(cons.events.navigation.CREATE_ROOM_OPENED, handleCreateOpen);
    navigation.on(cons.events.navigation.EDIT_ROOM_OPENED, handleEditOpen);

    return () => {
      navigation.removeListener(cons.events.navigation.CREATE_ROOM_OPENED, handleCreateOpen);
      navigation.removeListener(cons.events.navigation.EDIT_ROOM_OPENED, handleEditOpen);
    };
  }, []);

  const onRequestClose = () => {
    setCreateState(initialEditState);
    setEditState(initialEditState);
  };

  return {
    isCreateOpen: createState.isOpen,
    isEditOpen: editState.isOpen,
    onRequestClose,
    roomId: editState.roomId,
  };
}
