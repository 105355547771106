import {NestedKeyOf, getNestedValue} from '~/app/utils/getNestedValues';
import {SelectOption} from './Select';

export function createSelectOptions<T, V = T>(
  items: T[],
  valueKey?: NestedKeyOf<T>,
  labelKey?: NestedKeyOf<T>,
  formatLabel?: (item: T) => string,
): SelectOption<V | string>[] {
  const options: SelectOption<V | string>[] = [];

  items.forEach((item) => {
    if (valueKey && labelKey) {
      // For objects with nested properties
      const value = getNestedValue(item, valueKey) as V;
      const labelValue = getNestedValue(item, labelKey);
      if (value !== undefined && labelValue !== undefined) {
        options.push({
          value,
          label: formatLabel ? formatLabel(item) : String(labelValue),
        });
      }
    } else {
      // For simple arrays
      options.push({
        value: item as unknown as V,
        label: formatLabel ? formatLabel(item) : String(item),
      });
    }
  });

  return options;
}
