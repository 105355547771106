import mixpanel, {type Config} from 'mixpanel-browser';
import {parser} from '~/shared/uaParser';
import {CompanyModel} from '~/types/company';
import {Project} from '~/types/project';

const prfx = 'CHAT_';

export type EventMeta = {
  [key: string]: unknown;
};

export interface MixpanelTrackFn {
  (event: string, meta?: EventMeta): void;
}

type MixpanelDefaultMeta = {
  companyName?: string;
  companyId?: string;
  userId?: string;
  userFullName?: string;
  [key: string]: unknown;
};

type MixpanelBufferEvent = {
  event: string;
  meta: EventMeta;
};

const STORAGE_KEY = 'mixpanel_default_meta';

class MixpanelService {
  private static instance: MixpanelService;
  private deviceInfo: UAParser.IResult = parser.getResult();
  private defaultMeta: MixpanelDefaultMeta = {};
  private eventsBuffer: MixpanelBufferEvent[] = [];
  private needDeferEvents = true;

  private constructor() {
    this.loadDefaultMetaFromStorage();
  }

  public static getInstance(): MixpanelService {
    if (!MixpanelService.instance) {
      MixpanelService.instance = new MixpanelService();
    }
    return MixpanelService.instance;
  }

  initialize(token: string, config: Config = {} as Config): void {
    mixpanel.init(token, config);
    this.needDeferEvents = false;
    this.processBuffer();
  }

  setDefaultMeta(meta: Partial<MixpanelDefaultMeta>): void {
    this.defaultMeta = {
      ...this.defaultMeta,
      ...meta,
      ...this.deviceInfo,
    };
    this.saveDefaultMetaToStorage();
  }

  private loadDefaultMetaFromStorage(): void {
    const storedMeta = localStorage.getItem(STORAGE_KEY);
    if (storedMeta) {
      this.defaultMeta = JSON.parse(storedMeta);
    }
  }

  private saveDefaultMetaToStorage(): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.defaultMeta));
  }

  private addTimestamp(meta: EventMeta): EventMeta {
    return Object.assign({}, meta, {timestamp: new Date().toISOString()});
  }

  track: MixpanelTrackFn = (event: string, meta: EventMeta = {}) => {
    const resultMeta = {...this.defaultMeta, ...meta};
    // @ts-expect-error config is not defined, but in reality it is 🤷
    if (mixpanel.config?.token && event?.length) {
      const normalizedEvent = event.startsWith(prfx) ? event : `${prfx}${event}`;
      if (this.needDeferEvents) {
        this.eventsBuffer.push({event: normalizedEvent, meta: resultMeta});
      } else {
        mixpanel.track(normalizedEvent, this.addTimestamp(resultMeta));
      }
    }
  };

  trackWithAction<T>(action: () => T, event: string, meta: EventMeta = {}, condition = true): T {
    if (condition) {
      this.track(event, meta);
    }
    return action();
  }

  private processBuffer(): void {
    while (this.eventsBuffer.length) {
      const currentEvent = this.eventsBuffer.shift();
      if (currentEvent) {
        const {meta, event} = currentEvent;
        this.track(event, meta);
      }
    }
  }

  setUserProfile(profile: {id: string; full_name?: string; email?: string}): void {
    if (profile?.id) {
      this.setDefaultMeta({
        userId: profile.id,
        userFullName: profile?.full_name || profile?.email,
        email: profile?.email,
      });
    }
  }

  setCompany(company: Partial<CompanyModel>): void {
    if (company) {
      this.setDefaultMeta({
        companyName: company.company_name,
        companyId: company.id,
      });
    }
  }

  setProject(project: Partial<Project>): void {
    if (project) {
      this.setDefaultMeta({
        projectName: project.name,
        projectId: project.id,
        companyId: project.company_id,
        state: project.state,
        timezone: project.timezone,
      });
    }
  }

  cleanup(): void {
    localStorage.removeItem(STORAGE_KEY);
    this.defaultMeta = {};
    this.eventsBuffer = [];
    this.needDeferEvents = true;
    mixpanel.reset();
  }
}

const mixpanelService = MixpanelService.getInstance();

export {mixpanelService};
