import to from 'await-to-js';
import classNames from 'classnames';
import {Box, Icon, Icons, Text, as, color, toRem} from 'folds';
import parse from 'html-dom-parser';
import parseHTML from 'html-react-parser';

import {DOMNode, Element, Text as TextType} from 'html-react-parser';
import {EventTimelineSet, MatrixClient, MatrixEvent, Room} from 'matrix-js-sdk';
import {CryptoBackend} from 'matrix-js-sdk/lib/common-crypto/CryptoBackend';
import React, {Fragment, ReactNode, useEffect, useMemo, useState} from 'react';
import {formatMessageContent} from '~/app/organisms/create-issue/helper';
import {
  formatAndScaleEmoji,
  getReactCustomHtmlParser,
} from '~/app/plugins/react-custom-html-parser';
import {randomNumberBetween} from '~/app/utils/common';
import {getMxIdLocalPart} from '~/app/utils/matrix';
import colorMXID from '~/util/colorMXID';
import {
  getMemberDisplayName,
  removeBlockQuoteFromEditor,
  trimReplyFromBody,
} from '../../utils/room';
import * as css from './Reply.css';
import {MessageBadEncryptedContent, MessageDeletedContent} from './content';
import {LinePlaceholder} from './placeholder';
import {removeMobileMxReply} from '../editor';

type ReplyLayoutProps = {
  userColor?: string;
  username?: ReactNode;
};
export const ReplyLayout = as<'div', ReplyLayoutProps>(
  ({username, userColor, className, children, ...props}, ref) => (
    <Box
      className={classNames(css.Reply, className)}
      alignItems="Center"
      gap="100"
      {...props}
      ref={ref}
    >
      <Box style={{color: userColor, maxWidth: toRem(200)}} alignItems="Center" shrink="No">
        <Icon size="100" src={Icons.ReplyArrow} />
        {username}
      </Box>
      <Box grow="Yes" className={css.ReplyContent}>
        {children}
      </Box>
    </Box>
  ),
);

type ReplyProps = {
  mx: MatrixClient;
  room: Room;
  timelineSet?: EventTimelineSet;
  eventId: string;
};

function isElement(node: DOMNode): node is Element {
  return (node as unknown as Element).type === 'tag';
}

function isText(node: DOMNode): node is TextType {
  return (node as TextType).type === 'text';
}

export const parseReply = (content: (string | JSX.Element)[]) => {
  const renderContent = (item: string | JSX.Element, index: number) => {
    if (typeof item === 'string') {
      const parsed = parse(item);

      const renderNode = (node: DOMNode, _nodeIndex: number): React.ReactNode => {
        if (isElement(node) && node.name === 'a') {
          const href = node.attribs?.href || 'Link not provided';
          return (
            <a key={`link-${_nodeIndex}`} href={href}>
              {href}
            </a>
          );
        }
        if (isText(node)) {
          return <span key={`text-${_nodeIndex}`}>{node.data}</span>;
        }
        if (isElement(node)) {
          return (
            <Fragment key={`element-${_nodeIndex}`}>
              {node.children.map((child, i) => renderNode(child, i))}
            </Fragment>
          );
        }
        return null;
      };

      return (
        <Fragment key={`string-${index}`}>{parsed.map((node, i) => renderNode(node, i))}</Fragment>
      );
    }
    // If it's already a JSX element, just return it
    return <Fragment key={`jsx-${index}`}>{item}</Fragment>;
  };

  return <>{content.map((item, index) => renderContent(item, index))}</>;
};

export const Reply = as<'div', ReplyProps>(({mx, room, timelineSet, eventId, ...props}, ref) => {
  const [replyEvent, setReplyEvent] = useState<MatrixEvent | null | undefined>(
    timelineSet?.findEventById(eventId),
  );
  const placeholderWidth = useMemo(() => randomNumberBetween(40, 400), []);

  const {body, formatted_body} = replyEvent?.getContent() ?? {};
  const sender = replyEvent?.getSender();

  const customBody = removeMobileMxReply(removeBlockQuoteFromEditor(formatted_body));

  const fallbackBody = replyEvent?.isRedacted() ? <MessageDeletedContent /> : null;

  useEffect(() => {
    let disposed = false;
    const loadEvent = async () => {
      const [err, evt] = await to(mx.fetchRoomEvent(room.roomId, eventId));
      const mEvent = new MatrixEvent(evt);
      if (disposed) return;
      if (err) {
        setReplyEvent(null);
        return;
      }
      if (mEvent.isEncrypted() && mx.getCrypto()) {
        await to(mEvent.attemptDecryption(mx.getCrypto() as CryptoBackend));
      }
      setReplyEvent(mEvent);
    };
    if (replyEvent === undefined) loadEvent();
    return () => {
      disposed = true;
    };
  }, [replyEvent, mx, room, eventId]);

  const badEncryption = replyEvent?.getContent().msgtype === 'm.bad.encrypted';

  const parserOptions = getReactCustomHtmlParser(mx, room, {});
  const parsedFormattedBody = customBody ? parseHTML(customBody, parserOptions) : null;

  const bodyContent = parsedFormattedBody
    ? formatAndScaleEmoji(parsedFormattedBody as JSX.Element[])
    : body
      ? formatAndScaleEmoji(trimReplyFromBody(body))
      : fallbackBody;

  const bodyJSX = Array.isArray(bodyContent)
    ? parseReply(bodyContent.map((content) => formatMessageContent(content, mx)))
    : formatMessageContent(bodyContent, mx);

  return (
    <ReplyLayout
      userColor={sender ? colorMXID(sender) : undefined}
      username={
        sender && (
          <Text size="T300" truncate>
            <b>{getMemberDisplayName(room, sender) ?? getMxIdLocalPart(sender)}</b>
          </Text>
        )
      }
      {...props}
      ref={ref}
    >
      {replyEvent !== undefined ? (
        <Text size="T300" truncate>
          {badEncryption ? <MessageBadEncryptedContent /> : bodyJSX}
        </Text>
      ) : (
        <LinePlaceholder
          style={{
            backgroundColor: color.SurfaceVariant.ContainerActive,
            maxWidth: toRem(placeholderWidth),
            width: '100%',
          }}
        />
      )}
    </ReplyLayout>
  );
});
