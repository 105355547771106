import {RouterProvider} from 'react-router-dom';
import {ClientConfig} from '../hooks/useClientConfig';
import {ScreenSize} from '../hooks/useScreenSize';
import {createRouter} from './Router';
import {useAuth} from './auth/AuthProvider';

type RouterInitializerProps = {
  clientConfig: ClientConfig;
  screenSize: ScreenSize;
};

export function RouterInitializer({clientConfig, screenSize}: RouterInitializerProps) {
  const {isAuthenticated} = useAuth();
  const router = createRouter(isAuthenticated, clientConfig, screenSize);

  return <RouterProvider router={router} />;
}
