import {useQueries} from '@tanstack/react-query';
import {MatrixClient} from 'matrix-js-sdk';
import {useMatrixClient} from '~/app/hooks/useMatrixClient';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {CompanyWorker} from '~/types/worker';

const avatarSize = 45;

const getUserProfileInfo = async (id: string, mx: MatrixClient) => {
  const userAccount = await mx.getProfileInfo(id);
  const avatarUrl = userAccount?.avatar_url
    ? (mx.mxcUrlToHttp(userAccount?.avatar_url, avatarSize, avatarSize, 'crop') ?? undefined)
    : undefined;
  return {
    avatarUrl,
    displayName: userAccount?.displayname,
  };
};

export const useWorkerProfileInfo = (filteredWorkers: CompanyWorker[]) => {
  const mx = useMatrixClient();
  return useQueries({
    queries: filteredWorkers?.map((worker) => ({
      queryKey: QUERY_CACHE_KEYS.workerProfiles(worker.worker_id),
      queryFn: () => getUserProfileInfo(worker.worker_full.ext_chat_id, mx),
    })),
  });
};
