import {Box, Icon, IconButton, Icons} from 'folds';
import {DOMNode, HTMLReactParserOptions} from 'html-react-parser';
import isHotkey, {isKeyHotkey} from 'is-hotkey';
import {KeyboardEvent, MouseEvent, ReactNode, useCallback, useState} from 'react';
import * as css from '~/app/styles/CustomHtml.css';
import {Attachment} from '~/types/matrix/room';
import {useMatrixClient} from '../../hooks/useMatrixClient';

interface BlockQuoteProps {
  children: DOMNode[];
  replyAttachments: Attachment[];
  domToReact: (children: DOMNode[], options?: HTMLReactParserOptions) => ReactNode;
  modifiedOptions: HTMLReactParserOptions;
}

export const BlockQuoteWAttachments = ({
  children,
  replyAttachments,
  domToReact,
  modifiedOptions,
}: BlockQuoteProps) => {
  const mx = useMatrixClient();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleImageClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (isHotkey('Enter', e) || isKeyHotkey('escape', e)) {
      e.preventDefault();
      setIsExpanded((prev) => !prev);
    }

    if (isExpanded && replyAttachments && replyAttachments.length > 1) {
      if (isHotkey('ArrowLeft', e)) {
        cycleImage('left', e);
      } else if (isHotkey('ArrowRight', e)) {
        cycleImage('right', e);
      }
    }
  };

  const cycleImage = useCallback(
    (direction: 'left' | 'right', e: MouseEvent | KeyboardEvent) => {
      e.stopPropagation();
      setCurrentImageIndex((prevIndex) => {
        const newIndex =
          direction === 'left'
            ? (prevIndex - 1 + replyAttachments.length) % replyAttachments.length
            : (prevIndex + 1) % replyAttachments.length;
        return newIndex;
      });
    },
    [replyAttachments?.length],
  );

  return (
    <blockquote className={css.BlockQuote({flexxing: !isExpanded})}>
      <button
        className={css.BlockQuoteImageContainer({isExpanded})}
        onClick={handleImageClick}
        onKeyDown={handleKeyDown}
        type="button"
      >
        <img
          className={css.BlockQuoteImage}
          src={mx.mxcUrlToHttp(replyAttachments[currentImageIndex].url) ?? ''}
          alt=""
        />
        {replyAttachments.length > 1 && !isExpanded ? (
          <div className={css.BlockQuoteImageOverlay}>+{replyAttachments.length - 1}</div>
        ) : null}
        {isExpanded && replyAttachments.length > 1 ? (
          <Box className={css.BlockQuoteImageNav} alignItems="Center" justifyContent="SpaceBetween">
            <IconButton
              size="300"
              outlined={false}
              onClick={(e: MouseEvent) => cycleImage('left', e)}
            >
              <Icon src={Icons.ArrowLeft} />
            </IconButton>
            <span>
              {currentImageIndex + 1} / {replyAttachments.length}
            </span>
            <IconButton
              size="300"
              outlined={false}
              onClick={(e: MouseEvent) => cycleImage('right', e)}
            >
              <Icon src={Icons.ArrowRight} />
            </IconButton>
          </Box>
        ) : null}
      </button>
      <div>{domToReact(children, modifiedOptions)}</div>
    </blockquote>
  );
};
