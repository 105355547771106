import {useQuery} from '@tanstack/react-query';
import {useAtom} from 'jotai';
import {useEffect} from 'react';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {mixpanelService} from '~/services/MixPanelService/MixPanelService';
import {workerService} from '~/services/WorkerService/WorkerService';
import {workerAtom} from '../state/worker';

export function useWorker() {
  const [worker] = useAtom(workerAtom);

  const {data, isLoading, error} = useQuery({
    queryKey: QUERY_CACHE_KEYS.getWorker(worker.id),
    queryFn: () => workerService.getWorkerById(worker.id),
    enabled: Boolean(worker.id),
  });

  useEffect(() => {
    if (data) {
      mixpanelService.setUserProfile(data);
    }
  }, [data]);

  return {
    worker: data,
    isLoading,
    error,
  };
}
