export type CustomResponse<R> = {
  data: R[];
  headers: {
    get: (name: string) => string | null;
  };
};

export type FetchAllGeneratorOptions<R> = {
  request: (offset: number, take: number) => Promise<CustomResponse<R>>;
  initialTake?: number;
  maxTake?: number;
  maxRetries?: number;
  retryDelay?: number;
  abortSignal?: AbortSignal;
  initialParallelCalls?: number;
  maxParallelCalls?: number;
};

export async function* fetchAllGenerator<R>(
  options: FetchAllGeneratorOptions<R>,
): AsyncGenerator<R[], void, unknown> {
  const {
    request,
    initialTake = 500,
    maxTake = 2_000,
    maxRetries = 3,
    retryDelay = 1_000,
    abortSignal,
    initialParallelCalls = 1,
    maxParallelCalls = 5,
  } = options;

  let offset = 0;
  let totalItems: number | undefined;
  let parallelCalls = initialParallelCalls;
  let take = initialTake;

  const fetchWithRetry = async (offset: number, take: number): Promise<CustomResponse<R>> => {
    let retries = 0;
    while (retries <= maxRetries) {
      try {
        if (abortSignal?.aborted) {
          throw new Error('Operation aborted');
        }
        return await request(offset, take);
      } catch (error: unknown) {
        if (
          (error as Error).message === 'Operation aborted' ||
          (error as Error).name === 'AbortError'
        ) {
          throw new Error('Operation aborted');
        }
        retries++;
        if (retries > maxRetries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
    throw new Error('Unexpected error in fetchWithRetry');
  };

  const adjustFetchParameters = (itemsReceived: number, timeTaken: number) => {
    const itemsPerSecond = itemsReceived / (timeTaken / 1_000);
    const targetItemsPerSecond = 1_000;

    if (itemsPerSecond < targetItemsPerSecond * 0.8) {
      // If we're fetching too slowly, increase parallel calls or take
      if (parallelCalls < maxParallelCalls) {
        parallelCalls = Math.min(maxParallelCalls, parallelCalls + 1);
      } else if (take < maxTake) {
        take = Math.min(maxTake, take * 1.5);
      }
    } else if (itemsPerSecond > targetItemsPerSecond * 1.2) {
      // If we're fetching too quickly, decrease parallel calls or take
      if (parallelCalls > 1) {
        parallelCalls--;
      } else if (take > initialTake) {
        take = Math.max(initialTake, take / 1.5);
      }
    }
  };

  while (true) {
    if (abortSignal?.aborted) {
      throw new Error('Operation aborted');
    }

    const fetchPromises = Array.from({length: parallelCalls}, (_, i) =>
      fetchWithRetry(offset + i * take, take),
    );

    const startTime = Date.now();
    const results = await Promise.all(fetchPromises);
    const endTime = Date.now();
    let allItems: R[] = [];

    for (const result of results) {
      if (totalItems === undefined) {
        totalItems = parseInt(result.headers.get('x-total-count') || '0', 10);
      }
      allItems = allItems.concat(result.data);
      if (result.data.length < take) {
        yield allItems;
        return;
      }
    }

    yield allItems;

    offset += allItems.length;
    adjustFetchParameters(allItems.length, endTime - startTime);

    if (totalItems !== undefined && offset >= totalItems) {
      return;
    }
  }
}

export async function fetchAllWithGenerator<R>(options: FetchAllGeneratorOptions<R>): Promise<R[]> {
  const allItems: R[] = [];
  for await (const items of fetchAllGenerator(options)) {
    allItems.push(...items);
  }
  return allItems;
}
