import {TFunction} from 'i18next';
import {IContent, MatrixClient} from 'matrix-js-sdk';
import {isValidElement} from 'react';
import {SelectOption} from '~/app/atoms/select/Select';
import {TaskIssueType} from '~/types/task';
import {TaskTypeSubset} from './CreateIssue';

export type CreateIssueTFunction = TFunction<['create_issue'], undefined>;

export const getTaskIssueTypeOptions = (t: CreateIssueTFunction): SelectOption<string>[] => {
  return [
    {
      value: TaskIssueType.DesignEngineering,
      label: t('create_issue:form.fields.issue_type.select.design_engineering'),
    },
    {
      value: TaskIssueType.DrawingDiscrepancies,
      label: t('create_issue:form.fields.issue_type.select.drawing_discrepancies'),
    },
    {
      value: TaskIssueType.LaborWorkforce,
      label: t('create_issue:form.fields.issue_type.select.labor_workforce'),
    },
    {
      value: TaskIssueType.LackofPlanning,
      label: t('create_issue:form.fields.issue_type.select.lack_of_planning'),
    },
    {
      value: TaskIssueType.Manufacturing,
      label: t('create_issue:form.fields.issue_type.select.manufacturing'),
    },
    {
      value: TaskIssueType.MaterialEquipment,
      label: t('create_issue:form.fields.issue_type.select.material_equipment'),
    },
    {
      value: TaskIssueType.TradeCoordination,
      label: t('create_issue:form.fields.issue_type.select.trade_coordination'),
    },
    {
      value: TaskIssueType.SiteConditionsAccess,
      label: t('create_issue:form.fields.issue_type.select.site_conditions_access'),
    },
    {value: TaskIssueType.Quality, label: t('create_issue:form.fields.issue_type.select.quality')},
    {value: TaskIssueType.Weather, label: t('create_issue:form.fields.issue_type.select.weather')},
    {value: TaskIssueType.Safety, label: t('create_issue:form.fields.issue_type.select.safety')},
    {value: TaskIssueType.Other, label: t('create_issue:form.fields.issue_type.select.other')},
  ];
};

export const getTaskIssueImpactOptions = (t: CreateIssueTFunction): SelectOption[] => {
  return [
    {value: 'none', label: t('create_issue:form.fields.time_impact.select.none')},
    {value: 'partial', label: t('create_issue:form.fields.time_impact.select.partial')},
    {value: 'day_for_day', label: t('create_issue:form.fields.time_impact.select.day_for_day')},
  ];
};

export const getCostImpactOptions = (t: CreateIssueTFunction): SelectOption[] => {
  return [
    {value: 'true', label: t('create_issue:form.fields.cost_impact.select.true')},
    {value: 'false', label: t('create_issue:form.fields.cost_impact.select.false')},
  ];
};

export const formatStatusLabel = (status: string): string => {
  return status
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatActivityLabel = (task: TaskTypeSubset): string => {
  return task.outline_code ? `${task.outline_code} - ${task.name}` : task.name;
};

export function formatMessageContent(
  content: IContent | string | JSX.Element | null | undefined,
  mx: MatrixClient,
): string | JSX.Element {
  // If content is undefined, null, or an empty object, return an empty string
  if (content == null || (typeof content === 'object' && Object.keys(content).length === 0)) {
    return '';
  }

  // If content is a JSX.Element, return it as is
  if (isValidElement(content)) {
    return content;
  }

  // If content is a string, use it directly
  if (typeof content === 'string') {
    return formatContentString(content, mx);
  }

  // Handle IContent type
  if (typeof content === 'object') {
    if ('msgtype' in content && content.msgtype === 'm.image') {
      return ''; // Or you might want to return a placeholder for images
    }

    if ('body' in content && typeof content.body === 'string') {
      return formatContentString(content.body, mx);
    }
  }

  // If none of the above conditions are met, return an empty string
  return '';
}

function formatContentString(text: string, mx: MatrixClient): string {
  /**
   * Regex to match Matrix user IDs.
   *
   * Structure:
   * - Starts with '@'
   * - Followed by a localpart containing alphanumeric characters and some special characters (._=-)
   * - Optionally followed by a server name (domain) starting with ':' and ending with a TLD
   *
   * The localpart allows: A-Z, a-z, 0-9, underscore, dot, equals sign, and hyphen.
   * The server name (if present) allows: A-Z, a-z, 0-9, dot, and hyphen.
   *
   * Examples of matching user IDs:
   * - @user123
   * - @complex_user-name
   * - @user:example.com
   * - @user_123:sub.domain.org
   */
  const userIdRegex = /(@[a-zA-Z0-9._=-]+)(?::[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?/g;

  const formattedContent = text.replace(userIdRegex, (match: string, username: string) => {
    const user = mx.getUser(match);
    if (user?.displayName) {
      return `@${user.displayName}`;
    }
    return username;
  });

  return formattedContent;
}

export function extractReplyContent(content: string): string {
  // Check if the content starts with "> <" and contains "\n\n"
  if (content.startsWith('> <') && content.includes('\n\n')) {
    const parts = content.split('\n\n');
    if (parts.length > 1) {
      const replyContent = parts.slice(1).join('\n\n').trim();
      // If there's no content after the last "\n\n", return the original string
      return replyContent === '' ? content : replyContent;
    }
  }

  // If the specific format is not matched, return the trimmed content
  return content.trim();
}

export function handleContent(content: IContent | undefined): string {
  const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  if (content && content.info && imageTypes.includes(content.info.mimetype)) {
    return '';
  }

  // If it's not an image, process it with extractReplyContent
  const contentWithoutReplyContent = extractReplyContent(content?.body || '');
  return contentWithoutReplyContent;
}
