import i18next from 'i18next';
import {z} from 'zod';

// Custom error map function with immediate translation
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.custom) {
    return {message: i18next.t(issue.message as string)};
  }
  return {message: ctx.defaultError};
};

// Set the custom error map
z.setErrorMap(customErrorMap);

/**
 * !Do not Remove
 * t('create_room:group_chat.room_name.input.error')
 * t('create_room:group_chat.room_name.input.group_or_individual')
 */
export const createRoomFormSchema = z
  .object({
    roomName: z.string().optional(),
    selectedProject: z.any(), // We're not validating this
    selectedWorker: z.any().optional(),
    selectedWorkers: z.array(z.any()).optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data.selectedWorkers &&
      Array.isArray(data.selectedWorkers) &&
      data.selectedWorkers.length > 0
    ) {
      if (!data.roomName || data.roomName.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'create_room:group_chat.room_name.input.error',
          path: ['roomName'],
        });
      }
    }

    // Ensure either selectedWorker or selectedWorkers is provided, but not both
    if (!!data.selectedWorker === (!!data.selectedWorkers && data.selectedWorkers.length > 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'create_room:group_chat.room_name.input.group_or_individual',
        path: ['selectedWorker', 'selectedWorkers'],
      });
    }
  });
