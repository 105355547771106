import {IconName, IconSrc} from 'folds';

import parse, {HTMLReactParserOptions, Element} from 'html-react-parser';
import {
  EventTimeline,
  EventTimelineSet,
  EventType,
  IPushRule,
  IPushRules,
  JoinRule,
  MatrixClient,
  MatrixEvent,
  MsgType,
  NotificationCountType,
  RelationType,
  Room,
  RoomMember,
} from 'matrix-js-sdk';
import {CryptoBackend} from 'matrix-js-sdk/lib/common-crypto/CryptoBackend';
import {AccountDataEvent} from '../../types/matrix/accountData';
import {
  MRelatesToContent,
  MessageEvent,
  NotificationType,
  RoomToParents,
  RoomType,
  StateEvent,
  UnreadInfo,
} from '../../types/matrix/room';

export const getStateEvent = (
  room: Room,
  eventType: StateEvent,
  stateKey = '',
): MatrixEvent | undefined => room.currentState.getStateEvents(eventType, stateKey) ?? undefined;

export const getStateEvents = (room: Room, eventType: StateEvent): MatrixEvent[] =>
  room.currentState.getStateEvents(eventType);

export const getAccountData = (
  mx: MatrixClient,
  eventType: AccountDataEvent,
): MatrixEvent | undefined => mx.getAccountData(eventType);

export const getMDirects = (mDirectEvent: MatrixEvent): Set<string> => {
  const roomIds = new Set<string>();
  const userIdToDirects = mDirectEvent?.getContent();

  if (userIdToDirects === undefined) return roomIds;

  Object.keys(userIdToDirects).forEach((userId) => {
    const directs = userIdToDirects[userId];
    if (Array.isArray(directs)) {
      directs.forEach((id) => {
        if (typeof id === 'string') roomIds.add(id);
      });
    }
  });

  return roomIds;
};

export const isDirectInvite = (room: Room | null, myUserId: string | null): boolean => {
  if (!room || !myUserId) return false;
  const me = room.getMember(myUserId);
  const memberEvent = me?.events?.member;
  const content = memberEvent?.getContent();
  return content?.is_direct === true;
};

export const isSpace = (room: Room | null): boolean => {
  if (!room) return false;
  const event = getStateEvent(room, StateEvent.RoomCreate);
  if (!event) return false;
  return event.getContent().type === RoomType.Space;
};

export const isRoom = (room: Room | null): boolean => {
  if (!room) return false;
  const event = getStateEvent(room, StateEvent.RoomCreate);
  if (!event) return true;
  return event.getContent().type !== RoomType.Space;
};

export const isUnsupportedRoom = (room: Room | null): boolean => {
  if (!room) return false;
  const event = getStateEvent(room, StateEvent.RoomCreate);
  if (!event) return true; // Consider room unsupported if m.room.create event doesn't exist
  return event.getContent().type !== undefined && event.getContent().type !== RoomType.Space;
};

export function isValidChild(mEvent: MatrixEvent): boolean {
  return (
    mEvent.getType() === StateEvent.SpaceChild &&
    Array.isArray(mEvent.getContent<{via: string[]}>().via)
  );
}

export const getAllParents = (roomToParents: RoomToParents, roomId: string): Set<string> => {
  const allParents = new Set<string>();

  const addAllParentIds = (rId: string) => {
    if (allParents.has(rId)) return;
    allParents.add(rId);

    const parents = roomToParents.get(rId);
    parents?.forEach((id) => addAllParentIds(id));
  };
  addAllParentIds(roomId);
  allParents.delete(roomId);
  return allParents;
};

export const getSpaceChildren = (room: Room) =>
  getStateEvents(room, StateEvent.SpaceChild).reduce<string[]>((filtered, mEvent) => {
    const stateKey = mEvent.getStateKey();
    if (isValidChild(mEvent) && stateKey) {
      filtered.push(stateKey);
    }
    return filtered;
  }, []);

export const mapParentWithChildren = (
  roomToParents: RoomToParents,
  roomId: string,
  children: string[],
) => {
  const allParents = getAllParents(roomToParents, roomId);
  children.forEach((childId) => {
    if (allParents.has(childId)) {
      // Space cycle detected.
      return;
    }
    const parents = roomToParents.get(childId) ?? new Set<string>();
    parents.add(roomId);
    roomToParents.set(childId, parents);
  });
};

export const getRoomToParents = (mx: MatrixClient): RoomToParents => {
  const map: RoomToParents = new Map();
  mx.getRooms()
    .filter((room) => isSpace(room))
    .forEach((room) => mapParentWithChildren(map, room.roomId, getSpaceChildren(room)));

  return map;
};

export const getOrphanParents = (roomToParents: RoomToParents, roomId: string): string[] => {
  const parents = getAllParents(roomToParents, roomId);
  const orphanParents = Array.from(parents).filter(
    (parentRoomId) => !roomToParents.has(parentRoomId),
  );

  return orphanParents;
};

export const isMutedRule = (rule: IPushRule) =>
  rule.actions[0] === 'dont_notify' && rule.conditions?.[0]?.kind === 'event_match';

export const findMutedRule = (overrideRules: IPushRule[], roomId: string) =>
  overrideRules.find((rule) => rule.rule_id === roomId && isMutedRule(rule));

export const getNotificationType = (mx: MatrixClient, roomId: string): NotificationType => {
  let roomPushRule: IPushRule | undefined;
  try {
    roomPushRule = mx.getRoomPushRule('global', roomId);
  } catch {
    roomPushRule = undefined;
  }

  if (!roomPushRule) {
    const overrideRules = mx.getAccountData('m.push_rules')?.getContent<IPushRules>()
      ?.global?.override;
    if (!overrideRules) return NotificationType.Default;

    return findMutedRule(overrideRules, roomId) ? NotificationType.Mute : NotificationType.Default;
  }

  if (roomPushRule.actions[0] === 'notify') return NotificationType.AllMessages;
  return NotificationType.MentionsAndKeywords;
};

const NOTIFICATION_EVENT_TYPES = [
  'm.room.create',
  'm.room.message',
  'm.room.encrypted',
  'm.room.member',
  'm.sticker',
];
export const isNotificationEvent = (mEvent: MatrixEvent) => {
  const eType = mEvent.getType();
  if (!NOTIFICATION_EVENT_TYPES.includes(eType)) {
    return false;
  }
  if (eType === 'm.room.member') return false;

  if (mEvent.isRedacted()) return false;
  if (mEvent.getRelation()?.rel_type === 'm.replace') return false;

  return true;
};

export const roomHaveNotification = (room: Room): boolean => {
  const total = room.getUnreadNotificationCount(NotificationCountType.Total);
  const highlight = room.getUnreadNotificationCount(NotificationCountType.Highlight);

  return total > 0 || highlight > 0;
};

export const roomHaveUnread = (mx: MatrixClient, room: Room) => {
  const userId = mx.getUserId();
  if (!userId) return false;
  const readUpToId = room.getEventReadUpTo(userId);
  const liveEvents = room.getLiveTimeline().getEvents();

  if (liveEvents[liveEvents.length - 1]?.getSender() === userId) {
    return false;
  }

  for (let i = liveEvents.length - 1; i >= 0; i -= 1) {
    const event = liveEvents[i];
    if (!event) return false;
    if (event.getId() === readUpToId) return false;
    if (isNotificationEvent(event)) return true;
  }
  return true;
};

export const getUnreadInfo = (room: Room): UnreadInfo => {
  const total = room.getUnreadNotificationCount(NotificationCountType.Total);
  const highlight = room.getUnreadNotificationCount(NotificationCountType.Highlight);
  return {
    roomId: room.roomId,
    highlight,
    total: highlight > total ? highlight : total,
  };
};

export const getUnreadInfos = (mx: MatrixClient): UnreadInfo[] => {
  const unreadInfos = mx.getRooms().reduce<UnreadInfo[]>((unread, room) => {
    if (room.isSpaceRoom()) return unread;
    if (room.getMyMembership() !== 'join') return unread;
    if (getNotificationType(mx, room.roomId) === NotificationType.Mute) return unread;

    if (roomHaveNotification(room) || roomHaveUnread(mx, room)) {
      unread.push(getUnreadInfo(room));
    }

    return unread;
  }, []);
  return unreadInfos;
};

export const joinRuleToIconSrc = (
  icons: Record<IconName, IconSrc>,
  joinRule: JoinRule,
  space: boolean,
): IconSrc | undefined => {
  if (joinRule === JoinRule.Restricted) {
    return space ? icons.Space : icons.Hash;
  }
  if (joinRule === JoinRule.Knock) {
    return space ? icons.SpaceLock : icons.HashLock;
  }
  if (joinRule === JoinRule.Invite) {
    return space ? icons.SpaceLock : icons.HashLock;
  }
  if (joinRule === JoinRule.Public) {
    return space ? icons.SpaceGlobe : icons.HashGlobe;
  }
  return undefined;
};

export const getRoomAvatarUrl = (
  mx: MatrixClient,
  room: Room,
  size: 32 | 96 = 32,
): string | undefined => room.getAvatarUrl(mx.baseUrl, size, size, 'crop') ?? undefined;

export const getDirectRoomAvatarUrl = (
  mx: MatrixClient,
  room: Room,
  size: 32 | 96 = 32,
): string | undefined =>
  room.getAvatarFallbackMember()?.getAvatarUrl(mx.baseUrl, size, size, 'crop', undefined, false) ??
  undefined;

export const trimReplyFromBody = (body: string): string => {
  // Split the body into lines
  const lines = body.split('\n');

  // Filter out lines that start with '>'
  const newContentLines = lines.filter((line) => !line.trim().startsWith('>'));

  // Join the new content lines back into a single string
  const newContent = newContentLines.join('\n').trim();

  return newContent;
};

export function extractHrefIfAnchor(body: string): string {
  const trimmed = body.trim();
  if (trimmed.startsWith('<a') && trimmed.endsWith('</a>')) {
    let extractedHref: string | null = null;

    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (domNode instanceof Element && domNode.name === 'a' && 'href' in domNode.attribs) {
          extractedHref = domNode.attribs.href;
          return undefined;
        }
        return false;
      },
    };

    parse(trimmed, options);

    return extractedHref || trimmed;
  }

  return trimmed;
}

export const trimReplyFromFormattedBody = (formattedBody: string): string => {
  const suffix = '</mx-reply>';
  const i = formattedBody.lastIndexOf(suffix);
  if (i < 0) {
    return formattedBody;
  }
  return formattedBody.slice(i + suffix.length);
};

export const parseReplyBody = (userId: string, body: string) =>
  `> <${userId}> ${body.replace(/\n/g, '\n> ')}\n\n`;

export const getMemberDisplayName = (room: Room, userId: string): string | undefined => {
  const member = room.getMember(userId);
  const name = member?.rawDisplayName;
  if (name === userId) return undefined;
  return name;
};

export const removeBlockQuoteFromEditor = (input: string) => {
  const blockquoteRegex = /<blockquote[\s\S]*?>[\s\S]*?<\/blockquote>/gi;
  return input?.replace(blockquoteRegex, '').trim() || '';
};

export const parseReplyFormattedBody = ({
  roomId,
  userId,
  eventId,
  replyBody,
}: {roomId: string; userId: string; eventId: string; replyBody: string}): string => {
  const encodedRoomId = encodeURIComponent(roomId);
  const encodedEventId = encodeURIComponent(eventId);
  const encodedUserId = encodeURIComponent(userId);

  const replyToLink = `<a href="https://matrix.to/#/${encodedRoomId}/${encodedEventId}">In reply to </a>`;
  const userLink = `<a href="https://matrix.to/#/${encodedUserId}">${userId}</a>`;

  return `<mx-reply><blockquote>${replyToLink}${userLink}<br />${replyBody}</blockquote></mx-reply>`;
};

export const getMemberSearchStr = (
  member: RoomMember,
  query: string,
  mxIdToName: (mxId: string) => string,
): string[] => [
  member.rawDisplayName === member.userId ? mxIdToName(member.userId) : member.rawDisplayName,
  query.startsWith('@') || query.indexOf(':') > -1 ? member.userId : mxIdToName(member.userId),
];

export const getMemberAvatarMxc = (room: Room, userId: string): string | undefined => {
  const member = room.getMember(userId);
  return member?.getMxcAvatarUrl();
};

export const isMembershipChanged = (mEvent: MatrixEvent): boolean =>
  mEvent.getContent().membership !== mEvent.getPrevContent().membership ||
  mEvent.getContent().reason !== mEvent.getPrevContent().reason;

export const decryptAllTimelineEvent = async (mx: MatrixClient, timeline: EventTimeline) => {
  const crypto = mx.getCrypto();
  if (!crypto) return;
  const decryptionPromises = timeline
    .getEvents()
    .filter((event) => event.isEncrypted())
    .reverse()
    .map((event) => event.attemptDecryption(crypto as CryptoBackend, {isRetry: true}));
  await Promise.allSettled(decryptionPromises);
};

export const getReactionContent = (eventId: string, key: string, shortcode?: string) => ({
  'm.relates_to': {
    event_id: eventId,
    key,
    rel_type: 'm.annotation',
  },
  shortcode,
});

export function mapLegacyReactionKey(key: string): string {
  const legacyKeyMap: {[key: string]: string} = {
    like: '❤️',
    thumbDown: '👎',
    thumbUp: '👍',
    question: '❓',
  };

  return legacyKeyMap[key] || key;
}

export const buildReactionContent = ({
  key,
  targetEventId,
}: {
  key: string | undefined;
  targetEventId: string | undefined;
}) => {
  if (key === undefined || targetEventId === undefined) {
    throw new Error('key and targetEventId must be defined');
  }
  return {
    msgtype: MessageEvent.Reaction,
    body: key,
    [MRelatesToContent.RelatesTo]: {
      rel_type: RelationType.Annotation,
      event_id: targetEventId,
      key,
    },
  };
};

export const isReactionEvent = (event: MatrixEvent) =>
  event.isRelation() ||
  (event.getType() === MessageEvent.RoomMessage &&
    event.getContent().msgtype === MessageEvent.Reaction);

export const getEventReactions = (
  timelineSet: EventTimelineSet,
  eventId: string | undefined,
  eventTypes: EventType[],
) => {
  if (!timelineSet || !timelineSet.relations || !eventId) {
    return null;
  }

  const reactionRelations = eventTypes
    .map((eventType) =>
      timelineSet.relations.getChildEventsForEvent(eventId, RelationType.Annotation, eventType),
    )
    .filter(Boolean)[0]; // Get the first non-null result

  if (reactionRelations) {
    // Get the sorted annotations
    const sortedAnnotations: [string, Set<MatrixEvent>][] | null =
      reactionRelations.getSortedAnnotationsByKey();
    // Create a custom getSortedAnnotationsByKey method that applies emoji mapping
    if (sortedAnnotations) {
      const originalGetSortedAnnotationsByKey =
        reactionRelations.getSortedAnnotationsByKey.bind(reactionRelations);
      reactionRelations.getSortedAnnotationsByKey = (): [string, Set<MatrixEvent>][] | null => {
        const original = originalGetSortedAnnotationsByKey();
        if (original) {
          return original.map(([key, events]) => [mapLegacyReactionKey(key), events]);
        }
        return null;
      };
    }
  }
  return reactionRelations;
};

export const getEventEdits = (timelineSet: EventTimelineSet, eventId: string, eventType: string) =>
  timelineSet.relations.getChildEventsForEvent(eventId, RelationType.Replace, eventType);

export const getLatestEdit = (
  targetEvent: MatrixEvent,
  editEvents: MatrixEvent[],
): MatrixEvent | undefined => {
  const eventByTargetSender = (rEvent: MatrixEvent) =>
    rEvent.getSender() === targetEvent.getSender();
  return editEvents.sort((m1, m2) => m2.getTs() - m1.getTs()).find(eventByTargetSender);
};

export const getEditedEvent = (
  mEventId: string,
  mEvent: MatrixEvent,
  timelineSet: EventTimelineSet,
): MatrixEvent | undefined => {
  const edits = getEventEdits(timelineSet, mEventId, mEvent.getType());
  return edits && getLatestEdit(mEvent, edits.getRelations());
};

export const canEditEvent = (mx: MatrixClient, mEvent: MatrixEvent) =>
  mEvent.getSender() === mx.getUserId() &&
  !mEvent.isRelation() &&
  (mEvent.getType() === MessageEvent.RoomMessage ||
    mEvent.getType() === MessageEvent.CustomMention) &&
  (mEvent.getContent().msgtype === MsgType.Text ||
    mEvent.getContent().msgtype === MsgType.Emote ||
    mEvent.getContent().msgtype === MsgType.Notice ||
    mEvent.getContent().type === MessageEvent.CustomMention);

export const getLatestEditableEvt = (
  timeline: EventTimeline,
  canEdit: (mEvent: MatrixEvent) => boolean,
): MatrixEvent | undefined => {
  const events = timeline.getEvents();

  for (let i = events.length - 1; i >= 0; i -= 1) {
    const evt = events[i];
    if (canEdit(evt)) return evt;
  }
  return undefined;
};

export const reactionOrEditEvent = (mEvent: MatrixEvent) =>
  mEvent.getRelation()?.rel_type === RelationType.Annotation ||
  mEvent.getRelation()?.rel_type === RelationType.Replace;

type ReplyDraft = {
  body: string;
};

export function getReplyBody(draft: ReplyDraft | undefined) {
  if (!draft) {
    return {
      isReplyToReply: false,
      replyBody: '',
    };
  }

  const isReplyToReply = draft.body?.includes('\n');

  if (isReplyToReply) {
    return {
      isReplyToReply,
      replyBody: draft.body.split('\n').pop() || '',
    };
  }
  return {
    isReplyToReply,
    replyBody: draft.body,
  };
}
