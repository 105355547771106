import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import {useCallback, useEffect, useState} from 'react';
import {QUERY_CACHE_KEYS} from '~/client/state/query-keys';
import {issuesService} from '~/services/IssuesService/IssuesService';
import {IssueModelDTO} from '~/types/issue';
import {LoadOptions} from '~/types/loadOptions';

export function useIssuesQuery(
  options: Partial<LoadOptions>,
  query = '',
  queryOptions?: Omit<UseQueryOptions<IssueModelDTO[], Error>, 'queryKey' | 'queryFn'>,
) {
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  const debouncedSetQuery = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
    }, 250),
    [],
  );

  useEffect(() => {
    debouncedSetQuery(query);
    return () => {
      debouncedSetQuery.cancel();
    };
  }, [query, debouncedSetQuery]);

  const filterParams: Partial<LoadOptions> = {
    ...options,
    queryParams: {
      ...options.queryParams,
      q: debouncedQuery || undefined,
    },
  };

  return useQuery<IssueModelDTO[], Error>({
    queryKey: QUERY_CACHE_KEYS.getIssues(filterParams),
    queryFn: async () => {
      try {
        const response = await issuesService.loadIssues(filterParams);
        const issues = response;
        if (!issues || !Array.isArray(issues)) {
          throw new Error('Invalid response format');
        }
        return issues;
      } catch (error) {
        console.error('Error fetching issues:', error);
        throw error; // Re-throw the error to ensure it's caught by React Query
      }
    },
    networkMode: 'always',
    staleTime: 0,
    enabled: !!options.projectId,
    ...queryOptions,
  });
}
